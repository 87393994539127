import { createContext, useCallback, useContext, useState } from "react";
import {
  FilterCondition,
  InboxMessagePayload,
  PushPayloadReq,
  UploadedImage
} from "../API/data-contracts";
import { FilterProperty, FilterType } from "../model/filter-property";
import { getDateTimeString, throwEmptyContext } from "../util";

type InboxMessageContext = {
  clearContext(): void;
  initContext(payload?: InboxMessagePayload): void;
  getMessagePayload(): InboxMessagePayload | undefined;
  getPushPayload(): PushPayloadReq | undefined;
  isExistingMessage: boolean;
  messageId?: string;
  messageTitle?: string;
  setMessageTitle(messageTitle?: string): void;
  messageBody?: string;
  setMessageBody(messageBody?: string): void;
  actionTitle?: string;
  setActionTitle(actionTitle?: string): void;
  messageUrl?: string;
  setMessageUrl(messageUrl?: string): void;
  messageVideoUrl?: string;
  setMessageVideoUrl(messageVideoUrl?: string): void;
  coverImage?: UploadedImage;
  setCoverImage(coverImage?: UploadedImage): void;
  galleryImages: UploadedImage[];
  setGalleryImages(galleryImages: UploadedImage[]): void;
  imageIdsToDelete: string[];
  setImageIdsToDelete(imageIdsToDelete: string[]): void;
  pushTitle?: string;
  setPushTitle(pushTitle?: string): void;
  pushBody?: string;
  setPushBody(pushBody?: string): void;
  pushSendRequired: boolean;
  setPushSendRequired(pushSendRequired: boolean): void;
  usernames?: string;
  setUsernames(username?: string): void;
  sendToSingleUser: boolean;
  setSendToSingleUser(sendToSingleUser: boolean): void;
  filterConditions: FilterCondition[];
  setFilterConditions(filterConditions: FilterCondition[]): void;
  scheduleDate?: Date;
  setScheduleDate(scheduleDate?: Date): void;
  scheduleTime?: string;
  setScheduleTime(scheduleTime?: string): void;
  expiryDate?: Date;
  setExpiryDate(expiryDate?: Date): void;
  expiryTime?: string;
  setExpiryTime(expiryTime?: string): void;
};

const defaultValues: InboxMessageContext = {
  clearContext: throwEmptyContext,
  initContext: throwEmptyContext,
  getMessagePayload: throwEmptyContext,
  getPushPayload: throwEmptyContext,
  isExistingMessage: false,
  setMessageTitle: throwEmptyContext,
  setMessageBody: throwEmptyContext,
  setActionTitle: throwEmptyContext,
  setMessageUrl: throwEmptyContext,
  setMessageVideoUrl: throwEmptyContext,
  setCoverImage: throwEmptyContext,
  galleryImages: [],
  setGalleryImages: throwEmptyContext,
  imageIdsToDelete: [],
  setImageIdsToDelete: throwEmptyContext,
  setPushTitle: throwEmptyContext,
  setPushBody: throwEmptyContext,
  pushSendRequired: false,
  setPushSendRequired: throwEmptyContext,
  setUsernames: throwEmptyContext,
  sendToSingleUser: false,
  setSendToSingleUser: throwEmptyContext,
  filterConditions: [
    { input: [], property: new FilterProperty(), selectedOperator: null },
  ],
  setFilterConditions: throwEmptyContext,
  setScheduleDate: throwEmptyContext,
  setScheduleTime: throwEmptyContext,
  setExpiryDate: throwEmptyContext,
  setExpiryTime: throwEmptyContext,
};

const context = createContext<InboxMessageContext>(defaultValues);

type Props = React.PropsWithChildren<{}>;

export const InboxMessageContextProvider = ({ children }: Props) => {
  // step 1 (content & media)
  const [isExistingMessage, setExistingMessage] = useState(
    defaultValues.isExistingMessage
  );
  const [messageId, setMessageId] = useState(defaultValues.messageId);
  const [messageTitle, setMessageTitle] = useState(defaultValues.messageTitle);
  const [messageBody, setMessageBody] = useState(defaultValues.messageBody);
  const [actionTitle, setActionTitle] = useState(defaultValues.actionTitle);
  const [messageUrl, setMessageUrl] = useState(defaultValues.messageUrl);
  const [messageVideoUrl, setMessageVideoUrl] = useState(
    defaultValues.messageVideoUrl
  );
  const [coverImage, setCoverImage] = useState(defaultValues.coverImage);
  const [galleryImages, setGalleryImages] = useState(
    defaultValues.galleryImages
  );
  const [imageIdsToDelete, setImageIdsToDelete] = useState(
    defaultValues.imageIdsToDelete
  );
  // step 2 (target & pushes)
  const [pushTitle, setPushTitle] = useState(defaultValues.pushTitle);
  const [pushBody, setPushBody] = useState(defaultValues.pushBody);
  const [pushSendRequired, setPushSendRequired] = useState(false);
  const [usernames, setUsernames] = useState(defaultValues.usernames);
  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>(
    defaultValues.filterConditions
  );
  // step 3 send
  const [scheduleDate, setScheduleDate] = useState(defaultValues.scheduleDate);
  const [scheduleTime, setScheduleTime] = useState(defaultValues.scheduleTime);
  const [expiryDate, setExpiryDate] = useState(defaultValues.expiryDate);
  const [expiryTime, setExpireTime] = useState(defaultValues.expiryTime);
  const [sendToSingleUser, setSendToSingleUser] = useState(
    defaultValues.sendToSingleUser
  );

  const clearContext = useCallback(() => {
    setExistingMessage(defaultValues.isExistingMessage);
    setMessageId(defaultValues.messageId);
    setMessageTitle(defaultValues.messageTitle);
    setMessageBody(defaultValues.messageBody);
    setActionTitle(defaultValues.actionTitle);
    setMessageUrl(defaultValues.messageUrl);
    setMessageVideoUrl(defaultValues.messageVideoUrl);
    setCoverImage(defaultValues.coverImage);
    setGalleryImages(defaultValues.galleryImages);
    setImageIdsToDelete(defaultValues.imageIdsToDelete);
    setPushTitle(defaultValues.pushTitle);
    setPushBody(defaultValues.pushBody);
    setPushSendRequired(defaultValues.pushSendRequired);
    setUsernames(defaultValues.usernames);
    setSendToSingleUser(defaultValues.sendToSingleUser);
    setFilterConditions(defaultValues.filterConditions);
    setScheduleDate(defaultValues.scheduleDate);
    setScheduleTime(defaultValues.scheduleTime);
    setExpiryDate(defaultValues.expiryDate);
    setExpireTime(defaultValues.expiryTime);
  }, [
    setExistingMessage,
    setMessageId,
    setMessageTitle,
    setMessageBody,
    setActionTitle,
    setMessageUrl,
    setMessageVideoUrl,
    setCoverImage,
    setGalleryImages,
    setImageIdsToDelete,
    setPushTitle,
    setPushBody,
    setPushSendRequired,
    setUsernames,
    setSendToSingleUser,
    setFilterConditions,
    setScheduleDate,
    setScheduleTime,
    setExpiryDate,
    setExpireTime,
  ]);

  const initContext = useCallback(
    (messagePayload?: InboxMessagePayload) => {
      clearContext();
      if (messagePayload) {
        setMessageId(messagePayload.id);
        setExistingMessage(true);
        // set all the data we get from the message payload
        setMessageTitle(messagePayload.message?.title);
        setMessageBody(messagePayload.message?.body);
        setActionTitle(messagePayload.message?.actionTitle);
        setMessageUrl(messagePayload.message?.url);
        setMessageVideoUrl(messagePayload.message?.videoUrl);

        // set image data
        const ids = messagePayload.message?.imageIds;
        const gallery: UploadedImage[] = [];
        if (ids) {
          for (let i = 0; i < ids.length; i++) {
            if (i == 0) {
              setCoverImage({
                id: ids[0],
              });
            } else {
              gallery.push({ id: ids[i] });
            }
          }
          setGalleryImages(gallery);
        }
      }
    },
    [
      clearContext,
      setExistingMessage,
      setMessageId,
      setMessageTitle,
      setMessageBody,
      setActionTitle,
      setMessageUrl,
      setMessageVideoUrl,
      setCoverImage,
      setGalleryImages,
    ]
  );

  const getMessagePayload = useCallback(() => {
    const conditions = filterConditions?.filter(
      (condition) =>
        condition.property && condition.property.type != FilterType.none
    );
    const imageIds = [];
    if (coverImage?.id) imageIds.push(coverImage.id);
    imageIds.push(...galleryImages.map((image) => image.id ?? ""));

    const payload: InboxMessagePayload = {
      id: messageId,
      usernames: sendToSingleUser ? usernames?.split(",") : undefined,
      conditions: !sendToSingleUser ? conditions : undefined,
      scheduleDate: getDateTimeString(scheduleDate, scheduleTime),
      expiryDate: getDateTimeString(expiryDate, expiryTime),
      message: {
        title: messageTitle ?? "",
        body: messageBody ?? "",
        actionTitle,
        url: messageUrl,
        videoUrl: messageVideoUrl,
        imageIds: imageIds.filter(
          (id) => id != "" && !imageIdsToDelete.includes(id)
        ),
      },
    };

    return payload;
  }, [
    messageId,
    filterConditions,
    scheduleDate,
    scheduleTime,
    expiryDate,
    expiryTime,
    messageTitle,
    messageBody,
    messageUrl,
    messageVideoUrl,
    actionTitle,
    usernames,
    sendToSingleUser,
    coverImage,
    galleryImages,
  ]);

  const getPushPayload = useCallback(() => {
    const conditions = filterConditions?.filter(
      (condition) =>
        condition.property && condition.property.type != FilterType.none
    );
    const payload: PushPayloadReq = {
      usernames: sendToSingleUser ? usernames?.split(",") : undefined,
      conditions: !sendToSingleUser ? conditions : undefined,
      scheduleDate: getDateTimeString(scheduleDate, scheduleTime),
      message: {
        title: pushTitle ?? "",
        body: pushBody ?? "",
        localized: false,
      },
    };
    return payload;
  }, [
    filterConditions,
    scheduleDate,
    scheduleTime,
    pushTitle,
    pushBody,
    usernames,
    sendToSingleUser,
  ]);

  const contextValues: InboxMessageContext = {
    clearContext,
    initContext,
    getMessagePayload,
    getPushPayload,
    isExistingMessage: isExistingMessage,
    messageId: messageId,
    messageTitle: messageTitle,
    setMessageTitle: setMessageTitle,
    messageBody: messageBody,
    setMessageBody: setMessageBody,
    actionTitle: actionTitle,
    setActionTitle: setActionTitle,
    messageUrl: messageUrl,
    setMessageUrl: setMessageUrl,
    messageVideoUrl: messageVideoUrl,
    setMessageVideoUrl: setMessageVideoUrl,
    coverImage: coverImage,
    setCoverImage: setCoverImage,
    galleryImages: galleryImages,
    setGalleryImages: setGalleryImages,
    imageIdsToDelete: imageIdsToDelete,
    setImageIdsToDelete: setImageIdsToDelete,
    pushTitle: pushTitle,
    setPushTitle: setPushTitle,
    pushBody: pushBody,
    setPushBody: setPushBody,
    pushSendRequired: pushSendRequired,
    setPushSendRequired: setPushSendRequired,
    usernames: usernames,
    setUsernames: setUsernames,
    sendToSingleUser: sendToSingleUser,
    setSendToSingleUser: setSendToSingleUser,
    filterConditions: filterConditions,
    setFilterConditions: setFilterConditions,
    scheduleDate: scheduleDate,
    setScheduleDate: setScheduleDate,
    scheduleTime: scheduleTime,
    setScheduleTime: setScheduleTime,
    expiryDate: expiryDate,
    setExpiryDate: setExpiryDate,
    expiryTime: expiryTime,
    setExpiryTime: setExpireTime,
  };

  return <context.Provider value={contextValues}>{children}</context.Provider>;
};

export const useInboxMessage = () => useContext(context);
