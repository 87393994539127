import { useNavigate } from "react-router-dom";
import EditEmailContent from "../Components/UI/EditEmailContent";
import { useEmailMessage } from "../context/EmailMessageContext";

export default function NewEmailMessageTarget() {
  const emailMessage = useEmailMessage();
  const navigate = useNavigate();

  const templateStep = () => {
    navigate("/emails/new");
  };

  const sendStep = () => {
    navigate("/emails/new/send");
  };

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          {emailMessage.isExistingMessage
            ? "✉️ Edit Email Message"
            : "✉️ New Email Message"}
        </h2>
      </div>

      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          <li className="relative md:flex md:flex-1">
            <a
              onClick={templateStep}
              className="group flex cursor-pointer items-center no-underline"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  {"Select a Template"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            <span className="flex items-center px-6 py-4 text-sm font-medium no-underline">
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                <span className="text-rotaxDark group-hover:text-gray-900">
                  02
                </span>
              </span>
              <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                {"Content & Target"}
              </span>
            </span>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            {/* Upcoming Step */}
            <a
              onClick={sendStep}
              className="group flex cursor-pointer items-center no-underline"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                  <span className="text-gray-500 group-hover:text-gray-900">
                    03
                  </span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  Send
                </span>
              </span>
            </a>
          </li>
        </ol>
      </nav>

      <EditEmailContent />

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="mt-10 mb-12">
              <div className="flex justify-between">
                <div>
                  <button
                    type="button"
                    onClick={templateStep}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={sendStep}
                    className={
                      "inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                    }
                  >
                    Next step
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
