import { createContext, useContext, useState } from "react";
import {
  AutomatedMarketingEmailTemplate,
  EmailTemplate,
  FilterCondition,
  MarketingEmailCreate,
  TestEmailBody,
} from "../API/data-contracts";
import { FilterProperty, FilterType } from "../model/filter-property";
import {
  convertMapToRecord,
  convertRecordToMap,
  fixHtmlIssues,
} from "../ts-util";
import { getDateTimeString, throwEmptyContext } from "../util";

type EmailMessageContext = {
  clearContext(): void;
  initContext(automatedEmailTemplate?: AutomatedMarketingEmailTemplate): void;
  automatedEmailTemplate: AutomatedMarketingEmailTemplate | undefined;
  getAutomatedEmailTemplate(): AutomatedMarketingEmailTemplate | undefined;
  getTestEmail(recipient: string): TestEmailBody | undefined;
  getMarketingEmail(): MarketingEmailCreate | undefined;
  isExistingMessage: boolean;
  template: EmailTemplate | undefined;
  setTemplate(template: EmailTemplate): void;
  parameters: Map<string, string>;
  setParameters(parameters: Map<string, string>): void;
  filterConditions: FilterCondition[];
  setFilterConditions(filterConditions: FilterCondition[]): void;
  scheduleDate?: Date;
  setScheduleDate(scheduleDate?: Date): void;
  scheduleTime?: string;
  setScheduleTime(scheduleTime?: string): void;
  campaignName: string;
  setCampaignName(campaignName: string): void;
  emailSubject: string | undefined;
  setEmailSubject(emailSubject: string): void;
};

const defaultValues: EmailMessageContext = {
  clearContext: throwEmptyContext,
  initContext: throwEmptyContext,
  automatedEmailTemplate: undefined,
  getAutomatedEmailTemplate: throwEmptyContext,
  getTestEmail: throwEmptyContext,
  getMarketingEmail: throwEmptyContext,
  isExistingMessage: false,
  template: undefined,
  setTemplate: throwEmptyContext,
  parameters: new Map(),
  setParameters: throwEmptyContext,
  filterConditions: [
    { input: [], property: new FilterProperty(), selectedOperator: null },
  ],
  setFilterConditions: throwEmptyContext,
  setScheduleDate: throwEmptyContext,
  setScheduleTime: throwEmptyContext,
  campaignName: "",
  setCampaignName: throwEmptyContext,
  emailSubject: undefined,
  setEmailSubject: throwEmptyContext,
};

const context = createContext<EmailMessageContext>(defaultValues);

type Props = React.PropsWithChildren<{}>;

export const EmailMessageContextProvider = ({ children }: Props) => {
  // step 0 (optional autmated template)
  const [automatedEmailTemplate, setAutomatedEmailTemplate] = useState<
    AutomatedMarketingEmailTemplate | undefined
  >(defaultValues.automatedEmailTemplate);
  // step 1 (email tempalte)
  const [isExistingMessage, setExistingMessage] = useState(
    defaultValues.isExistingMessage
  );
  const [template, setTemplate] = useState<EmailTemplate | undefined>(
    defaultValues.template
  );
  // step 2 (content + target)
  const [campaignName, setCampaignName] = useState(defaultValues.campaignName);
  const [emailSubject, setEmailSubject] = useState(defaultValues.emailSubject);
  const [parameters, setParameters] = useState<Map<string, string>>(
    defaultValues.parameters
  );
  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>(
    defaultValues.filterConditions
  );
  // step 3 send
  const [scheduleDate, setScheduleDate] = useState(defaultValues.scheduleDate);
  const [scheduleTime, setScheduleTime] = useState(defaultValues.scheduleTime);

  const clearContext = () => {
    setExistingMessage(defaultValues.isExistingMessage);
    setTemplate(defaultValues.template);
    parameters.clear();
    setParameters(parameters);
    setFilterConditions(defaultValues.filterConditions);
    setScheduleDate(defaultValues.scheduleDate);
    setScheduleTime(defaultValues.scheduleTime);
    setCampaignName(defaultValues.campaignName);
    setEmailSubject(defaultValues.emailSubject);
  };

  const initContext = (
    automatedEmailTemplate?: AutomatedMarketingEmailTemplate
  ) => {
    clearContext();
    setAutomatedEmailTemplate(automatedEmailTemplate);
    if (automatedEmailTemplate) {
      setEmailSubject(automatedEmailTemplate.subject);
      if (automatedEmailTemplate.parameters) {
        setParameters(convertRecordToMap(automatedEmailTemplate.parameters));
      }
    }
  };

  const getParams = () => {
    return parameters.size > 0
      ? convertMapToRecord(fixHtmlIssues(parameters))
      : undefined;
  };

  const getAutomatedEmailTemplate = () => {
    if (template?.id && automatedEmailTemplate) {
      return {
        templateId: template.id,
        subject:
          emailSubject && emailSubject?.length > 0
            ? emailSubject
            : template.subject,
        parameters: getParams(),
        enabled: automatedEmailTemplate.enabled,
        id: automatedEmailTemplate.id,
        createdDate: automatedEmailTemplate.createdDate,
        type: automatedEmailTemplate.type,
      };
    } else {
      return undefined;
    }
  };

  const getTestEmail = (recipient: string) => {
    if (template?.id) {
      return {
        templateId: template.id,
        subject:
          emailSubject && emailSubject?.length > 0
            ? emailSubject
            : template.subject,
        parameters: getParams(),
        recipient: recipient,
      };
    } else {
      return undefined;
    }
  };

  const getMarketingEmail = () => {
    const conditions = filterConditions?.filter(
      (condition) =>
        condition.property && condition.property.type != FilterType.none
    );

    if (template?.id) {
      return {
        name: campaignName,
        subject:
          emailSubject && emailSubject?.length > 0
            ? emailSubject
            : template.subject,
        templateId: template.id,
        parameters: getParams(),
        replyTo: "noreply@news.rotaxmaxdome.com",
        // recipientEmails: ["dev@ahoikapptn.com"], //TODO: only for testing
        conditions: conditions,
        scheduledAt: getDateTimeString(scheduleDate, scheduleTime),
      };
    } else {
      return undefined;
    }
  };

  const contextValues: EmailMessageContext = {
    clearContext,
    initContext,
    automatedEmailTemplate,
    getAutomatedEmailTemplate,
    getTestEmail,
    getMarketingEmail,
    isExistingMessage: isExistingMessage,
    template: template,
    setTemplate,
    parameters: parameters,
    setParameters,
    filterConditions: filterConditions,
    setFilterConditions,
    scheduleDate: scheduleDate,
    setScheduleDate,
    scheduleTime: scheduleTime,
    setScheduleTime,
    campaignName: campaignName,
    setCampaignName,
    emailSubject: emailSubject,
    setEmailSubject,
  };

  return <context.Provider value={contextValues}>{children}</context.Provider>;
};

export const useEmailMessage = () => useContext(context);
