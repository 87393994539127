import { CheckCircleIcon } from "@heroicons/react/outline";
import Compressor from "compressorjs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import {
  Challenge,
  FilterCondition,
  UploadedImage,
} from "../API/data-contracts";
import CriteriaDropdown from "../Components/UI/CriteriaDropdown";
import FilterSelection from "../Components/UI/FilterSelection";
import { useErrorHandler } from "../Error/ErrorContext";
import { ConditionType, FilterProperty } from "../model/filter-property";
import { RMDConstants } from "../model/rmdconstants";
import { dateToYYYYmmdd, getDateString, inboxMessageUrl } from "../util";

type LocationState = {
  editChallenge: Challenge | undefined;
};

export default function NewChallenge() {
  const { api, accessToken } = useAuth();
  const { state } = useLocation();
  const { showError } = useErrorHandler();
  const { editChallenge } = state as LocationState;
  const [challenge, setChallenge] = useState<Challenge | undefined>(
    editChallenge
  );
  const [isSavingChallenge, setSavingChallenge] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [title, setTitle] = useState<string>(challenge?.title ?? "");
  const [sponsorName, setSponsorName] = useState<string>(
    challenge?.sponsorName ?? ""
  );
  const [description, setDescription] = useState<string>(
    challenge?.description ?? ""
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    challenge?.from ? new Date(challenge.from) : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    challenge?.to ? new Date(challenge.to) : undefined
  );
  const [metric, setMetric] = useState<
    "VictoryPoints" | "BestLapTime" | "MostVisits" | "MostPoints" | undefined
  >(challenge?.metric ?? undefined);

  // combine all the conditions for easier ussage and split them up again correctly when making the request
  const allChallengeConditions = challenge
    ? [
        ...(challenge.userConditions ?? []),
        ...(challenge.raceConditions ?? []),
        ...(challenge.driverConditions ?? []),
      ]
    : [];

  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>(
    allChallengeConditions.length > 0
      ? allChallengeConditions
      : [{ input: [], property: new FilterProperty(), selectedOperator: null }]
  );

  const [coverImage, setCoverImage] = useState<UploadedImage | undefined>(
    challenge?.imageId ? { id: challenge.imageId } : undefined
  );
  const [hasCoverImage, setHasCoverImage] = useState(
    challenge?.imageId !== undefined
  );
  const [deleteCoverImage, setDeleteCoverImage] = useState(false);

  const [sponsorLogo, setSponsorLogo] = useState<UploadedImage | undefined>(
    challenge?.sponsorImageId ? { id: challenge.sponsorImageId } : undefined
  );
  const [hasSponsorLogo, setHasSponsorLogo] = useState(
    challenge?.sponsorImageId !== undefined
  );
  const [deleteSponsorImage, setDeleteSponsorImage] = useState(false);

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleSponsorNameChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setSponsorName(event.currentTarget.value);
  };

  const handleDescriptionChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.currentTarget.value);
  };

  const criteriaSelected = (
    metric:
      | "VictoryPoints"
      | "BestLapTime"
      | "MostVisits"
      | "MostPoints"
      | undefined
  ) => {
    setMetric(metric);
  };

  const handleRemoveCoverImage = () => {
    setHasCoverImage(false);
    setDeleteCoverImage(true);
  };

  const handleRemoveSponsorLogo = () => {
    setHasSponsorLogo(false);
    setDeleteSponsorImage(true);
  };

  const handleCoverImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      compressImagePromise(file).then((compressedImage) => {
        handleImage(compressedImage)
          .then((image) => {
            setCoverImage(image);
            setHasCoverImage(true);
            console.log("Successfully processed cover image.");
          })
          .catch((e) => {
            console.log("Failed to process cover image: " + e);
          });
      });
    }
  };

  const handleSponsorLogoChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      compressImagePromise(file).then((compressedImage) => {
        handleImage(compressedImage)
          .then((image) => {
            setSponsorLogo(image);
            setHasSponsorLogo(true);
            console.log("Successfully processed sponsor logo.");
          })
          .catch((e) => {
            console.log("Failed to process sponsor logo: " + e);
          });
      });
    }
  };

  function handleImage(file: File): Promise<UploadedImage> {
    var promise = new Promise<UploadedImage>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var base64 = reader.result; //reader.result;

        var image: UploadedImage = { file: file, src: base64 };
        resolve(image);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
        reject();
      };
      reader.readAsDataURL(file);
    });

    return promise;
  }

  function compressImagePromise(image: File): Promise<File> {
    var promise = new Promise<File>((resolve) => {
      // max size without compression is 500KB
      const maxSizeWithoutCompression = 500000;
      const compressionQuality = 0.6;

      if (image.size > maxSizeWithoutCompression) {
        console.log("Compressing image...");
        new Compressor(image, {
          quality: compressionQuality,
          success: (compressedResult) => {
            const compressedImage = new File([compressedResult], image.name, {
              type: image.type,
            });
            resolve(compressedImage);
          },
          error: (e) => {
            console.log(e.message);
            resolve(image);
          },
        });
      } else {
        resolve(image);
      }
    });
    return promise;
  }

  const addNewCondition = () => {
    let filterCopy = [...filterConditions];
    filterCopy.push({
      input: [],
      property: new FilterProperty(),
      selectedOperator: null,
    });
    setFilterConditions(filterCopy);
  };

  const removeCondition = (index: number) => {
    if (index >= 0) {
      let filterCopy = [...filterConditions];
      if (index !== -1) {
        filterCopy.splice(index, 1);
        setFilterConditions(filterCopy);
      }
    }
  };

  const updateCondition = (
    conditionIndex: number,
    condition: FilterCondition
  ) => {
    let filterCopy = [...filterConditions];
    filterCopy[conditionIndex] = condition;
    setFilterConditions(filterCopy);
  };

  const save = async () => {
    setSavingChallenge(true);

    const userConditions =
      filterConditions.filter(
        (condition) =>
          FilterProperty.getConditionType(condition.property?.type) ===
          ConditionType.user
      ) ?? [];
    const raceConditions =
      filterConditions.filter(
        (condition) =>
          FilterProperty.getConditionType(condition.property?.type) ===
          ConditionType.race
      ) ?? [];
    const driverConditions =
      filterConditions.filter(
        (condition) =>
          FilterProperty.getConditionType(condition.property?.type) ===
          ConditionType.driver
      ) ?? [];

    if (challenge) {
      api
        .updateChallenge(challenge.id, {
          title: title,
          description: description,
          sponsorName: sponsorName,
          from: getDateString(startDate),
          to: getDateString(endDate),
          userConditions: userConditions,
          raceConditions: raceConditions,
          driverConditions: driverConditions,
          metric: metric ?? "VictoryPoints",
        })
        .then((response) => {
          const challenge = response.data;
          if (challenge) {
            console.log("Successfully updated challenge.");
            setChallenge(challenge);
            uploadImages("" + challenge.id);
          }
        })
        .catch((e) => {
          showError(e);
        });
    } else {
      api
        .createChallenge({
          title: title,
          description: description,
          sponsorName: sponsorName,
          from: getDateString(startDate),
          to: getDateString(endDate),
          userConditions: userConditions,
          raceConditions: raceConditions,
          driverConditions: driverConditions,
          metric: metric ?? "VictoryPoints",
        })
        .then((response) => {
          const challenge = response.data;
          if (challenge) {
            console.log("Successfully updated challenge.");
            setChallenge(challenge);
            uploadImages("" + challenge.id);
          }
        })
        .catch((e) => {
          showError(e);
        });
    }
  };

  const uploadImages = (challengeId: string) => {
    // if a cover image was set, upload it. If not/afterwards upload sponsor logo
    if (coverImage?.file || deleteCoverImage) {
      api
        .setChallengeImage(challengeId, { image: coverImage?.file })
        .then(() => {
          console.log("Successfully uploaded cover image.");
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          handleSponsorLogo(challengeId);
        });
    } else {
      handleSponsorLogo(challengeId);
    }
  };

  const handleSponsorLogo = (challengeId: string) => {
    // if an image was set, upload it, else we are finished
    if (sponsorLogo?.file || deleteSponsorImage) {
      api
        .setChallengeSponsorImage(challengeId, { image: sponsorLogo?.file })
        .then(() => {
          console.log("Successfully uploaded sponsor logo.");
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          finalize();
        });
    } else {
      finalize();
    }
  };

  function finalize() {
    setSavingChallenge(false);
    setShowConfirm(false);
    setShowSuccess(true);
  }

  const handleStartDateChange = (event: React.FormEvent<HTMLInputElement>) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      setStartDate(new Date(dateValue));
    }
  };

  const handleEndDateChange = (event: React.FormEvent<HTMLInputElement>) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      setEndDate(new Date(dateValue));
    }
  };

  // the date since when we have data (1. Sept. 2021)
  let initialDate: Date = new Date("2021-09-01");
  let inThreeYears = new Date();
  inThreeYears.setFullYear(inThreeYears.getFullYear() + 3);

  const minDate = dateToYYYYmmdd(initialDate);
  const maxDate = dateToYYYYmmdd(inThreeYears);

  const coverImageSrc =
    coverImage?.src ?? inboxMessageUrl(coverImage?.id, accessToken());

  // update src of cover image if it doesn't have one yet
  if (coverImage?.src === undefined) {
    setCoverImage({
      id: coverImage?.id,
      src: coverImageSrc,
    });
  }

  const sponsorLogoSrc =
    sponsorLogo?.src ?? inboxMessageUrl(sponsorLogo?.id, accessToken());

  // update src of sponsor logo if it doesn't have one yet
  if (sponsorLogo?.src === undefined) {
    setSponsorLogo({
      id: sponsorLogo?.id,
      src: sponsorLogoSrc,
    });
  }

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          {challenge
            ? "Edit Rotax MAX Dome Challenge"
            : "New Rotax MAX Dome Challenge"}
        </h2>
      </div>

      <div>
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Challenge
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Describe what the challenge is about and set start and end
                  date.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  {!hasCoverImage && (
                    <div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="cover_photo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Header image
                        </label>
                        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="flex justify-center text-sm text-gray-600">
                              <label
                                htmlFor="cover-upload"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-rotaxDark hover:text-rotaxDark-light"
                              >
                                <span>
                                  Set a header image for the challenge
                                </span>
                                <input
                                  id="cover-upload"
                                  name="cover-upload"
                                  type="file"
                                  className="sr-only"
                                  accept=".png,.jpg,.jpeg"
                                  onChange={handleCoverImageChange}
                                />
                              </label>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG or JPG up to 5MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {hasCoverImage && (
                    <div className="relative flex justify-center">
                      <img
                        className="max-h-64 object-contain"
                        id="image-overlayed"
                        src={coverImageSrc}
                        onError={() => {
                          setHasCoverImage(false);
                        }}
                      />
                      <button
                        type="button"
                        onClick={handleRemoveCoverImage}
                        className="absolute -top-2 -right-2 inline-flex items-center justify-center rounded-md bg-red p-1 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      >
                        <span className="sr-only">Close menu</span>
                        {/* Heroicon name: outline/x */}
                        <svg
                          className="h-3 w-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  )}

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        autoComplete="off"
                        placeholder="Enter a meaningful and exciting title"
                        value={title}
                        onChange={handleTitleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Challenge information
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={10}
                        placeholder="Describe the challenge and the prizes that participants can win"
                        value={description}
                        onChange={handleDescriptionChange}
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-1">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start date
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          Start
                        </span>
                        <input
                          type="date"
                          min={minDate}
                          max={maxDate}
                          className="block w-full rounded-none rounded-r-md border-gray-300 focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                          onChange={handleStartDateChange}
                          value={
                            startDate ? dateToYYYYmmdd(startDate) : undefined
                          }
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End date
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          End
                        </span>
                        <input
                          type="date"
                          min={minDate}
                          max={maxDate}
                          className="block w-full rounded-none rounded-r-md border-gray-300 focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                          onChange={handleEndDateChange}
                          value={endDate ? dateToYYYYmmdd(endDate) : undefined}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Presented By
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Upload a logo of the sponsor of this challenge. (optional)
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                {!hasSponsorLogo && (
                  <div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="sponsor_logo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Logo
                      </label>
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="flex justify-center text-sm text-gray-600">
                            <label
                              htmlFor="sponsor_logo"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-rotaxDark hover:text-rotaxDark-light"
                            >
                              <span>Upload the logo of the sponsor</span>
                              <input
                                id="sponsor_logo"
                                name="sponsor_logo"
                                type="file"
                                className="sr-only"
                                accept=".png,.jpg,.jpeg"
                                onChange={handleSponsorLogoChange}
                              />
                            </label>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG or JPG up to 2MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {hasSponsorLogo && (
                  <div className="relative flex justify-center">
                    <img
                      className="max-h-64 object-contain"
                      id="image-overlayed"
                      src={sponsorLogoSrc}
                      onError={() => {
                        setHasSponsorLogo(false);
                      }}
                    />
                    <button
                      type="button"
                      onClick={handleRemoveSponsorLogo}
                      className="absolute -top-2 -right-2 inline-flex items-center justify-center rounded-md bg-red p-1 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close menu</span>
                      {/* Heroicon name: outline/x */}
                      <svg
                        className="h-3 w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}

                {/* <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="sponsor_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor name
                    </label>
                    <input
                      type="text"
                      name="sponsor_name"
                      id="sponsor_name"
                      autoComplete="off"
                      placeholder="Enter the name of the sponsor"
                      value={sponsorName}
                      onChange={handleSponsorNameChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Criteria
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Select winning criterias for the challenge.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <CriteriaDropdown
                selectedMetric={metric}
                criteriaSelected={criteriaSelected}
              />
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Conditions
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Set conditions that need to be fulfilled for this challenge.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  {/* Filter options */}
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex items-center justify-between">
                      <span className="bg-white pr-3 text-sm text-gray-500">
                        Set conditions
                      </span>
                      <button
                        type="button"
                        onClick={addNewCondition}
                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                      >
                        {/* Heroicon name: solid/plus-sm */}
                        <svg
                          className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span>Add new condition</span>
                      </button>
                    </div>
                  </div>

                  {/* Filter options */}
                  {filterConditions.length > 0 && (
                    <div className="pt-4">
                      <div className="overflow-hidden rounded-md border border-gray-200 bg-white">
                        <FilterSelection
                          filterProperties={
                            RMDConstants.challengeFilterProperties
                          }
                          filterConditions={filterConditions}
                          firstRemoveable={true}
                          removeCondition={removeCondition}
                          updateCondition={updateCondition}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 mb-12">
          <div className="flex justify-end">
            <div>
              <button
                type="button"
                onClick={() => setShowConfirm(true)}
                className="inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
              >
                {challenge ? "Save changes" : "Create new challenge"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm dialog */}
      {showConfirm && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {challenge ? "Save changes" : "Create new challenge"}
                    </h3>
                    <div className="mt-2">
                      {
                        <p className="text-sm text-gray-500">
                          {challenge
                            ? "Are you sure you want to save the changes and modify the challenge?"
                            : "Are you sure you want to create a new challenge?"}
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={save}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {isSavingChallenge && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {challenge ? "Yes, save" : "Yes, create"}
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirm(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {challenge ? "No, don't save" : "No, don't create"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success dialog */}
      {showSuccess && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <CheckCircleIcon
                      className="currentColor h-6 w-6"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Success
                    </h3>
                    <div className="mt-2">
                      {
                        <p className="text-sm text-gray-500">
                          Sucessfully saved the challenge!
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => setShowSuccess(false)}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
