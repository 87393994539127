import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { AutomatedMarketingEmailTemplate } from "../API/data-contracts";
import AutomatedEmailTemplateListEntry from "../Components/EmailMarketing/AutomatedEmailTemplateListEntry";
import { useEmailMessage } from "../context/EmailMessageContext";
import { useErrorHandler } from "../Error/ErrorContext";

export default function EmailTemplates() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const { showError } = useErrorHandler();
  const [automatedEmailTemplates, setAutomatedEmailTemplates] =
    useState<AutomatedMarketingEmailTemplate[]>();
  const emailMessage = useEmailMessage();

  const loadAutomatedEmailTemplates = async () => {
    try {
      const response = await api.getAutomatedMarketingEmailTemplates();
      setAutomatedEmailTemplates(response.data);
    } catch (error) {
      showError(error);
    }
  };

  const editTemplate = (template: AutomatedMarketingEmailTemplate) => {
    emailMessage.initContext(template);
    navigate("/email-template", {
      state: { template: template },
    });
  };

  const toggleEnabled = async (template: AutomatedMarketingEmailTemplate) => {
    try {
      await api.updateAutomatedMarketingEmail(template);
    } catch (error) {
      loadAutomatedEmailTemplates();
      showError(error);
    }
  };

  useEffect(() => {
    loadAutomatedEmailTemplates();
  }, []);

  return (
    <div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Enabled
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {automatedEmailTemplates?.map((template, i) => {
                    return (
                      <AutomatedEmailTemplateListEntry
                        key={i}
                        template={template}
                        even={i % 2 == 0}
                        editTemplate={editTemplate}
                        toggleEnabled={toggleEnabled}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
