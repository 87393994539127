import React from "react";
import { FilterCondition } from "../../API/data-contracts";
import { CareerRank } from "../../model/career-rank";
import { OperatorType } from "../../model/filter-operator";
import { FilterProperty, FilterType } from "../../model/filter-property";
import { Weekday } from "../../model/weekday";
import { dateToYYYYmmdd, getDateString } from "../../util";
import MultiSelect from "./MultiSelect";

type Props = {
  filterProperties: FilterProperty[];
  filterConditions: FilterCondition[];
  firstRemoveable?: boolean;
  removeCondition(index: number): void;
  updateCondition(conditionIndex: number, condition: FilterCondition): void;
};

export default function FilterSelection({
  filterProperties,
  filterConditions,
  firstRemoveable,
  removeCondition,
  updateCondition,
}: Props) {
  const careerRanks = CareerRank.getCareerRanks();
  const weekdays = Weekday.getWeekdays();

  // this is required as the conditions properties from the backend are missing the operators
  filterConditions.map((condition) => {
    if (condition.property) {
      condition.property = FilterProperty.create(condition.property.type);
    }
  });

  const updateFilterProperty = (
    conditionIndex: number,
    condition: FilterCondition,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const propertyIndex = event.target.value;
    if (!isNaN(+propertyIndex)) {
      // reset condition whenever property changes
      condition = {
        input: [],
        property: new FilterProperty(),
        selectedOperator: null,
      };

      // set default operators
      condition.property = filterProperties[+propertyIndex];
      if (
        condition.property?.operators &&
        condition.property?.operators.length > 0
      ) {
        condition.selectedOperator = condition.property.operators[0];
      } else {
        condition.selectedOperator = null;
      }

      // set default input values
      if (condition.property?.type === FilterType.careerLevel) {
        condition.input = [careerRanks[0].name];
      }

      updateCondition(conditionIndex, condition);
    }
  };

  const updateFilterOperator = (
    conditionIndex: number,
    condition: FilterCondition,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const operatorIndex = event.target.value;
    if (!isNaN(+operatorIndex)) {
      condition.selectedOperator =
        condition.property?.operators[+operatorIndex];
      updateCondition(conditionIndex, condition);
    }
  };

  const updateInputValue = (
    conditionIndex: number,
    condition: FilterCondition,
    inputIndex: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (!isNaN(+inputValue)) {
      condition.input[inputIndex] = +inputValue;
      updateCondition(conditionIndex, condition);
    }
  };

  const updateCareerRank = (
    conditionIndex: number,
    condition: FilterCondition,
    careerRank: CareerRank
  ) => {
    condition.input = [careerRank.name];
    updateCondition(conditionIndex, condition);
  };

  const updateWeekdays = (
    conditionIndex: number,
    condition: FilterCondition,
    selection: string[]
  ) => {
    const selectedWeekdays: Weekday[] = weekdays.filter((weekday) =>
      selection.includes(weekday.name)
    );
    condition.input = selectedWeekdays.map((weekday) => weekday.value);
    updateCondition(conditionIndex, condition);
  };

  const updateJoined = (
    conditionIndex: number,
    condition: FilterCondition,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      condition.input = [getDateString(new Date(dateValue))];
    }
    updateCondition(conditionIndex, condition);
  };

  // the date since when we have data (1. Sept. 2021)
  let initialDate: Date = new Date("2021-09-01");
  let inThreeYears = new Date();
  inThreeYears.setFullYear(inThreeYears.getFullYear() + 3);

  const minDate = dateToYYYYmmdd(initialDate);
  const maxDate = dateToYYYYmmdd(inThreeYears);

  return (
    <ul className="divide-y divide-gray-200">
      {filterConditions.map((condition, i) => {
        // get the previously selected weekdays
        var selectedWeekdays: Weekday[] = [];
        if (condition.property?.type === FilterType.weekDay) {
          const selection =
            condition.input.map((weekDayValue) => weekDayValue) ?? [];
          selectedWeekdays = weekdays.filter((weekday) =>
            selection.includes(weekday.value)
          );
        }

        return (
          <li className="flex gap-2 px-6 py-4">
            <div className="flex-initial">
              <select
                className="form-control mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-rotaxDark focus:outline-none focus:ring-rotaxDark sm:text-sm"
                onChange={(event) => updateFilterProperty(i, condition, event)}
              >
                {filterProperties.map((property, i) => {
                  return (
                    <option
                      key={i}
                      value={i}
                      selected={
                        FilterType[property.type as keyof typeof FilterType] ==
                        condition?.property?.type.toString()
                      }
                    >
                      {property.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* CARRER LEVEL */}
            {condition.property?.type === FilterType.careerLevel && (
              <div className="flex-auto">
                <select
                  className="form-control mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-rotaxDark focus:outline-none focus:ring-rotaxDark sm:text-sm"
                  onChange={(event) =>
                    updateCareerRank(
                      i,
                      condition,
                      careerRanks[+event.target.value]
                    )
                  }
                >
                  {careerRanks.map((rank, i) => {
                    return (
                      <option
                        key={i}
                        value={i}
                        selected={condition.input[0] === rank.type}
                      >
                        {rank.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {/* WEEK DAY */}
            {condition.property?.type === FilterType.weekDay && (
              <div className="flex-auto">
                <MultiSelect
                  items={weekdays.map((weekday) => weekday.name)}
                  initiallySelectedItems={selectedWeekdays.map(
                    (weekday) => weekday.name
                  )}
                  selectionChanged={(selection) =>
                    updateWeekdays(i, condition, selection)
                  }
                />
              </div>
            )}

            {/* JOINED */}
            {condition.property?.type === FilterType.joined && (
              <div className="flex-auto">
                {condition.selectedOperator && (
                  <div className="flex gap-2">
                    <div className="flex-auto">
                      <select
                        className="form-control mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-rotaxDark focus:outline-none focus:ring-rotaxDark sm:text-sm"
                        onChange={(event) =>
                          updateFilterOperator(i, condition, event)
                        }
                      >
                        {condition.property?.operators?.map((operator, i) => {
                          return (
                            <option
                              key={i}
                              value={i}
                              selected={
                                condition.selectedOperator?.type ===
                                operator.type
                              }
                            >
                              {operator.type == OperatorType.greaterThan
                                ? "After"
                                : "Before"}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="flex-auto">
                      <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        onChange={(event) => updateJoined(i, condition, event)}
                        value={
                          condition.input[0]
                            ? dateToYYYYmmdd(new Date(condition.input[0]))
                            : undefined
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* EVERYTHING EXCEPT WEEK DAY, CAREER LEVEL AND JOINED */}
            {!(
              condition.property?.type === FilterType.weekDay ||
              condition.property?.type === FilterType.careerLevel ||
              condition.property?.type === FilterType.joined
            ) && (
              <div className="flex-auto">
                {condition.selectedOperator && (
                  <div className="flex gap-2">
                    <div className="flex-auto">
                      <select
                        className="form-control mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-rotaxDark focus:outline-none focus:ring-rotaxDark sm:text-sm"
                        onChange={(event) =>
                          updateFilterOperator(i, condition, event)
                        }
                      >
                        {condition.property?.operators?.map((operator, i) => {
                          return (
                            <option
                              key={i}
                              value={i}
                              selected={
                                condition.selectedOperator?.type ===
                                operator.type
                              }
                            >
                              {operator.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="flex-auto">
                      <div className="relative">
                        <input
                          type="number"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                          value={condition.input[0]}
                          onChange={(event) =>
                            updateInputValue(i, condition, 0, event)
                          }
                          placeholder="0"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-6">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            {FilterProperty.getInputTypeName(
                              filterConditions[i].property?.inputType
                            )}
                          </span>
                        </div>
                      </div>

                      {condition.selectedOperator.type ==
                        OperatorType.range && (
                        <div>
                          <p className="py-1 text-center text-sm text-gray-500">
                            and
                          </p>

                          <div className="relative">
                            <input
                              type="number"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                              value={condition.input[1]}
                              onChange={(event) =>
                                updateInputValue(i, condition, 1, event)
                              }
                              placeholder="0"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-6">
                              <span
                                className="text-gray-500 sm:text-sm"
                                id="price-currency"
                              >
                                {FilterProperty.getInputTypeName(
                                  filterConditions[i].property?.inputType
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              hidden={i == 0 && !firstRemoveable}
              className="w-6 flex-grow-0 self-center justify-self-end"
            >
              <button
                onClick={() => removeCondition(i)}
                className="inline-flex rounded-md bg-white align-middle text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                {/* Heroicon name: solid/x */}
                <svg
                  className="h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
