import React from "react";
import { PushPayloadRes } from "../../API/data-contracts";
import { RMDConstants } from "../../model/rmdconstants";
import { classNames, getFormattedDateString, isDateInFuture } from "../../util";

type Props = {
  notification: PushPayloadRes;
  even: boolean;
  deletePayloadById(id: string): void;
};

export default function PushNotificationListEntry({
  notification,
  even,
  deletePayloadById,
}: Props) {
  const deleteNotification = () => {
    const title = "Delete push message?";
    if (notification.scheduleDate != null && !notification.delivered) {
      if (
        window.confirm(title + "\nThis will prevent the push from being sent.")
      ) {
        deletePayloadById(notification.id);
      }
    } else {
      if (
        window.confirm(
          title + '\nDeleting cannot "take back" already sent messages.'
        )
      ) {
        deletePayloadById(notification.id);
      }
    }
  };

  return (
    <tr className={classNames(even ? "bg-white" : "bg-gray-50", "text-left")}>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <p className="font-medium text-gray-900">
          {notification.message.title.length > 30
            ? notification.message.title.substring(0, 30) + "..."
            : notification.message.title}
        </p>
      </td>

      <td className="whitespace-normal break-normal px-6 py-4 text-sm text-gray-500">
        <p className="font-medium text-gray-900">
          {notification.message.body.length > 70
            ? notification.message.body.substring(0, 70) + "..."
            : notification.message.body}
        </p>
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {notification.recipients}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {notification.delivered ? notification.successCount : "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {notification.delivered ? notification.views : "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm">
        <span
          className={classNames(
            isDateInFuture(notification.scheduleDate)
              ? "bg-gray-200 px-2 text-gray-500"
              : "bg-green-100 px-3 text-green-600",
            "inline-flex rounded-full text-xs font-semibold leading-5"
          )}
        >
          {isDateInFuture(notification.scheduleDate) ? "Pending" : "Sent"}
        </span>
      </td>

      <td className="whitespace-nowrap px-5 py-4 text-sm text-gray-500">
        {notification.scheduleDate
          ? getFormattedDateString(notification.scheduleDate)
          : getFormattedDateString(notification.createdDate)}
      </td>
      <td>
        <div className="flex items-center">
          <button
            className="ml-6 inline-flex items-center rounded-full p-1 text-gray-400 focus:outline-none"
            onClick={() => deleteNotification()}
          >
            🗑️
          </button>
        </div>
      </td>
    </tr>
  );
}
