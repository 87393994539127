import { UserIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useAuth } from "../../API/AuthContext";
import { Challenge } from "../../API/data-contracts";
import { classNames, getFormattedDateString, imageUrl } from "../../util";

type Props = {
  challenge: Challenge;
  editChallenge(challenge: Challenge): void;
};

export const ChallengeCard: React.FC<Props> = ({
  challenge,
  editChallenge,
}) => {
  const { accessToken } = useAuth();
  const [hasCoverImage, setHasCoverImage] = useState(
    challenge?.imageId !== undefined
  );
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: "medium",
  });

  let fromDateString = "";
  if (challenge.from) {
    const fromDate = new Date(challenge.from);
    try {
      // this crashes on some browsers (e.g. Safari), therefore we need a workaround
      fromDateString = getFormattedDateString(fromDate, dateFormatter) ?? "";
    } catch (error) {
      fromDateString = fromDate.toLocaleDateString();
    }
  }
  let toDateString = "";
  if (challenge.to) {
    const toDate = new Date(challenge.to);
    try {
      // this crashes on some browsers (e.g. Safari), therefore we need a workaround
      toDateString = getFormattedDateString(toDate, dateFormatter) ?? "";
    } catch (error) {
      toDateString = toDate.toLocaleDateString();
    }
  }

  return (
    <motion.div
      className={
        "cursor-pointer flex-col items-center rounded bg-white text-left"
      }
      whileHover={{
        scale: 1.05,
        opacity: 0.9,
        transition: { duration: 0.1 },
      }}
      onClick={() => editChallenge(challenge)}
    >
      <div className="space-y-3">
        <div className="h-36 w-full">
          {hasCoverImage && (
            <img
              className="h-full w-full rounded-t-lg object-cover shadow-lg"
              src={imageUrl(challenge?.imageId, accessToken())}
              onError={() => {
                setHasCoverImage(false);
              }}
              alt=""
            />
          )}
        </div>

        <div className="px-3 pb-4 text-xs">
          <div className="leading-6">
            <h3 className="text-base">{challenge.title}</h3>
            <p className="text-gray-400">
              {fromDateString} - {toDateString}
            </p>
          </div>
          <div className="group flex pt-5">
            <UserIcon
              className={classNames("mr-4 h-4 w-4 flex-shrink-0 lg:mr-1")}
              aria-hidden="true"
            />
            <p>{challenge.participants.length} participants</p>
            <div
              className={classNames(
                challenge.status == "Running" ? "bg-green-700" : "",
                challenge.status == "Upcoming" ? "bg-yellow" : "",
                challenge.status == "Completed" ? "bg-red" : "",
                "ml-8 h-2 w-2 self-center rounded-full"
              )}
            ></div>
            <p className="ml-2">{challenge.status}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
