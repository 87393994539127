import { FilterOperator, OperatorType } from "./filter-operator";

export class FilterProperty {
  type: FilterType;
  operators: FilterOperator[];
  inputType: InputType;

  get name(): string {
    switch (this.type) {
      case FilterType.none:
        return "No condition";
      case FilterType.lastVisit:
        return "Last visit";
      case FilterType.joined:
        return "Joined";
      case FilterType.age:
        return "Age";
      case FilterType.homeBase:
        return "Home base";
      case FilterType.language:
        return "Language";
      case FilterType.birthday:
        return "Birthday";
      case FilterType.gender:
        return "Gender";
      case FilterType.postcode:
        return "Post code";
      case FilterType.platform:
        return "Platform";
      case FilterType.weekDay:
        return "Weekday";
      case FilterType.careerLevel:
        return "Career rank";
    }
  }

  get inputTypeName() {
    return FilterProperty.getInputTypeName(this.inputType);
  }

  constructor(
    type: FilterType = FilterType.none,
    operators: FilterOperator[] = [],
    inputType: InputType = InputType.text
  ) {
    this.type = type;
    this.operators = operators;
    this.inputType = inputType;
  }

  static create(filter: FilterType): FilterProperty {
    return new FilterProperty(
      filter,
      FilterProperty.getOperators(filter),
      FilterProperty.getInputType(filter)
    );
  }

  static getInputTypeName(inputType: InputType | undefined): string {
    switch (inputType) {
      case InputType.days:
        return "day(s)";
      case InputType.years:
        return "year(s)";
      default:
        return "";
    }
  }

  static getOperators(filter: FilterType | undefined): FilterOperator[] {
    switch (filter) {
      case FilterType.none:
        return [];
      case FilterType.lastVisit:
        return [
          new FilterOperator(OperatorType.greaterThan),
          new FilterOperator(OperatorType.lessThan),
        ];
      case FilterType.age:
        return [
          new FilterOperator(OperatorType.greaterThan),
          new FilterOperator(OperatorType.lessThan),
          new FilterOperator(OperatorType.range),
        ];
      case FilterType.joined:
        return [
          new FilterOperator(OperatorType.greaterThan),
          new FilterOperator(OperatorType.lessThan),
        ];
      case FilterType.weekDay:
        return [new FilterOperator(OperatorType.multiSelect)];
      case FilterType.careerLevel:
        return [new FilterOperator(OperatorType.picker)];
      default:
        return [];
    }
  }

  static getInputType(filter: FilterType): InputType {
    switch (filter) {
      case FilterType.none:
        return InputType.text;
      case FilterType.lastVisit:
        return InputType.days;
      case FilterType.age:
        return InputType.years;
      case FilterType.joined:
        return InputType.isoDate;
      case FilterType.weekDay:
        return InputType.number;
      default:
        return InputType.text;
    }
  }

  static getConditionType(filter: FilterType | undefined): ConditionType {
    switch (filter) {
      case FilterType.age:
      case FilterType.joined:
        return ConditionType.user;
      case FilterType.weekDay:
        return ConditionType.race;
      case FilterType.careerLevel:
        return ConditionType.driver;
      default:
        return ConditionType.none;
    }
  }
}

export enum FilterType {
  none = "none",
  lastVisit = "lastVisit",
  joined = "joined",
  age = "age",
  homeBase = "homeBase",
  language = "language",
  birthday = "birthday",
  gender = "gender",
  postcode = "postcode",
  platform = "platform",
  weekDay = "weekDay",
  careerLevel = "careerLevel",
}

export enum InputType {
  days = "days",
  years = "years",
  isoDate = "isoDate",
  text = "text",
  number = "number",
}

export enum ConditionType {
  none = "none",
  user = "user",
  race = "race",
  driver = "driver",
}
