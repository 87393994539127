import { useEffect, useState } from "react";
import { useAuth } from "../../API/AuthContext";
import { EmailTemplate } from "../../API/data-contracts";
import { AnimatedOpacityDiv } from "../../common/AnimatedOpacityDiv";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { useEmailMessage } from "../../context/EmailMessageContext";
import { classNames, showError } from "../../util";
import { EmailTemplatePage } from "../EmailMarketing/EmailTemplatePage";

type Props = {
  selectedTemplateId: number | undefined;
};

export default function EmailTemplateSelection({ selectedTemplateId }: Props) {
  const { api } = useAuth();
  const emailMessage = useEmailMessage();
  const [loading, setLoading] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>();

  const loadEmailTemplates = async () => {
    try {
      const response = await api.getEmailTemplates();
      const emailTemplates = response.data;
      if (emailTemplates) {
        // filter out templates with empty htmlContent
        setEmailTemplates(
          emailTemplates.filter(
            (template) =>
              template.htmlContent && template.htmlContent.length > 0
          )
        );

        // select the preselected template
        preSelectTemplate(emailTemplates);
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  function preSelectTemplate(emailTemplates: EmailTemplate[]) {
    const preSelectedTemplate = emailTemplates.find(
      (template) => template.id === selectedTemplateId
    );
    if (preSelectedTemplate) {
      emailMessage.setTemplate(preSelectedTemplate);
    }
  }

  const selectTemplate = (template: EmailTemplate) => {
    emailMessage.clearContext();
    emailMessage.setTemplate(template);
    template.params.forEach((param) => {
      // set the initial value of every param to an empty string
      emailMessage.setParameters(
        new Map(
          emailMessage.parameters.set(param.paramName, "")
        )
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    loadEmailTemplates();
  }, []);

  return (
    <div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Choose template
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Templates are created by the Rotax Marketing team. Select one
                that you want to send out to your customers.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <AnimatedOpacityDiv key={"page_overview_0"}>
                  <div className="relative m-2 flex flex-col">
                    <div
                      className={classNames(
                        "grid w-full gap-8",
                        emailTemplates && emailTemplates.length > 0
                          ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4"
                          : ""
                      )}
                    >
                      {emailTemplates && emailTemplates.length > 0 ? (
                        emailTemplates.map((template, index) => {
                          return (
                            <EmailTemplatePage
                              key={index}
                              template={template}
                              selected={
                                template.id === emailMessage.template?.id
                              }
                              selectTemplate={selectTemplate}
                            />
                          );
                        })
                      ) : loading ? (
                        <div className="text-text mt-32 flex flex-col items-center justify-center gap-6">
                          <LoadingIndicator />
                        </div>
                      ) : (
                        <div className="text-text mt-32 flex flex-col items-center justify-center gap-6">
                          <p className="text-lg font-bold uppercase">
                            Es wurden keine Templates gefunden ...
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </AnimatedOpacityDiv>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
