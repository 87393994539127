/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Achievement,
  AdditionalData,
  AutomatedMarketingEmailTemplate,
  AutomatedMarketingEmailTemplateUpdate,
  Avatar,
  CareerLevel,
  CareerRankingRecord,
  CareerState,
  Challenge,
  ChallengeCreateUpdate,
  ChallengeLeader,
  ChallengeLeaderBoard,
  ChallengeStats,
  CMSPreview,
  EmailCampaign,
  EmailMarketingPreview,
  EmailTemplate,
  ErrorLogs,
  ErrorResponse,
  GooglePass,
  HasPersonResponse,
  ImageDelete,
  InboxMessage,
  InboxMessagePayload,
  InboxMessageTemplateRes,
  InboxStats,
  InputStream,
  InputStreamResource,
  LoginCodeResponse,
  LoginResponse,
  MarketingEmail,
  MarketingEmailCreate,
  OpeningTimes,
  Order,
  PageableObject,
  PageChallenge,
  PageInboxMessagePayload,
  PagePushPayload,
  PageRankingList,
  PageUser,
  PassRegistration,
  PassRequiredResponse,
  PassStateResponse,
  PKSerialNumbersResponse,
  Profile,
  ProfileUpdate,
  PushPayloadReq,
  PushPayloadRes,
  PushStats,
  Race,
  RaceSession,
  RaceSessionData,
  RaceSessionDetails,
  RaceSessionLapDetails,
  RankingList,
  RankingListCreateUpdate,
  RankingListLeaderBoard,
  ResourceRegion,
  RotaxRecord,
  SendMarketingEmailResponse,
  SignupPayload,
  SmsTimingUser,
  StreamingResponseBody,
  TestEmailBody,
  Unit,
  UrlResource,
  User,
  UserStats,
  UserSyncResponse,
  WebExpResultReq,
  WebExpResultRes,
  WebExpStats,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags analytics-resource
   * @name InboxCtaUsingGet
   * @summary inboxCta
   * @request GET:/api/analytics/inbox-cta
   */
  inboxCtaUsingGet = (query: { id: string }, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/analytics/inbox-cta`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags analytics-resource
   * @name InboxViewUsingGet
   * @summary inboxView
   * @request GET:/api/analytics/inbox-view
   */
  inboxViewUsingGet = (query: { id: string }, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/analytics/inbox-view`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags analytics-resource
   * @name PushViewUsingGet
   * @summary pushView
   * @request GET:/api/analytics/push-view
   */
  pushViewUsingGet = (query: { id: string }, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/analytics/push-view`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description Called by the Apple to get the serial numbers for passes associated with a device.
   *
   * @tags apple-pass-resource
   * @name SerialNumbersUsingGet
   * @summary serialNumbers
   * @request GET:/api/user/pass/apple/v1/devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}
   */
  serialNumbersUsingGet = (
    deviceLibraryIdentifier: string,
    passTypeIdentifier: string,
    query?: { passesUpdatedSince?: string },
    params: RequestParams = {}
  ) =>
    this.request<PKSerialNumbersResponse, void>({
      path: `/api/user/pass/apple/v1/devices/${deviceLibraryIdentifier}/registrations/${passTypeIdentifier}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description Called by the Apple device to register for pass updates.
   *
   * @tags apple-pass-resource
   * @name RegisterUsingPost
   * @summary register
   * @request POST:/api/user/pass/apple/v1/devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}/{serialNumber}
   */
  registerUsingPost = (
    deviceLibraryIdentifier: string,
    passTypeIdentifier: string,
    serialNumber: string,
    data: PassRegistration,
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/pass/apple/v1/devices/${deviceLibraryIdentifier}/registrations/${passTypeIdentifier}/${serialNumber}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Called by the Apple device to unregister for pass updates.
   *
   * @tags apple-pass-resource
   * @name UnregisterUsingDelete
   * @summary unregister
   * @request DELETE:/api/user/pass/apple/v1/devices/{deviceLibraryIdentifier}/registrations/{passTypeIdentifier}/{serialNumber}
   */
  unregisterUsingDelete = (
    deviceLibraryIdentifier: string,
    passTypeIdentifier: string,
    serialNumber: string,
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/pass/apple/v1/devices/${deviceLibraryIdentifier}/registrations/${passTypeIdentifier}/${serialNumber}`,
      method: "DELETE",
      ...params,
    });
  /**
   * @description Called by Apple to notify about errors concerning passes.
   *
   * @tags apple-pass-resource
   * @name LogErrorUsingPost
   * @summary logError
   * @request POST:/api/user/pass/apple/v1/log
   */
  logErrorUsingPost = (data: ErrorLogs, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/user/pass/apple/v1/log`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Called by Apple to get a particular pass (usually after receiving an update push).
   *
   * @tags apple-pass-resource
   * @name GetPassUsingGet
   * @summary getPass
   * @request GET:/api/user/pass/apple/v1/passes/{passTypeIdentifier}/{serialNumber}
   */
  getPassUsingGet = (
    passTypeIdentifier: string,
    serialNumber: string,
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/user/pass/apple/v1/passes/${passTypeIdentifier}/${serialNumber}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource
   * @name ReceiveUsingPost
   * @summary receive
   * @request POST:/api/avatar
   */
  receiveUsingPost = (data: InputStream, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/avatar`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource-mobile
   * @name CompositingUsingGet
   * @summary compositing
   * @request GET:/api/avatar/compositing
   */
  compositingUsingGet = (
    query: {
      template: string;
      lapTime: string;
      rank: number;
      location?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/avatar/compositing`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource-mobile
   * @name GetAvatarUsingGet
   * @summary getAvatar
   * @request GET:/api/avatar/get
   */
  getAvatarUsingGet = (
    query: { template: string },
    params: RequestParams = {}
  ) =>
    this.request<Avatar, void>({
      path: `/api/avatar/get`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource-mobile
   * @name GetAvatarStreamUsingGet
   * @summary getAvatarStream
   * @request GET:/api/avatar/stream
   */
  getAvatarStreamUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
      userId?: string;
      email?: string;
      template?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<StreamingResponseBody, void>({
      path: `/api/avatar/stream`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource-mobile
   * @name GetAvatarStreamRangeUsingGet
   * @summary getAvatarStreamRange
   * @request GET:/api/avatar/stream-range
   */
  getAvatarStreamRangeUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
      userId?: string;
      email?: string;
      template?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ResourceRegion, void>({
      path: `/api/avatar/stream-range`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags avatar-resource-mobile
   * @name GetAvatarStreamUrlUsingGet
   * @summary getAvatarStreamUrl
   * @request GET:/api/avatar/stream-url
   */
  getAvatarStreamUrlUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
      userId?: string;
      email?: string;
      template?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<UrlResource, void>({
      path: `/api/avatar/stream-url`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name DeleteDeletionRequestedUsingDelete
   * @summary deleteDeletionRequested
   * @request DELETE:/api/cms/deletion-requested
   */
  deleteDeletionRequestedUsingDelete = (params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/cms/deletion-requested`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetInboxPayloadsUsingGet
   * @summary getInboxPayloads
   * @request GET:/api/cms/inbox
   */
  getInboxPayloadsUsingGet = (
    query?: {
      offset?: number;
      page?: number;
      pageSize?: number;
      paged?: boolean;
      sort?: string;
      unpaged?: boolean;
      includeTemplatePayloads?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.request<PageInboxMessagePayload, void>({
      path: `/api/cms/inbox`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name DeleteInboxPayloadUsingDelete
   * @summary deleteInboxPayload
   * @request DELETE:/api/cms/inbox
   */
  deleteInboxPayloadUsingDelete = (
    query: { id: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/cms/inbox`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetInboxPreviewUsingPost
   * @summary getInboxPreview
   * @request POST:/api/cms/inbox-preview
   */
  getInboxPreviewUsingPost = (
    data: InboxMessagePayload,
    params: RequestParams = {}
  ) =>
    this.request<CMSPreview, void>({
      path: `/api/cms/inbox-preview`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetInboxImageUsingGet
   * @summary getInboxImage
   * @request GET:/api/cms/inbox/image/{imageId}
   */
  getInboxImageUsingGet = (imageId: string, params: RequestParams = {}) =>
    this.request<string, void>({
      path: `/api/cms/inbox/image/${imageId}`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags Images
   * @name GetImage
   * @summary Gets the image with the provided id
   * @request GET:/api/image/{id}
   */
  getImage = (id: string, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/image/${id}`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetInboxTemplatesUsingGet
   * @summary getInboxTemplates
   * @request GET:/api/cms/inbox/template
   */
  getInboxTemplatesUsingGet = (params: RequestParams = {}) =>
    this.request<InboxMessageTemplateRes[], void>({
      path: `/api/cms/inbox/template`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name UpdateInboxTemplate
   * @summary Updates the provided inbox template
   * @request PUT:/api/cms/inbox/template
   * @secure
   */
  updateInboxTemplate = (
    data: InboxMessageTemplateRes,
    params: RequestParams = {}
  ) =>
    this.request<InboxMessageTemplateRes, any>({
      path: `/api/cms/inbox/template`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name UpdateInboxTemplateImages
   * @summary Updates the images of the inbox template with the provided id
   * @request PUT:/api/cms/inbox/template/{templateId}/images
   * @secure
   */
  updateInboxTemplateImages = (
    templateId: string,
    data: { images: File[] },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/inbox/template/${templateId}/images`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name ToggleInboxHideUsingGet
   * @summary toggleInboxHide
   * @request GET:/api/cms/inbox/toggle-hide
   */
  toggleInboxHideUsingGet = (
    query: { id: string; hidden: boolean },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/cms/inbox/toggle-hide`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name GetTemplateInboxPayloads
   * @summary Gets all inbox payloads that originated from templates.
   * @request GET:/api/cms/inbox/template/payloads
   * @secure
   */
  getTemplateInboxPayloads = (
    query: { pageable: PageableObject },
    params: RequestParams = {}
  ) =>
    this.request<PageInboxMessagePayload, any>({
      path: `/api/cms/inbox/template/payloads`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS
   * @name GetInboxPayloads
   * @request GET:/api/cms/inbox
   * @secure
   */
  getInboxPayloads = (
    query: {
      page?: number;
      size?: number;
      sort?: string;
      includeTemplatePayloads?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.request<PageInboxMessagePayload, any>({
      path: `/api/cms/inbox`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name CreateInboxMessage
   * @summary Creates a new inbox payload
   * @request POST:/api/cms/inbox/create
   * @secure
   */
  createInboxMessage = (
    data: InboxMessagePayload,
    params: RequestParams = {}
  ) =>
    this.request<InboxMessagePayload, any>({
      path: `/api/cms/inbox/create`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name UpdateInboxMessage
   * @summary Updates the provided inbox payload
   * @request PUT:/api/cms/inbox
   * @secure
   */
  updateInboxMessage = (
    data: InboxMessagePayload,
    params: RequestParams = {}
  ) =>
    this.request<InboxMessagePayload, any>({
      path: `/api/cms/inbox`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name DeleteInboxPayload
   * @request DELETE:/api/cms/inbox
   * @secure
   */
  deleteInboxPayload = (query: { id: string }, params: RequestParams = {}) =>
    this.request<Unit, any>({
      path: `/api/cms/inbox`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name UpdateInboxMessageImages
   * @summary Updates the images of the inbox payload with the provided id
   * @request PUT:/api/cms/inbox/{payloadId}/images
   * @secure
   */
  updateInboxMessageImages = (
    payloadId: string,
    data: { images: File[] },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/inbox/${payloadId}/images`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Inbox
   * @name DeleteInboxMessageImages
   * @summary Deletes the images with the provided ids from the inbox payload with the provided id
   * @request DELETE:/api/cms/inbox/{payloadId}/images
   * @secure
   */
  deleteInboxMessageImages = (
    payloadId: string,
    data: ImageDelete,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/inbox/${payloadId}/images`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Inbox
   * @name AddInboxMessageImage
   * @summary Adds the provided image to the inbox payload with the provided id. New images are added to the back.
   * @request PUT:/api/cms/inbox/{payloadId}/image
   * @secure
   */
  addInboxMessageImage = (
    payloadId: string,
    data: { image: File },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/inbox/${payloadId}/image`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS
   * @name GetInboxStats
   * @summary Gets information about inbox messages like count of hidden and visible.
   * @request GET:/api/cms/inbox-stats
   * @secure
   */
  getInboxStats = (params: RequestParams = {}) =>
    this.request<InboxStats, any>({
      path: `/api/cms/inbox-stats`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Challenges
   * @name UpdateChallenge
   * @summary Updates the challenge with the provided id
   * @request PUT:/api/cms/challenge/{id}
   * @secure
   */
  updateChallenge = (
    id: string,
    data: ChallengeCreateUpdate,
    params: RequestParams = {}
  ) =>
    this.request<Challenge, any>({
      path: `/api/cms/challenge/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Challenges
   * @name SetChallengeSponsorImage
   * @summary Sets the provided sponsor image for the challenge with the provided id, will remove image if none is provided
   * @request PUT:/api/cms/challenge/{id}/sponsor/image
   * @secure
   */
  setChallengeSponsorImage = (
    id: string,
    data: { image: File },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/challenge/${id}/sponsor/image`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Challenges
   * @name SetChallengeImage
   * @summary Sets the provided image as the image for the challenge with the provided id, will remove image if none is provided
   * @request PUT:/api/cms/challenge/{id}/image
   * @secure
   */
  setChallengeImage = (
    id: string,
    data: { image: File },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/challenge/${id}/image`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Challenges
   * @name DeleteChallenge
   * @summary Deletes the challenge (and related image) with the provided id
   * @request DELETE:/api/cms/challenge/{id}
   * @secure
   */
  deleteChallenge = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/cms/challenge/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name LeaveChallenge
   * @summary Leaves the challenge with the provided id
   * @request PUT:/api/challenge/{id}/leave
   * @secure
   */
  leaveChallenge = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/challenge/${id}/leave`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name JoinChallenge
   * @summary Joins the challenge with the provided id
   * @request PUT:/api/challenge/{id}/join
   * @secure
   */
  joinChallenge = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/challenge/${id}/join`,
      method: "PUT",
      secure: true,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Challenges
   * @name GetChallengeStats
   * @summary Gets all challenge stats
   * @request GET:/api/cms/challenge/stats
   * @secure
   */
  getChallengeStats = (params: RequestParams = {}) =>
    this.request<ChallengeStats, any>({
      path: `/api/cms/challenge/stats`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Challenges
   * @name CreateChallenge
   * @summary Creates a new challenge
   * @request POST:/api/cms/challenge
   * @secure
   */
  createChallenge = (data: ChallengeCreateUpdate, params: RequestParams = {}) =>
    this.request<Challenge, any>({
      path: `/api/cms/challenge`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Challenges
   * @name GetChallenges
   * @summary Gets all challenges
   * @request GET:/api/cms/challenge
   * @secure
   */
  getChallenges = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {}
  ) =>
    this.request<PageChallenge, any>({
      path: `/api/cms/challenge`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetChallengeImage
   * @summary Gets the image of the challenge with the provided id
   * @request GET:/api/challenges/{id}/image
   */
  getChallengeImage = (id: string, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/challenges/${id}/image`,
      method: "GET",
      format: "blob",
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetUserChallenges
   * @summary Gets all challenges the authenticated user is participating in or has participated in
   * @request GET:/api/challenge/user
   * @secure
   */
  getUserChallenges = (params: RequestParams = {}) =>
    this.request<Challenge[], any>({
      path: `/api/challenge/user`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetRunningChallenges
   * @summary Gets all running (active) challenges
   * @request GET:/api/challenge/running
   * @secure
   */
  getRunningChallenges = (params: RequestParams = {}) =>
    this.request<Challenge[], any>({
      path: `/api/challenge/running`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetChallengeLeaderForMetric
   * @summary Gets the current leader (first place) for the provided metric
   * @request GET:/api/challenge/leader
   * @secure
   */
  getChallengeLeaderForMetric = (
    query: {
      metric: "VictoryPoints" | "BestLapTime" | "MostVisits" | "MostPoints";
    },
    params: RequestParams = {}
  ) =>
    this.request<ChallengeLeader, any>({
      path: `/api/challenge/leader`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetChallengeLeaders
   * @summary Gets the current leader (first place) for all available metrics
   * @request GET:/api/challenge/leaders
   * @secure
   */
  getChallengeLeaders = (params: RequestParams = {}) =>
    this.request<ChallengeLeader[], any>({
      path: `/api/challenge/leaders`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetCompletedChallenges
   * @summary Gets the latest challenges that are already completed
   * @request GET:/api/challenge/completed
   * @secure
   */
  getCompletedChallenges = (
    query?: { limit?: number },
    params: RequestParams = {}
  ) =>
    this.request<Challenge[], any>({
      path: `/api/challenge/completed`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Challenges
   * @name GetChallengeLeaderBoard
   * @summary Gets the leaderboard of the challenge with the provided id
   * @request GET:/api/challenge/{id}/leaderboard
   * @secure
   */
  getChallengeLeaderBoard = (id: string, params: RequestParams = {}) =>
    this.request<ChallengeLeaderBoard, any>({
      path: `/api/challenge/${id}/leaderboard`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetPushPayloadsUsingGet
   * @summary getPushPayloads
   * @request GET:/api/cms/push
   */
  getPushPayloadsUsingGet = (
    query?: {
      offset?: number;
      page?: number;
      pageSize?: number;
      paged?: boolean;
      sort?: string;
      unpaged?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.request<PagePushPayload, void>({
      path: `/api/cms/push`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name SendPushUsingPost
   * @summary sendPush
   * @request POST:/api/cms/push
   */
  sendPushUsingPost = (data: PushPayloadReq, params: RequestParams = {}) =>
    this.request<PushPayloadRes, void>({
      path: `/api/cms/push`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetPushPreviewUsingPost
   * @summary getPushPreview
   * @request POST:/api/cms/push-preview
   */
  getPushPreviewUsingPost = (
    data: PushPayloadReq,
    params: RequestParams = {}
  ) =>
    this.request<CMSPreview, void>({
      path: `/api/cms/push-preview`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetPushStatsUsingGet
   * @summary getPushStats
   * @request GET:/api/cms/push-stats
   */
  getPushStatsUsingGet = (params: RequestParams = {}) =>
    this.request<PushStats, void>({
      path: `/api/cms/push-stats`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name DeletePushUsingDelete
   * @summary deletePush
   * @request DELETE:/api/cms/push/{id}
   */
  deletePushUsingDelete = (id: string, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/cms/push/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name DeleteUserUsingDelete
   * @summary deleteUser
   * @request DELETE:/api/cms/user
   */
  deleteUserUsingDelete = (
    query?: { id?: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/cms/user`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name UserExportUsingGet
   * @summary userExport
   * @request GET:/api/cms/user-export
   */
  userExportUsingGet = (
    query?: { fileName?: string },
    params: RequestParams = {}
  ) =>
    this.request<InputStreamResource, void>({
      path: `/api/cms/user-export`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetUserStatsUsingGet
   * @summary getUserStats
   * @request GET:/api/cms/user-stats
   */
  getUserStatsUsingGet = (params: RequestParams = {}) =>
    this.request<UserStats, void>({
      path: `/api/cms/user-stats`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetUserAvatarDataUsingGet
   * @summary getUserAvatarData
   * @request GET:/api/cms/user/{id}/avatars
   */
  getUserAvatarDataUsingGet = (id: string, params: RequestParams = {}) =>
    this.request<Avatar[], void>({
      path: `/api/cms/user/${id}/avatars`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name ListUsingGet
   * @summary list
   * @request GET:/api/cms/users
   */
  listUsingGet = (
    query?: {
      offset?: number;
      page?: number;
      pageSize?: number;
      paged?: boolean;
      searchQuery?: string;
      sort?: string;
      unpaged?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.request<PageUser, void>({
      path: `/api/cms/users`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS User
   * @name GetById
   * @request GET:/api/cms/user/{id}
   * @secure
   */
  getUserById = (id: string, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/cms/user/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags cms-resource
   * @name GetWebExpStatsUsingGet
   * @summary getWebExpStats
   * @request GET:/api/cms/web-exp-stats
   */
  getWebExpStatsUsingGet = (params: RequestParams = {}) =>
    this.request<WebExpStats, void>({
      path: `/api/cms/web-exp-stats`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags confirm-email-resource
   * @name ConfirmEmailSuccessUsingGet
   * @summary confirmEmailSuccess
   * @request GET:/api/email-confirm
   */
  confirmEmailSuccessUsingGet = (params: RequestParams = {}) =>
    this.request<string, void>({
      path: `/api/email-confirm`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags error-resource
   * @name ErrorUsingGet1
   * @summary error
   * @request GET:/api/error
   */
  errorUsingGet1 = (params: RequestParams = {}) =>
    this.request<ErrorResponse, void>({
      path: `/api/error`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags error-resource
   * @name ListUsingGet1
   * @summary list
   * @request GET:/api/error/all
   */
  listUsingGet1 = (params: RequestParams = {}) =>
    this.request<string[], void>({
      path: `/api/error/all`,
      method: "GET",
      ...params,
    });
  /**
   * @description Called by the Google whenever the user makes changes to the wallet, e.g. removes or saves a pass.
   *
   * @tags google-pass-resource
   * @name GooglePayPassCallbackUsingPost
   * @summary googlePayPassCallback
   * @request POST:/api/user/pass/google
   */
  googlePayPassCallbackUsingPost = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/user/pass/google`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags inbox-message-resource
   * @name GetInboxUsingGet
   * @summary getInbox
   * @request GET:/api/inbox
   */
  getInboxUsingGet = (params: RequestParams = {}) =>
    this.request<InboxMessage[], void>({
      path: `/api/inbox`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags inbox-message-resource
   * @name MarkAsDismissedUsingPut
   * @summary markAsDismissed
   * @request PUT:/api/inbox/dismiss
   */
  markAsDismissedUsingPut = (
    query: { id: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/inbox/dismiss`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags inbox-message-resource
   * @name GetInboxImageUsingGet1
   * @summary getInboxImage
   * @request GET:/api/inbox/image/{payloadId}/{imageIndex}
   */
  getInboxImageUsingGet1 = (
    payloadId: string,
    imageIndex: number,
    query?: { size?: number },
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/inbox/image/${payloadId}/${imageIndex}`,
      method: "GET",
      query: query,
      format: "blob",
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name GetRankingLists1
   * @summary Gets all ranking lists
   * @request GET:/api/cms/ranking-list
   * @secure
   */
  getRankingLists = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {}
  ) =>
    this.request<PageRankingList, any>({
      path: `/api/cms/ranking-list`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name GetRankingListsUser
   * @summary Gets all ranking lists created by normal users via the app
   * @request GET:/api/cms/ranking-list/user-created
   * @secure
   */
  getRankingListsUser = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {}
  ) =>
    this.request<PageRankingList, any>({
      path: `/api/cms/ranking-list/user-created`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name GetRankingListsAdmin
   * @summary Gets all ranking lists created by admins in the CMS
   * @request GET:/api/cms/ranking-list/admin-created
   * @secure
   */
  getRankingListsAdmin = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {}
  ) =>
    this.request<PageRankingList, any>({
      path: `/api/cms/ranking-list/admin-created`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name CreateRankingList
   * @summary Creates a new ranking list
   * @request POST:/api/cms/ranking-list
   * @secure
   */
  createRankingList = (
    data: RankingListCreateUpdate,
    params: RequestParams = {}
  ) =>
    this.request<RankingList, any>({
      path: `/api/cms/ranking-list`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name UpdateRankingList
   * @summary Updates the ranking list with the provided id
   * @request PUT:/api/cms/ranking-list/{id}
   * @secure
   */
  updateRankingList = (
    id: string,
    data: RankingListCreateUpdate,
    params: RequestParams = {}
  ) =>
    this.request<RankingList, any>({
      path: `/api/cms/ranking-list/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name Delete1
   * @summary Deletes the ranking list (and related image) with the provided id
   * @request DELETE:/api/cms/ranking-list/{id}
   * @secure
   */
  deleteRankingList = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/cms/ranking-list/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Ranking Lists
   * @name SetRankingListImage
   * @summary Sets the provided image as the image for the ranking list with the provided id, will remove image if none is provided
   * @request PUT:/api/cms/ranking-list/{id}/image
   * @secure
   */
  setRankingListImage = (
    id: string,
    data: { image: File },
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/ranking-list/${id}/image`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ranking Lists
   * @name GetRankingListLeaderBoard
   * @summary Gets the leaderboard of the ranking list with the provided id
   * @request GET:/api/ranking-list/{id}/leaderboard
   * @secure
   */
  getRankingListLeaderBoard = (id: string, params: RequestParams = {}) =>
    this.request<RankingListLeaderBoard, any>({
      path: `/api/ranking-list/${id}/leaderboard`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Inbox
   * @name MarkAllAsRead
   * @request PUT:/api/inbox/read/all
   * @secure
   */
  markAllAsRead = (params: RequestParams = {}) =>
    this.request<Unit, any>({
      path: `/api/inbox/read/all`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Inbox
   * @name MarkAsDismissed
   * @request PUT:/api/inbox/dismiss
   * @secure
   */
  markAsDismissed = (query: { id: string }, params: RequestParams = {}) =>
    this.request<Unit, any>({
      path: `/api/inbox/dismiss`,
      method: "PUT",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Logs
   *
   * @tags logging-resource
   * @name LogUsingGet
   * @summary log
   * @request GET:/api/logging
   */
  logUsingGet = (params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/logging`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags open-resource
   * @name VersionUsingGet
   * @summary version
   * @request GET:/api/open/version
   */
  versionUsingGet = (params: RequestParams = {}) =>
    this.request<string, void>({
      path: `/api/open/version`,
      method: "GET",
      ...params,
    });
  /**
   * @description Creates a new or returns an existing Google Pay Pass. The returned payload contains a url to save the pass if needed.
   *
   * @tags pass-resource
   * @name CreateGooglePassUsingGet
   * @summary createGooglePass
   * @request GET:/api/user/pass/gp
   */
  createGooglePassUsingGet = (params: RequestParams = {}) =>
    this.request<GooglePass, void>({
      path: `/api/user/pass/gp`,
      method: "GET",
      ...params,
    });
  /**
   * @description Creates a new or returns an existing .pkpass.
   *
   * @tags pass-resource
   * @name CreatePkPassUsingPost
   * @summary createPKPass
   * @request POST:/api/user/pass/pk
   */
  createPkPassUsingPost = (params: RequestParams = {}) =>
    this.request<string, void>({
      path: `/api/user/pass/pk`,
      method: "POST",
      ...params,
    });
  /**
   * @description Returns information if requesting a (new) pass is required.
   *
   * @tags pass-resource
   * @name PassRequiredUsingGet
   * @summary passRequired
   * @request GET:/api/user/pass/required
   */
  passRequiredUsingGet = (params: RequestParams = {}) =>
    this.request<PassRequiredResponse, void>({
      path: `/api/user/pass/required`,
      method: "GET",
      ...params,
    });
  /**
   * @description Returns the current state of the passes for the user.
   *
   * @tags pass-resource
   * @name GetPassStateUsingGet
   * @summary getPassState
   * @request GET:/api/user/pass/state
   */
  getPassStateUsingGet = (params: RequestParams = {}) =>
    this.request<PassStateResponse, void>({
      path: `/api/user/pass/state`,
      method: "GET",
      ...params,
    });
  /**
   * @description SMS-Timing endpoint to anonymize a user
   *
   * @tags sms-timing-resource
   * @name AnonymizeUsingGet
   * @summary anonymize
   * @request GET:/api/sms-timing/anonymize
   */
  anonymizeUsingGet = (
    query?: { userId?: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/anonymize`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description SMS-Timing endpoint to sync a new signup email
   *
   * @tags sms-timing-resource
   * @name NewSignupUsingGet
   * @summary newSignup
   * @request GET:/api/sms-timing/new-signup
   */
  newSignupUsingGet = (
    query: { email: string; id: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/new-signup`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description SMS-Timing endpoint to sync a new signup
   *
   * @tags sms-timing-resource
   * @name NewSignupUsingPost
   * @summary newSignup
   * @request POST:/api/sms-timing/new-signup
   */
  newSignupUsingPost = (data: SmsTimingUser, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/new-signup`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description SMS-Timing endpoint to sync a finished or edited race
   *
   * @tags sms-timing-resource
   * @name RaceFinishedUsingPost
   * @summary raceFinished
   * @request POST:/api/sms-timing/race-finished
   */
  raceFinishedUsingPost = (data: Race, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/race-finished`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description SMS-Timing endpoint to sync users
   *
   * @tags sms-timing-resource
   * @name UserSyncUsingPost
   * @summary userSync
   * @request POST:/api/sms-timing/user-sync
   */
  userSyncUsingPost = (data: SmsTimingUser[], params: RequestParams = {}) =>
    this.request<UserSyncResponse, void>({
      path: `/api/sms-timing/user-sync`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name AchievementPicUsingGet
   * @summary achievementPic
   * @request GET:/api/sms-timing/mobile/achievement-pic
   */
  achievementPicUsingGet = (
    query: { width?: number; height?: number; id: string },
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/sms-timing/mobile/achievement-pic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name AchievementsUsingGet
   * @summary achievements
   * @request GET:/api/sms-timing/mobile/achievements
   */
  achievementsUsingGet = (params: RequestParams = {}) =>
    this.request<Achievement[], void>({
      path: `/api/sms-timing/mobile/achievements`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name AchievementsAllUsingGet
   * @summary achievementsAll
   * @request GET:/api/sms-timing/mobile/achievements/all
   */
  achievementsAllUsingGet = (params: RequestParams = {}) =>
    this.request<Achievement[], void>({
      path: `/api/sms-timing/mobile/achievements/all`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name UpdateAdditionalDataUsingPut
   * @summary updateAdditionalData
   * @request PUT:/api/sms-timing/mobile/additional-data
   */
  updateAdditionalDataUsingPut = (
    data: AdditionalData,
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/additional-data`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name CareerLevelsUsingGet
   * @summary careerLevels
   * @request GET:/api/sms-timing/mobile/career-levels
   */
  careerLevelsUsingGet = (
    query: { careerId: number },
    params: RequestParams = {}
  ) =>
    this.request<CareerLevel[], void>({
      path: `/api/sms-timing/mobile/career-levels`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name CareerPicUsingGet
   * @summary careerPic
   * @request GET:/api/sms-timing/mobile/career-pic
   */
  careerPicUsingGet = (
    query: { width?: number; height?: number; id: string; active?: boolean },
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/sms-timing/mobile/career-pic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name CareersUsingGet
   * @summary careers
   * @request GET:/api/sms-timing/mobile/careers
   */
  careersUsingGet = (params: RequestParams = {}) =>
    this.request<CareerState[], void>({
      path: `/api/sms-timing/mobile/careers`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name ContinueToAppUsingGet
   * @summary continueToApp
   * @request GET:/api/sms-timing/mobile/continue
   */
  continueToAppUsingGet = (
    query: { email: string },
    params: RequestParams = {}
  ) =>
    this.request<HasPersonResponse, void>({
      path: `/api/sms-timing/mobile/continue`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name ContinueToAppWithCodeUsingGet
   * @summary continueToAppWithCode
   * @request GET:/api/sms-timing/mobile/continue/code
   */
  continueToAppWithCodeUsingGet = (
    query: { code: string; redirect?: string },
    params: RequestParams = {}
  ) =>
    this.request<LoginCodeResponse, void>({
      path: `/api/sms-timing/mobile/continue/code`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name AddFriendUsingPut
   * @summary addFriend
   * @request PUT:/api/sms-timing/mobile/friend
   */
  addFriendUsingPut = (query: { data: string }, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/friend`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RemoveFriendUsingDelete
   * @summary removeFriend
   * @request DELETE:/api/sms-timing/mobile/friend
   */
  removeFriendUsingDelete = (
    query: { data: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/friend`,
      method: "DELETE",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name FriendsUsingGet
   * @summary friends
   * @request GET:/api/sms-timing/mobile/friends
   */
  friendsUsingGet = (params: RequestParams = {}) =>
    this.request<Profile[], void>({
      path: `/api/sms-timing/mobile/friends`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name UpdateLocaleUsingPut
   * @summary updateLocale
   * @request PUT:/api/sms-timing/mobile/locale
   */
  updateLocaleUsingPut = (
    query: { locale: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/locale`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name LoginEmailUsingGet
   * @summary loginEmail
   * @request GET:/api/sms-timing/mobile/login
   */
  loginEmailUsingGet = (
    query: { email: string; locale?: string; platform?: string },
    params: RequestParams = {}
  ) =>
    this.request<LoginResponse, void>({
      path: `/api/sms-timing/mobile/login`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name LoginEmailCodeUsingGet
   * @summary loginEmailCode
   * @request GET:/api/sms-timing/mobile/login/code
   */
  loginEmailCodeUsingGet = (
    query: { email: string; locale?: string; platform?: string },
    params: RequestParams = {}
  ) =>
    this.request<LoginResponse, void>({
      path: `/api/sms-timing/mobile/login/code`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name OpeningTimesUsingGet
   * @summary openingTimes
   * @request GET:/api/sms-timing/mobile/opening-times
   */
  openingTimesUsingGet = (params: RequestParams = {}) =>
    this.request<OpeningTimes, void>({
      path: `/api/sms-timing/mobile/opening-times`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name OrdersUsingGet
   * @summary orders
   * @request GET:/api/sms-timing/mobile/orders
   */
  ordersUsingGet = (params: RequestParams = {}) =>
    this.request<Order[], void>({
      path: `/api/sms-timing/mobile/orders`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name ProfileUsingGet
   * @summary profile
   * @request GET:/api/sms-timing/mobile/profile
   */
  profileUsingGet = (
    query?: { raceLicenseQr?: string },
    params: RequestParams = {}
  ) =>
    this.request<Profile, void>({
      path: `/api/sms-timing/mobile/profile`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name UpdateProfileUsingPut
   * @summary updateProfile
   * @request PUT:/api/sms-timing/mobile/profile
   */
  updateProfileUsingPut = (data: ProfileUpdate, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/profile`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name ProfilePicUsingGet
   * @summary profilePic
   * @request GET:/api/sms-timing/mobile/profile-pic
   */
  profilePicUsingGet = (
    query?: { width?: number; height?: number; personId?: string },
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/sms-timing/mobile/profile-pic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name UpdateProfilePicUsingPut
   * @summary updateProfilePic
   * @request PUT:/api/sms-timing/mobile/profile-pic
   */
  updateProfilePicUsingPut = (
    data: { image: File },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/profile-pic`,
      method: "PUT",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name PushTokenUsingPut
   * @summary pushToken
   * @request PUT:/api/sms-timing/mobile/push-token
   */
  pushTokenUsingPut = (query: { token: string }, params: RequestParams = {}) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/push-token`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RaceSessionDetailsUsingGet
   * @summary raceSessionDetails
   * @request GET:/api/sms-timing/mobile/race-session/details
   */
  raceSessionDetailsUsingGet = (
    query: { sessionId: number },
    params: RequestParams = {}
  ) =>
    this.request<RaceSessionDetails[], void>({
      path: `/api/sms-timing/mobile/race-session/details`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RaceSessionLapDetailsUsingGet
   * @summary raceSessionLapDetails
   * @request GET:/api/sms-timing/mobile/race-session/laps
   */
  raceSessionLapDetailsUsingGet = (
    query: { sessionId: number },
    params: RequestParams = {}
  ) =>
    this.request<RaceSessionLapDetails[], void>({
      path: `/api/sms-timing/mobile/race-session/laps`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RaceSessionLapsAndDetailsUsingGet
   * @summary raceSessionLapsAndDetails
   * @request GET:/api/sms-timing/mobile/race-session/laps-and-details
   */
  raceSessionLapsAndDetailsUsingGet = (
    query: { sessionId: number },
    params: RequestParams = {}
  ) =>
    this.request<RaceSessionData, void>({
      path: `/api/sms-timing/mobile/race-session/laps-and-details`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RaceSessionsUsingGet
   * @summary raceSessions
   * @request GET:/api/sms-timing/mobile/race-sessions
   */
  raceSessionsUsingGet = (params: RequestParams = {}) =>
    this.request<RaceSession[], void>({
      path: `/api/sms-timing/mobile/race-sessions`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RankingsUsingGet
   * @summary rankings
   * @request GET:/api/sms-timing/mobile/rankings
   */
  rankingsUsingGet = (
    query: { careerId: number },
    params: RequestParams = {}
  ) =>
    this.request<CareerRankingRecord[], void>({
      path: `/api/sms-timing/mobile/rankings`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name RecordsUsingGet
   * @summary records
   * @request GET:/api/sms-timing/mobile/records
   */
  recordsUsingGet = (
    query: {
      scoreGroupId?: number;
      sorting: number;
      startDate?: string;
      endDate?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<RotaxRecord[], void>({
      path: `/api/sms-timing/mobile/records`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-mobile
   * @name SignupUsingPost
   * @summary signup
   * @request POST:/api/sms-timing/mobile/signup
   */
  signupUsingPost = (
    data: SignupPayload,
    query?: { locale?: string; platform?: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/sms-timing/mobile/signup`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags sms-timing-resource-service
   * @name ProfilePicUsingGet1
   * @summary profilePic
   * @request GET:/api/sms-timing/service/profile-pic
   */
  profilePicUsingGet1 = (
    query: { personId: string; width?: number; height?: number },
    params: RequestParams = {}
  ) =>
    this.request<string, void>({
      path: `/api/sms-timing/service/profile-pic`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description Returns 200 if the user exists and has given consent, 404 if not.
   *
   * @tags user-resource
   * @name HasConsentUsingGet
   * @summary hasConsent
   * @request GET:/api/user/consent
   */
  hasConsentUsingGet = (
    query?: {
      email?: string;
      appleUserId?: string;
      googleUserId?: string;
      facebookUserId?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/consent`,
      method: "GET",
      query: query,
      ...params,
    });
      /**
   * @description This overwrites any existing consent with the provided consent and updates email lists accordingly
   *
   * @tags CMS User
   * @name SetUserConsentById
   * @summary Sets the consent for the user with the provided ID.
   * @request PATCH:/api/cms/user/{id}/consent
   * @secure
   */
  setUserConsentById = (id: string, data: ("RMD" | "Marketing")[], params: RequestParams = {}) =>
  this.request<User, any>({
    path: `/api/cms/user/${id}/consent`,
    method: "PATCH",
    body: data,
    secure: true,
    type: ContentType.Json,
    ...params,
  });
  /**
   * @description Returns 200 if the user exists, 404 if not.
   *
   * @tags user-resource
   * @name ExistsUsingGet
   * @summary exists
   * @request GET:/api/user/exists
   */
  existsUsingGet = (
    query?: {
      email?: string;
      appleUserId?: string;
      googleUserId?: string;
      facebookUserId?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/exists`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description Sets a flag stating if the user already has the full app or not. Once set to true, it can never be set to false again.
   *
   * @tags user-resource
   * @name SetFullAppUsingPut
   * @summary setFullApp
   * @request PUT:/api/user/full-app
   */
  setFullAppUsingPut = (
    query: { fullApp: boolean },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/full-app`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-resource
   * @name SetPushTokenUsingPut
   * @summary setPushToken
   * @request PUT:/api/user/push-token
   */
  setPushTokenUsingPut = (
    query: { pushToken: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/user/push-token`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags web-experience-resource
   * @name RedeemCodeUsingPut
   * @summary redeemCode
   * @request PUT:/api/web-exp/redeem
   */
  redeemCodeUsingPut = (
    query?: { code?: string; raceLicenseId?: string },
    params: RequestParams = {}
  ) =>
    this.request<Unit, void>({
      path: `/api/web-exp/redeem`,
      method: "PUT",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags web-experience-resource
   * @name SubmitWebExpEmailUsingPost
   * @summary submitWebExpEmail
   * @request POST:/api/web-exp/submit
   */
  submitWebExpEmailUsingPost = (
    data: WebExpResultReq,
    params: RequestParams = {}
  ) =>
    this.request<WebExpResultRes, void>({
      path: `/api/web-exp/submit`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetMarketingEmailPreview
   * @summary Gets the preview for sending a marketing email (does not actually send the email)
   * @request POST:/api/cms/marketing/send/preview
   * @secure
   */
  getMarketingEmailPreview = (
    data: MarketingEmailCreate,
    params: RequestParams = {}
  ) =>
    this.request<EmailMarketingPreview, any>({
      path: `/api/cms/marketing/send/preview`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name InitiateSendEmail
   * @summary Initiates the sending of a marketing email based on the provided data. This means creating the required contact list for the campaign and importing all contacts based on the email conditions.
   * @request POST:/api/cms/marketing/send/initiate
   * @secure
   */
  initiateSendEmail = (
    data: MarketingEmailCreate,
    params: RequestParams = {}
  ) =>
    this.request<SendMarketingEmailResponse, any>({
      path: `/api/cms/marketing/send/initiate`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name CreateContactList
   * @summary Creates a new contact list based on the provided data. Does not invoke callback after import is complete.
   * @request POST:/api/cms/marketing/create/list
   * @secure
   */
  createContactList = (
    data: MarketingEmailCreate,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/cms/marketing/create/list`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetEmailTemplates
   * @summary Gets all available email templates
   * @request GET:/api/cms/marketing/templates
   * @secure
   */
  getEmailTemplates = (params: RequestParams = {}) =>
    this.request<EmailTemplate[], any>({
      path: `/api/cms/marketing/templates`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetTemplateThumbnail
   * @summary Gets thumbnail of the template with the provided id by converting it's HTML to an image
   * @request GET:/api/cms/marketing/templates/{id}/thumbnail
   * @secure
   */
  getTemplateThumbnail = (
    id: number,
    query?: { size?: number },
    params: RequestParams = {}
  ) =>
    this.request<string[], any>({
      path: `/api/cms/marketing/templates/${id}/thumbnail`,
      method: "GET",
      query: query,
      secure: true,
      format: "blob",
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetEmails
   * @summary Gets all marketing emails
   * @request GET:/api/cms/marketing/emails
   * @secure
   */
  getEmails = (
    query?: {
      page?: number;
      size?: number;
      sort?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<MarketingEmail[], any>({
      path: `/api/cms/marketing/emails`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetEmail
   * @summary Gets the marketing email with the provided id
   * @request GET:/api/cms/marketing/emails/{id}
   * @secure
   */
  getEmail = (id: string, params: RequestParams = {}) =>
    this.request<MarketingEmail, any>({
      path: `/api/cms/marketing/emails/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name Delete2
   * @summary Deletes the marketing email with the provided id. Does not delete associated campaigns and lists.
   * @request DELETE:/api/cms/marketing/emails/{id}
   * @secure
   */
  deleteEmail = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/cms/marketing/emails/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name Send
   * @summary Sends the marketing email with the provided id by creating the associated campaign. Attention: A previously created contact list is required for sending.
   * @request PUT:/api/cms/marketing/send/{id}
   * @secure
   */
  sendEmail = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/cms/marketing/send/${id}`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name SendTestEmail
   * @summary Sends a test email.
   * @request POST:/api/cms/marketing/send/test
   * @secure
   */
  sendTestEmail = (data: TestEmailBody, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/cms/marketing/send/test`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Email Marketing
   * @name GetCampaignForEmail
   * @summary Gets the marketing email campaign for the email with the provided id
   * @request GET:/api/cms/marketing/emails/{id}/campaign
   * @secure
   */
  getCampaignForEmail = (id: string, params: RequestParams = {}) =>
    this.request<EmailCampaign, any>({
      path: `/api/cms/marketing/emails/${id}/campaign`,
      method: "GET",
      secure: true,
      ...params,
    });

  /**
   * No description
   *
   * @tags CMS Email Marketing Automation
   * @name GetAutomatedMarketingEmailTemplates
   * @summary Gets all available automated marketing email templates
   * @request GET:/api/cms/marketing/automation/templates
   * @secure
   */
  getAutomatedMarketingEmailTemplates = (params: RequestParams = {}) =>
    this.request<AutomatedMarketingEmailTemplate[], any>({
      path: `/api/cms/marketing/automation/templates`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CMS Email Marketing Automation
   * @name Update
   * @summary Updates the provided automated email template
   * @request PUT:/api/cms/marketing/automation
   * @secure
   */
  updateAutomatedMarketingEmail = (
    data: AutomatedMarketingEmailTemplateUpdate,
    params: RequestParams = {}
  ) =>
    this.request<AutomatedMarketingEmailTemplate, any>({
      path: `/api/cms/marketing/automation`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
