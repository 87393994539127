import { useState } from "react";
import HeaderTabBar from "../Components/UI/HeaderTabBar";
import EmailCampaigns from "./EmailCampaigns";
import EmailTemplates from "./EmailTemplates";

const campaignsIndex = 0;
const automatedEmailsIndex = 1;

export default function EmailMarketing() {
  const [currentTab, setCurrentTab] = useState(campaignsIndex);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Email Marketing
          </h2>
        </div>
      </div>

      <div className="hidden sm:block">
        <HeaderTabBar
          tabs={[{ name: "Email Campaigns" }, { name: "Automated Emails" }]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        {currentTab == campaignsIndex && <EmailCampaigns />}
        {currentTab == automatedEmailsIndex && <EmailTemplates />}
      </div>
    </div>
  );
}
