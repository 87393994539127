import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../API/AuthContext";
import { RankingList } from "../../API/data-contracts";
import {
  classNames,
  getFormattedDateString,
  rankingListLink,
} from "../../util";

type Props = {
  rankingList: RankingList;
  showRankingList: (rankingList: RankingList) => void;
};

export default function RankingListEntry({
  rankingList,
  showRankingList,
}: Props) {
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  const showUser = () => {
    navigate("/customer/" + rankingList.ownerUserId, {
      state: {
        passedUserId: rankingList.ownerUserId,
      },
    });
  };

  const copyToClipboard = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(rankingListLink(rankingList.code));
    setTimeout(function () {
      setLinkCopied(false);
    }, 3000);
  };

  return (
    <tr className="cursor-pointer text-left">
      <td
        className="whitespace-nowrap px-6 py-4"
        onClick={() => showRankingList(rankingList)}
      >
        <div className="text-sm text-gray-500">{rankingList.title}</div>
      </td>
      <td
        className="whitespace-nowrap px-8 py-4"
        onClick={() => showRankingList(rankingList)}
      >
        <div className="text-sm text-gray-500">
          {/* filter out admin-user, which is returned but doesn't have an id */}
          {
            rankingList.participants.filter((id) => id !== loggedInUser?.id)
              .length
          }
        </div>
      </td>
      <td
        className="whitespace-nowrap px-8 py-4 text-center"
        onClick={() => showRankingList(rankingList)}
      >
        <div className="text-sm text-gray-500">
          {getFormattedDateString(rankingList.createdDate)}
        </div>
      </td>
      <td
        className="whitespace-nowrap px-8 py-4 text-center"
        onClick={() => {
          if (rankingList.ownerUserId) showUser();
        }}
      >
        <div className="text-sm text-gray-500">
          <span
            className={classNames(
              rankingList.adminCreated
                ? "bg-blue-100 px-2 text-blue-800"
                : "bg-green-100 px-3 text-green-600",
              "inline-flex rounded-full text-xs font-semibold leading-5"
            )}
          >
            {rankingList.adminCreated ? "Admin" : "User"}
          </span>
        </div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-8 py-4"
        onClick={() => copyToClipboard()}
      >
        <span className="flex justify-center">
          {linkCopied && <div className="text-sm text-gray-500">Copied!</div>}
          {!linkCopied && (
            <div className="text-sm text-gray-500">{rankingList.code}</div>
          )}
          <button
            type="button"
            className="ml-2 inline-flex items-center rounded-full text-gray-400 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
          </button>
        </span>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-8 py-4 text-right"
        onClick={() => showRankingList(rankingList)}
      >
        <div className="text-sm text-gray-500 underline">
          Go to ranking list
        </div>
      </td>
    </tr>
  );
}
