import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import {
  InboxMessagePayload,
  InboxMessageTemplateRes,
  PageInboxMessagePayload,
} from "../API/data-contracts";
import InboxMessageListEntry from "../Components/AppMessages/InboxMessageListEntry";
import InboxTemplateListEntry from "../Components/AppMessages/InboxTemplateListEntry";
import Pagination from "../Components/Pagination";
import SelectionTabBar from "../Components/UI/SelectionTabBar";
import { useErrorHandler } from "../Error/ErrorContext";

const templatesIndex = 0;
const sentMessagesIndex = 1;

export default function InboxTemplates() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const { showError } = useErrorHandler();
  const [currentTab, setCurrentTab] = useState(templatesIndex);
  const [inboxMessageTemplates, setInboxMessageTemplates] =
    useState<InboxMessageTemplateRes[]>();
  const [inboxMessages, setInboxMessages] = useState<InboxMessagePayload[]>();
  const [inboxMessagePages, setInboxMessagePages] = useState<
    Map<number, PageInboxMessagePayload>
  >(new Map());
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  const loadInboxMessageTemplates = async () => {
    try {
      const response = await api.getInboxTemplatesUsingGet();
      setInboxMessageTemplates(response.data);
    } catch (error) {
      showError(error);
    }
  };

  const loadInboxMessages = async () => {
    // when page wasn't loaded before, load the new page
    if (!inboxMessagePages.get(currentPage)) {
      try {
        const response = await api.getTemplateInboxPayloads({
          pageable: {
            pageNumber: currentPage,
            sort: {
              sorted: true //TODO: this doesn't seem to work
            }
          },
        });
        const inboxMessagePage = response.data;
        if (inboxMessagePage) {
          setPageCount(inboxMessagePage.totalPages ?? 0);
          setPageSize(inboxMessagePage.size ?? 0);
          setMessageCount(inboxMessagePage.totalElements ?? 0);
          setInboxMessagePages(
            new Map(inboxMessagePages.set(currentPage, inboxMessagePage))
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of inbox messages
    const newInboxMessages = inboxMessagePages.get(currentPage)?.content;
    setInboxMessages(newInboxMessages);
  };

  const editTemplate = (template: InboxMessageTemplateRes) => {
    navigate("/template", {
      state: { template: template },
    });
  };

  const toggleEnabled = async (template: InboxMessageTemplateRes) => {
    try {
      await api.updateInboxTemplate(template);
    } catch (error) {
      loadInboxMessageTemplates();
      showError(error);
    }
  };

  useEffect(() => {
    loadInboxMessageTemplates();
    loadInboxMessages();
  }, [currentPage]);

  return (
    <div>
      <div className="min-w-0 flex-1">
        <SelectionTabBar
          tabs={[
            { name: "Templates", value: inboxMessageTemplates?.length ?? 0 },
            { name: "Sent Messages", value: messageCount },
          ]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>
      {currentTab == templatesIndex && (
        <div className="mt-4 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Title
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Enabled
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inboxMessageTemplates?.map((template, i) => {
                      return (
                        <InboxTemplateListEntry
                          key={i}
                          template={template}
                          even={i % 2 == 0}
                          editTemplate={editTemplate}
                          toggleEnabled={toggleEnabled}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentTab == sentMessagesIndex && (
        <div className="mt-4 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full table-fixed divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Body
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Recipients
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Opened
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Clicks
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Sent
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inboxMessages?.map((inboxMessage) => {
                      return (
                        <InboxMessageListEntry
                          key={inboxMessage.id}
                          payload={inboxMessage}
                          editPayload={() => {}}
                          toggleHide={() => {}}
                          allowEdit={false}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  pageCount={pageCount}
                  totalElements={messageCount}
                  pageSize={pageSize}
                  maxDisplayPages={8}
                  onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
