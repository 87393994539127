import React from "react";
import { useAuth } from "../../API/AuthContext";
import { UploadedImage } from "../../API/data-contracts";
import { useInboxMessage } from "../../context/InboxMessageContext";
import { inboxMessageUrl } from "../../util";

type Props = {
  image: UploadedImage;
  index: number;
  removeImage: (index: number) => void;
};

export default function CarouselImage({ image, index, removeImage }: Props) {
  const { accessToken } = useAuth();
  const inboxMessage = useInboxMessage();

  const imageSrc = image.src ?? inboxMessageUrl(image.id, accessToken());
  // update src of gallery image if it doesn't have one yet
  if (inboxMessage.galleryImages[index]?.src === undefined) {
    const newImage = inboxMessage.galleryImages.map((obj) => {
      // if id equals image id
      if (obj.id === image.id) {
        return { id: image.id, src: imageSrc };
      }

      // otherwise return object as is
      return obj;
    });
    inboxMessage.setGalleryImages(newImage);
  }

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => removeImage(index)}
        className="absolute -top-2 -right-2 inline-flex items-center justify-center rounded-md bg-red p-1 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <span className="sr-only">Close menu</span>
        {/* Heroicon name: outline/x */}
        <svg
          className="h-3 w-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <img
        className="object-contain"
        id="image-overlayed"
        src={imageSrc}
        onError={() => {}}
      />
    </div>
  );
}
