import { XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { PageRankingList, RankingList } from "../API/data-contracts";
import Pagination from "../Components/Pagination";
import RankingListEntry from "../Components/UI/RankingListEntry";
import SelectionTabBar from "../Components/UI/SelectionTabBar";
import { classNames, showError } from "../util";

const allListsIndex = 0;
const userListsIndex = 1;
const adminListsIndex = 2;

export default function BusinessRankingLists() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(allListsIndex);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [newListName, setNewListName] = useState<string | undefined>(undefined);
  const [validListName, setValidListName] = useState(false);
  const [isCreatingList, setCreatingList] = useState(false);
  // all ranking lists
  const [allRankingListPages, setAllRankingListPages] = useState<
    Map<number, PageRankingList>
  >(new Map());
  const [allRankingLists, setAllRankingLists] = useState<RankingList[]>();
  const [currentPageAllLists, setCurrentPageAllLists] = useState(0);
  const [pageCountAllLists, setPageCountAllLists] = useState(0);
  const [pageSizeAllLists, setPageSizeAllLists] = useState(0);
  const [totalElementsAllLists, setTotalElementsAllLists] = useState(0);
  // user ranking lists
  const [userRankingListPages, setUserRankingListPages] = useState<
    Map<number, PageRankingList>
  >(new Map());
  const [userRankingLists, setUserRankingLists] = useState<RankingList[]>();
  const [currentPageUserLists, setCurrentPageUserLists] = useState(0);
  const [pageCountUserLists, setPageCountUserLists] = useState(0);
  const [pageSizeUserLists, setPageSizeUserLists] = useState(0);
  const [totalElementsUserLists, setTotalElementsUserLists] = useState(0);
  // admin ranking lists
  const [adminRankingListPages, setAdminRankingListPages] = useState<
    Map<number, PageRankingList>
  >(new Map());
  const [adminRankingLists, setAdminRankingLists] = useState<RankingList[]>();
  const [currentPageAdminLists, setCurrentPageAdminLists] = useState(0);
  const [pageCountAdminLists, setPageCountAdminLists] = useState(0);
  const [pageSizeAdminLists, setPageSizeAdminLists] = useState(0);
  const [totalElementsAdminLists, setTotalElementsAdminLists] = useState(0);

  const loadAllRankingLists = async () => {
    // when page wasn't loaded before, load the new page
    if (!allRankingListPages.get(currentPageAllLists)) {
      try {
        const response = await api.getRankingLists({
          page: currentPageAllLists,
          sort: ["createdDate,desc"]
        });
        const rankingListPage = response.data;
        if (rankingListPage) {
          setPageCountAllLists(rankingListPage.totalPages ?? 0);
          setPageSizeAllLists(rankingListPage.size ?? 0);
          setTotalElementsAllLists(rankingListPage.totalElements ?? 0);
          setAllRankingListPages(
            new Map(
              allRankingListPages.set(currentPageAllLists, rankingListPage)
            )
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of inbox messages
    const newRankingLists =
      allRankingListPages.get(currentPageAllLists)?.content;
    setAllRankingLists(newRankingLists);
  };

  const loadUserRankingLists = async () => {
    // when page wasn't loaded before, load the new page
    if (!userRankingListPages.get(currentPageUserLists)) {
      try {
        const response = await api.getRankingListsUser({
          page: currentPageUserLists,
          sort: ["createdDate,desc"],
        });
        const rankingListPage = response.data;
        if (rankingListPage) {
          setPageCountUserLists(rankingListPage.totalPages ?? 0);
          setPageSizeUserLists(rankingListPage.size ?? 0);
          setTotalElementsUserLists(rankingListPage.totalElements ?? 0);
          setUserRankingListPages(
            new Map(
              userRankingListPages.set(currentPageUserLists, rankingListPage)
            )
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of inbox messages
    const newRankingLists =
      userRankingListPages.get(currentPageUserLists)?.content;
    setUserRankingLists(newRankingLists);
  };

  const loadAdminRankingLists = async () => {
    // when page wasn't loaded before, load the new page
    if (!adminRankingListPages.get(currentPageAdminLists)) {
      try {
        const response = await api.getRankingListsAdmin({
          page: currentPageAdminLists,
          sort: ["createdDate,desc"],
        });
        const rankingListPage = response.data;
        if (rankingListPage) {
          setPageCountAdminLists(rankingListPage.totalPages ?? 0);
          setPageSizeAdminLists(rankingListPage.size ?? 0);
          setTotalElementsAdminLists(rankingListPage.totalElements ?? 0);
          setAdminRankingListPages(
            new Map(
              adminRankingListPages.set(currentPageAdminLists, rankingListPage)
            )
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of inbox messages
    const newRankingLists = adminRankingListPages.get(
      currentPageAdminLists
    )?.content;
    setAdminRankingLists(newRankingLists);
  };

  const reloadRankingLists = () => {
    allRankingListPages.clear();
    loadAllRankingLists();
    userRankingListPages.clear();
    loadUserRankingLists();
    adminRankingListPages.clear();
    loadAdminRankingLists();
  };

  const createRankingList = async () => {
    if (newListName) {
      try {
        setCreatingList(true);
        await api.createRankingList({
          title: newListName,
          private: true,
        });
        setCreatingList(false);
        setShowCreateDialog(false);
        setNewListName(undefined);
        setValidListName(false);

        // reload data
        reloadRankingLists();
      } catch (error) {
        showError(error);
        setCreatingList(false);
        setShowCreateDialog(false);
        setNewListName(undefined);
        setValidListName(false);
      }
    }
  };

  const showRankingList = (rankingList: RankingList) => {
    navigate(`/ranking-list/${rankingList.id}`);
  };

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    setValidListName(newName.length >= 3);
    setNewListName(newName);
  };

  useEffect(() => {
    loadAllRankingLists();
  }, [currentPageAllLists]);

  useEffect(() => {
    loadUserRankingLists();
  }, [currentPageUserLists]);

  useEffect(() => {
    loadAdminRankingLists();
  }, [currentPageAdminLists]);

  const tabs = [
    { name: "All Lists", value: totalElementsAllLists ?? 0 },
    { name: "User Lists", value: totalElementsUserLists ?? 0 },
    { name: "Admin Lists", value: totalElementsAdminLists ?? 0 },
  ];

  return (
    <div>
      <div className="mt-4 text-left md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <div className="min-w-0 flex-1">
            <SelectionTabBar
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </div>
        </div>

        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => setShowCreateDialog(true)}
          >
            Create ranking list
          </button>
        </div>
      </div>

      {/* Ranking lists */}
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Joined
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Created at
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Created by
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Link
                    </th>
                    <th
                      scope="col"
                      className="px-8 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Ranking list
                    </th>
                  </tr>
                </thead>

                {currentTab === allListsIndex && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {allRankingLists?.map((rankingListEntry, i) => {
                      return (
                        <RankingListEntry
                          rankingList={rankingListEntry}
                          showRankingList={showRankingList}
                        />
                      );
                    })}
                  </tbody>
                )}
                {currentTab === userListsIndex && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {userRankingLists?.map((rankingListEntry, i) => {
                      return (
                        <RankingListEntry
                          rankingList={rankingListEntry}
                          showRankingList={showRankingList}
                        />
                      );
                    })}
                  </tbody>
                )}
                {currentTab === adminListsIndex && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {adminRankingLists?.map((rankingListEntry, i) => {
                      return (
                        <RankingListEntry
                          rankingList={rankingListEntry}
                          showRankingList={showRankingList}
                        />
                      );
                    })}
                  </tbody>
                )}
              </table>
              {currentTab === allListsIndex && (
                <Pagination
                  pageCount={pageCountAllLists}
                  totalElements={totalElementsAllLists}
                  pageSize={pageSizeAllLists}
                  maxDisplayPages={8}
                  onPageSelected={(pageIndex) =>
                    setCurrentPageAllLists(pageIndex)
                  }
                />
              )}
              {currentTab === userListsIndex && (
                <Pagination
                  pageCount={pageCountUserLists}
                  totalElements={totalElementsUserLists}
                  pageSize={pageSizeUserLists}
                  maxDisplayPages={8}
                  onPageSelected={(pageIndex) =>
                    setCurrentPageUserLists(pageIndex)
                  }
                />
              )}
              {currentTab === adminListsIndex && (
                <Pagination
                  pageCount={pageCountAdminLists}
                  totalElements={totalElementsAdminLists}
                  pageSize={pageSizeAdminLists}
                  maxDisplayPages={8}
                  onPageSelected={(pageIndex) =>
                    setCurrentPageAdminLists(pageIndex)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Create ranking list dialog */}
      {showCreateDialog && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white p-8 pt-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="flex justify-end">
                <XIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setShowCreateDialog(false)}
                />
              </div>
              <div className="sm:flex sm:items-start">
                <div className="text-centersm:text-left w-full">
                  <h3
                    className="text-lg font-medium text-gray-900"
                    id="modal-title"
                  >
                    New Ranking List
                  </h3>
                  <label
                    htmlFor="title"
                    className="mt-4 block text-xs font-bold text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={newListName}
                    autoComplete="off"
                    autoFocus={true}
                    placeholder="Enter the name of the ranking list"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    onChange={handleNameChange}
                  />
                </div>
              </div>
              <div className="mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={createRankingList}
                  disabled={!validListName || isCreatingList}
                  className={classNames(
                    validListName
                      ? "bg-red text-white hover:bg-yellow hover:text-rotaxDark"
                      : "bg-gray-200 opacity-60",
                    "ml-3 inline-flex items-center rounded-md border border-transparent px-8 py-2 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  )}
                >
                  {isCreatingList && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Create new list
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
