import React from "react";
import { classNames } from "../util";

type Props = {
  className?: string;
};

export const LoadingIndicator: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames("flex items-center justify-center", className)}>
      <div className="relative inline-block h-8 w-8 animate-spin rounded-full border-4">
        <div
          className={classNames(
            "absolute left-4 h-2 w-2 rotate-4 bg-black rounded-full"
          )}
        ></div>
      </div>
    </div>
  );
};
