import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { classNames } from "../../util";

const criteriaList = [
  {
    id: 0,
    name: "Select a winning criteria",
    metric: undefined,
  },
  {
    id: 1,
    name: "🏆 Victory Points (Most points divided by best lap)",
    metric: "VictoryPoints",
  },
  {
    id: 2,
    name: "🏁 Best Lap",
    metric: "BestLapTime",
  },
  {
    id: 3,
    name: "👋 Most Visits",
    metric: "MostVisits",
  },
  {
    id: 4,
    name: "🔴 Most Points",
    metric: "MostPoints",
  },
];

type Props = {
  selectedMetric: string | undefined;
  criteriaSelected(metric: string | undefined): void;
};

export default function CriteriaDropdown({
  selectedMetric,
  criteriaSelected,
}: Props) {
  const [selected, setSelected] = useState(
    criteriaList.find((crit) => crit.metric === selectedMetric) ??
      criteriaList[0]
  );

  const updateSelection = (selection: {
    id: number;
    name: string;
    metric: string;
  }) => {
    setSelected(selection);
    criteriaSelected(selection.metric);
  };

  return (
    <Listbox value={selected} onChange={updateSelection}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            Criteria
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-rotaxDark focus:outline-none focus:ring-1 focus:ring-rotaxDark sm:text-sm">
              <div className="ml-2">{selected.name}</div>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {criteriaList.map((criteria) => (
                  <Listbox.Option
                    key={criteria.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-rotaxDark text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={criteria}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="ml-2 flex items-center">
                          {criteria.name}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-rotaxDark",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
