import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import { classNames } from "../../util";

type Props = {
  items: string[];
  selectedItems: string[];
  addItem(item: string): void;
  removeItem(item: string): void;
};

export const Dropdown: React.FC<Props> = ({
  items: list,
  selectedItems,
  addItem,
  removeItem,
}) => {
  return (
    <div
      id="dropdown"
      className="top-100 lef-0 max-h-select absolute z-40 w-2/12 overflow-y-auto rounded bg-white shadow "
    >
      <div className="flex w-full flex-col">
        {list.map((item, key) => {
          const isSelected = selectedItems.includes(item);
          return (
            <div
              key={key}
              className={classNames(
                "flex w-full cursor-pointer rounded-t border-b border-gray-100 hover:bg-gray-100",
                isSelected ? "bg-gray-100" : ""
              )}
              onClick={() => (isSelected ? removeItem(item) : addItem(item))}
            >
              <div className="relative flex w-full items-center border-l-2 border-transparent p-2 pl-2 hover:border-gray-100">
                <div className="flex w-full items-center">
                  <div className="mx-2 leading-6">{item}</div>
                </div>
              </div>
              {isSelected && (
                <div className="flex w-8 items-center border-gray-200 py-1 pl-2 pr-8 text-gray-300">
                  <button className="h-6 w-6 text-gray-600">
                    <CheckIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
