import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SidebarLayout from "./Components/SidebarLayout";
import { EmailMessageContextProvider } from "./context/EmailMessageContext";
import { InboxMessageContextProvider } from "./context/InboxMessageContext";
import AppMessages from "./pages/AppMessages";
import AuthGuard from "./pages/AuthGuard";
import ChallengeDetails from "./pages/ChallengeDetails";
import Challenges from "./pages/Challenges";
import CustomerDetails from "./pages/CustomerDetails";
import Customers from "./pages/Customers";
import Dashboard from "./pages/Dashboard";
import EditAutomatedEmailTemplate from "./pages/EditAutomatedEmailTemplate";
import EditAutomatedEmailTemplateSave from "./pages/EditAutomatedEmailTemplateSave";
import EditInboxTemplate from "./pages/EditInboxTemplate";
import EmailMarketing from "./pages/EmailMarketing";
import HandleAuthCode from "./pages/HandleAuthCode";
import InboxTemplates from "./pages/InboxTemplates";
import Login from "./pages/Login";
import NewChallenge from "./pages/NewChallenge";
import NewEmailMessage from "./pages/NewEmailMessage";
import NewEmailMessageSend from "./pages/NewEmailMessageSend";
import NewEmailMessageTarget from "./pages/NewEmailMessageTarget";
import NewInboxMessage from "./pages/NewInboxMessage";
import NewInboxMessageSend from "./pages/NewInboxMessageSend";
import NewInboxMessageTarget from "./pages/NewInboxMessageTarget";
import NewPushNotification from "./pages/NewPushNotification";
import PushNotifications from "./pages/PushNotifications";
import RankingListDetails from "./pages/RankingListDetails";

function App() {
  return (
    <div className="App bg-background min-h-screen min-w-full font-sans">
      <ToastContainer limit={1} />
      <EmailMessageContextProvider>
        <InboxMessageContextProvider>
          <Routes>
            <Route
              path="/"
              element={
                <AuthGuard>
                  <SidebarLayout />
                </AuthGuard>
              }
            >
              <Route path="" element={<Navigate replace to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customer">
                <Route path=":id" element={<CustomerDetails />} />
              </Route>
              <Route path="/notifications" element={<PushNotifications />} />
              <Route
                path="/notifications/new"
                element={<NewPushNotification />}
              />
              <Route path="/messages" element={<AppMessages />} />
              <Route path="/messages/new" element={<NewInboxMessage />} />
              <Route
                path="/messages/new/target"
                element={<NewInboxMessageTarget />}
              />
              <Route
                path="/messages/new/send"
                element={<NewInboxMessageSend />}
              />
              <Route path="/templates" element={<InboxTemplates />} />
              <Route path="/template" element={<EditInboxTemplate />} />
              <Route path="/email-template" element={<EditAutomatedEmailTemplate />} />
              <Route path="/email-template/save" element={<EditAutomatedEmailTemplateSave />} />
              <Route path="/emails" element={<EmailMarketing />} />
              <Route path="/emails/new" element={<NewEmailMessage />} />
              <Route
                path="/emails/new/target"
                element={<NewEmailMessageTarget />}
              />
              <Route
                path="/emails/new/send"
                element={<NewEmailMessageSend />}
              />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/challenge">
                <Route path=":id" element={<ChallengeDetails />} />
              </Route>
              <Route
                path="/ranking-list/:id/"
                element={<RankingListDetails />}
              />
              <Route path="/challenges/new" element={<NewChallenge />} />
              <Route path="/challenges/edit" element={<NewChallenge />} />
            </Route>
            <Route path="/handle-auth" element={<HandleAuthCode />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </InboxMessageContextProvider>
      </EmailMessageContextProvider>
    </div>
  );
}

export default App;
