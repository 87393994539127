import { TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { Challenge, ChallengeLeaderBoard } from "../API/data-contracts";
import LeaderBoardListEntry from "../Components/UI/LeaderBoardListEntry";
import { getMetricName, showError } from "../util";

type LocationState = {
  challenge: Challenge;
};

export default function ChallengeDetails() {
  const { state } = useLocation();
  const { challenge } = state as LocationState;
  const { api } = useAuth();
  const navigate = useNavigate();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [leaderBoard, setLeaderBoard] = useState<ChallengeLeaderBoard>();

  const loadLeaderBoard = async () => {
    try {
      const response = await api.getChallengeLeaderBoard(challenge.id);
      const rankingLeaderBoard = response.data;
      if (rankingLeaderBoard) {
        setLeaderBoard(rankingLeaderBoard);
      }
    } catch (error) {
      showError(error);
    }
  };

  function deleteChallenge() {
    api.deleteChallenge(challenge.id).then(() => {
      navigate("/challenges");
    });
  }

  const edit = () => {
    navigate("/challenges/new", {
      state: { editChallenge: challenge },
    });
  };

  useEffect(() => {
    loadLeaderBoard();
  }, []);

  return (
    <div>
      <div
        className="text-bold my-4 cursor-pointer text-left text-sm font-medium"
        onClick={() => navigate(-1)}
      >
        {"< Back"}
      </div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            {challenge.title}
          </h2>
        </div>
      </div>
      <div className="mt-4 mb-4 border-b border-gray-200" />

      <div className="text-left md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <span>
            <a className="text-bold whitespace-nowrap py-4 px-1 text-sm font-medium">
              Participants{" "}
              <span className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-600">
                {challenge.participants.length}
              </span>
            </a>
            <a className="text-bold whitespace-nowrap py-4 pl-16 text-sm font-medium">
              Status:{" "}
            </a>
            <a className="font-small whitespace-nowrap py-4 text-sm">
              {challenge.status}
            </a>
          </span>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-gray-300 px-8 py-2 text-sm font-medium text-rotaxDark shadow-sm hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => setShowConfirmDelete(true)}
          >
            Delete challenge
          </button>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => edit()}
          >
            Edit challenge
          </button>
        </div>
      </div>

      {/* Participant list */}
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {getMetricName(leaderBoard?.challenge.metric)}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {/* empty <th> to make space for Chevron in list */}
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {/* filter out admin-user, which is returned but doesn't have an id */}
                  {leaderBoard?.entries
                    .filter((e) => e.id)
                    .map((leaderBoardEntry, i) => {
                      return (
                        <LeaderBoardListEntry
                          key={i}
                          rank={i + 1}
                          leaderBoardEntry={leaderBoardEntry}
                          metric={leaderBoard?.challenge.metric}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete confirm dialog */}
      {showConfirmDelete && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Delete challenge?
                    </h3>
                    <div className="mt-2">
                      {
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this challenge? This
                          action cannot be undone.
                        </p>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={deleteChallenge}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Yes, delete
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirmDelete(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  No, don't delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
