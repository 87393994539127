import { RichTextEditor } from "@mantine/rte";

type Props = {
  value: string | undefined;
  onChange: (newValue: string) => void;
};

export default function TextEditor({ value, onChange }: Props) {
  return (
    <RichTextEditor
      className="cursor-text"
      value={value ?? ""}
      onChange={onChange}
      controls={[
        ["bold", "italic", "underline", "strike"],
        ["link"],
        ["h1", "h2", "h3"],
        ["orderedList", "unorderedList"],
        ["alignLeft", "alignCenter", "alignRight"],
      ]}
    />
  );
}
