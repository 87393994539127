import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { showError as displayError } from "../util";
import { getErrorMessageFromResponse } from "./ErrorMap";

type ErrorContext = {
  error: any;
  showError: React.Dispatch<any>;
};

const context = createContext<ErrorContext>(null!);

type Props = PropsWithChildren<{}>;

export function ErrorHandler({ children }: Props) {
  const [error, showError] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      const message = t(getErrorMessageFromResponse(error)) ?? error;
      displayError(message);
      console.error(error);
    }
    showError(undefined);
  }, [error]);

  const ctx = useMemo(() => ({ error, showError }), [error]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}

export const useErrorHandler = () => useContext(context);
