import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import {
  PagePushPayload,
  PushPayloadRes,
  PushStats,
} from "../API/data-contracts";
import Pagination from "../Components/Pagination";
import InfoCard from "../Components/UI/InfoCard";
import PushNotificationListEntry from "../Components/UI/PushNotificationListEntry";
import { useErrorHandler } from "../Error/ErrorContext";

export default function PushNotifications() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const { showError } = useErrorHandler();
  const [pushStats, setPushStats] = useState<PushStats | undefined>();
  const [notificationPages, setNotificationPages] = useState<
    Map<number, PagePushPayload>
  >(new Map());
  const [notifications, setNotifications] = useState<PushPayloadRes[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const openedRate =
    pushStats != undefined
      ? ((pushStats.views / pushStats.totalSent) * 100).toLocaleString(
          undefined,
          { maximumFractionDigits: 2 }
        )
      : 0;

  const loadData = async () => {
    loadPushStats();
    loadNotifications();
  };

  const loadPushStats = async () => {
    try {
      const response = await api.getPushStatsUsingGet();
      if (response.data) {
        setPushStats(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadNotifications = async () => {
    // when page wasn't loaded before, load the new page
    if (!notificationPages.get(currentPage)) {
      try {
        const response = await api.getPushPayloadsUsingGet({
          page: currentPage,
          sort: "createdDate,desc",
        });
        const notificationPage = response.data;
        if (notificationPage) {
          setPageCount(notificationPage.totalPages ?? 0);
          setTotalElements(notificationPage.totalElements ?? 0);
          setPageSize(notificationPage.size ?? 0);
          setNotificationPages(
            new Map(notificationPages.set(currentPage, notificationPage))
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of notifications
    const newNotifications = notificationPages.get(currentPage)?.content;
    setNotifications(newNotifications);
  };

  const deletePayloadById = async (id: string) => {
    try {
      const response = await api.deletePushUsingDelete(id, {});
      if (response.body) {
        console.log("Push deleted");
        notificationPages.clear();
        loadData();
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [currentPage]);

  return (
    <div>
      <div className="text-left md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Push Notifications
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => navigate("new")}
          >
            New push notification
          </button>
        </div>
      </div>

      {pushStats && (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
          <InfoCard
            title={"Successfully sent"}
            value={(pushStats?.totalSent ?? 0).toString()}
          />
          <InfoCard title={"Opened"} value={openedRate + "%"} />
        </dl>
      )}

      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Content
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Recipients
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Delivered
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Opened
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notifications?.map((notification, i) => {
                    return (
                      <PushNotificationListEntry
                        key={i}
                        notification={notification}
                        even={i % 2 == 0}
                        deletePayloadById={deletePayloadById}
                      />
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                pageCount={pageCount}
                totalElements={totalElements}
                pageSize={pageSize}
                maxDisplayPages={8}
                onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
