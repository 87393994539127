import { useState } from "react";
import HeaderTabBar from "../Components/UI/HeaderTabBar";
import InboxMessages from "./InboxMessages";
import InboxTemplates from "./InboxTemplates";

const appIndboxIndex = 0;
const automatedMessagesIndex = 1;

export default function AppMessages() {
  const [currentTab, setCurrentTab] = useState(appIndboxIndex);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Messages
          </h2>
        </div>
      </div>

      <div className="hidden sm:block">
        <HeaderTabBar
          tabs={[{ name: "App Inbox" }, { name: "Automated Messages" }]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        {currentTab == appIndboxIndex && <InboxMessages />}
        {currentTab == automatedMessagesIndex && <InboxTemplates />}
      </div>
    </div>
  );
}
