import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { AutomatedMarketingEmailTemplate } from "../API/data-contracts";
import EmailTemplateSelection from "../Components/UI/EmailTemplateSelection";
import { useEmailMessage } from "../context/EmailMessageContext";
import { classNames } from "../util";

type LocationState = {
  template: AutomatedMarketingEmailTemplate;
};

export default function EditAutomatedEmailTemplate() {
  const { state } = useLocation();
  const { template } = state as LocationState;
  const navigate = useNavigate();
  const emailMessage = useEmailMessage();

  const contentStep = () => {
    navigate("/email-template/save", {
      state: { template: template },
    });
  };

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          ✉️ Edit {template.type} Template
        </h2>
      </div>

      {/* Navigation */}
      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          {/* Current Step */}
          <li className="relative md:flex md:flex-1">
            <div
              className="flex items-center px-6 py-4 text-sm font-medium no-underline"
              aria-current="step"
            >
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                <span className="text-rotaxDark">01</span>
              </span>
              <span className="ml-4 text-sm font-medium text-rotaxDark">
                {"Select a Template"}
              </span>
            </div>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
          <li className="relative md:flex md:flex-1">
            {/* Upcoming Step */}
            <a
              onClick={contentStep}
              className="group flex cursor-pointer items-center no-underline"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                  <span className="text-gray-500 group-hover:text-gray-900">
                    02
                  </span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  {"Content & Save"}
                </span>
              </span>
            </a>
          </li>
        </ol>
      </nav>

      {/* Content */}
      <EmailTemplateSelection selectedTemplateId={template.templateId} />

      {/* Next Button */}
      <div className="mt-10 mb-12">
        <div className="flex justify-end">
          <div>
            <button
              type="button"
              onClick={contentStep}
              disabled={!emailMessage.template}
              className={classNames(
                !emailMessage.template
                  ? "bg-gray-200 opacity-60"
                  : "bg-red text-white hover:bg-yellow hover:text-rotaxDark focus:ring-rotaxDark",
                "inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              )}
            >
              Next step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
