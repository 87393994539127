import React, { useEffect, useState } from "react";
import { useAuth } from "../../API/AuthContext";
import { EmailCampaign, MarketingEmail } from "../../API/data-contracts";
import { classNames, getFormattedDateString, isDateInFuture } from "../../util";

type Props = {
  email: MarketingEmail;
};

export default function EmailMarketingListEntry({ email }: Props) {
  const { api } = useAuth();
  const [campaign, setCampaign] = useState<EmailCampaign | undefined>();

  const loadCampaign = async () => {
    api
      .getCampaignForEmail("" + email.id)
      .then((response) => {
        setCampaign(response.data);
        console.log("campaign:", campaign);
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  function getStatus(): string {
    if (campaign) {
      if (campaign.status === "InProcess") {
        return "In Progress";
      } else {
        return campaign.status;
      }
    } else {
      return "";
    }
  }

  function getStyleForStatus(): string {
    switch (getStatus()) {
      case "Draft":
      case "Queued":
      case "In Progress":
        return "bg-gray-200 px-2 text-gray-500";
      case "Archived":
      case "Sent":
        return "bg-green-100 px-3 text-green-600";
      default:
        return "bg-gray-200 px-2 text-gray-500";
    }
  }

  useEffect(() => {
    loadCampaign();
  }, [email]);

  return (
    <tr className="bg-white text-left">
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <p className="font-medium text-gray-900">
          {(email.name?.length ?? 0) > 30
            ? email.name?.substring(0, 30) + "..."
            : email.name}
        </p>
      </td>

      <td className="whitespace-normal break-normal px-6 py-4 text-sm text-gray-500">
        <p className="font-medium text-gray-900">{"ID: " + email.templateId}</p>
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {campaign?.statistics.globalStats.delivered ?? "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {campaign?.statistics.globalStats.viewed ?? "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {campaign?.statistics.globalStats.uniqueClicks ?? "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        {campaign?.sentDate
          ? getFormattedDateString(campaign?.sentDate)
          : campaign?.scheduledAt
          ? getFormattedDateString(campaign?.scheduledAt)
          : "-"}
      </td>

      <td className="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500">
        <span
          className={classNames(
            getStyleForStatus(),
            "inline-flex rounded-full text-xs font-semibold leading-5"
          )}
        >
          {getStatus()}
        </span>
      </td>
    </tr>
  );
}
