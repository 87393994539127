export class CareerRank {
  type: CareerRankType;

  constructor(type: CareerRankType) {
    this.type = type;
  }

  get name(): string {
    switch (this.type) {
      case CareerRankType.rookie:
        return "MAX Rookie";
      case CareerRankType.amateur:
        return "MAX Amateur";
      case CareerRankType.expert:
        return "MAX Expert";
      case CareerRankType.champion:
        return "MAX Champion";
      default:
        return "";
    }
  }

  static getCareerRanks(): CareerRank[] {
    return [
      new CareerRank(CareerRankType.rookie),
      new CareerRank(CareerRankType.amateur),
      new CareerRank(CareerRankType.expert),
      new CareerRank(CareerRankType.champion),
    ];
  }
}

export enum CareerRankType {
  rookie = "rookie",
  amateur = "amateur",
  expert = "expert",
  champion = "champion",
}
