import React, { useCallback, useEffect } from "react";
import { FilterCondition } from "../../API/data-contracts";
import { useEmailMessage } from "../../context/EmailMessageContext";
import { FilterProperty } from "../../model/filter-property";
import { RMDConstants } from "../../model/rmdconstants";
import TextEditor from "../Editor/RichTextEditor";
import FilterSelection from "./FilterSelection";

export default function EditEmailContent() {
  const emailMessage = useEmailMessage();

  const handleCampaignNameChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    emailMessage.setCampaignName(event.currentTarget.value);
  };

  const handleSubjectChange = (event: React.FormEvent<HTMLInputElement>) => {
    emailMessage.setEmailSubject(event.currentTarget.value);
  };

  const addNewCondition = () => {
    let filterCopy = [...emailMessage.filterConditions];
    filterCopy.push({
      input: [],
      property: new FilterProperty(),
      selectedOperator: null,
    });
    emailMessage.setFilterConditions(filterCopy);
  };

  const removeCondition = (index: number) => {
    if (index > 0) {
      let filterCopy = [...emailMessage.filterConditions];
      if (index !== -1) {
        filterCopy.splice(index, 1);
        emailMessage.setFilterConditions(filterCopy);
      }
    }
  };

  const updateCondition = (
    conditionIndex: number,
    condition: FilterCondition
  ) => {
    let filterCopy = [...emailMessage.filterConditions];
    filterCopy[conditionIndex] = condition;
    emailMessage.setFilterConditions(filterCopy);
  };

  const handleParamChange = (paramName: string, newValue: string) => {
    emailMessage.setParameters(
      new Map(emailMessage.parameters.set(paramName, newValue))
    );
  };

  return (
    <div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Content
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Change the dynamic content of this template.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  {!emailMessage.automatedEmailTemplate && (
                    <div className="col-span-3">
                      <label
                        htmlFor={"campaignName"}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Campaign name
                      </label>
                      <input
                        type="text"
                        name={"campaignName"}
                        id={"campaignName"}
                        autoComplete="off"
                        value={emailMessage.campaignName}
                        onChange={handleCampaignNameChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  )}

                  <div className="col-span-3">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name={"subject"}
                      id={"subject"}
                      autoComplete="off"
                      placeholder={emailMessage.template?.subject}
                      value={emailMessage.emailSubject}
                      onChange={handleSubjectChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="template"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Template
                  </label>
                  <div className="max-h-128 overflow-auto rounded-md border border-gray-300 p-2 shadow-sm">
                    {emailMessage.template?.htmlContent && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: emailMessage.template.htmlContent,
                        }}
                      />
                    )}
                  </div>
                </div>

                {emailMessage.template?.params?.map((param) => (
                  <div className="col-span-3">
                    <label
                      htmlFor={param.paramName}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {param.paramName} | {param.paramInTemplate}
                    </label>
                    {param.paramInTemplate.includes("_html") ? (
                      <TextEditor
                        value={emailMessage.parameters.get(param.paramName)}
                        onChange={(newValue) =>
                          handleParamChange(param.paramName, newValue)
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        name={param.paramName}
                        id={param.paramName}
                        placeholder={param.defaultValue}
                        autoComplete="off"
                        value={emailMessage.parameters.get(param.paramName)}
                        onChange={(event) =>
                          handleParamChange(
                            param.paramName,
                            event.currentTarget.value
                          )
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Only display target step when user is not editing an automated email template */}
        {!emailMessage.automatedEmailTemplate && (
          <div>
            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-5">
                <div className="border-t border-gray-200"></div>
              </div>
            </div>

            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Target
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      Select users who should receive the push notification.
                    </p>
                  </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="overflow-hidden shadow sm:rounded-md">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="mt-4">
                        <div className="relative">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300"></div>
                          </div>
                          <div className="relative flex items-center justify-between">
                            <span className="bg-white pr-3 text-sm text-gray-500">
                              Filter all users
                            </span>
                            <button
                              type="button"
                              onClick={addNewCondition}
                              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                            >
                              {/* Heroicon name: solid/plus-sm */}
                              <svg
                                className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <span>Add filter condition</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="pt-4">
                        <div className="overflow-hidden rounded-md border border-gray-200 bg-white">
                          <FilterSelection
                            filterProperties={
                              RMDConstants.pushNotificationFilterProperties
                            }
                            filterConditions={emailMessage.filterConditions}
                            removeCondition={removeCondition}
                            updateCondition={updateCondition}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
