import { useEffect, useState } from "react";
import { useAuth } from "../API/AuthContext";
import { UserStats, WebExpStats } from "../API/data-contracts";
import InfoCard from "../Components/UI/InfoCard";

export default function Dashboard() {
  const [userStats, setUserStats] = useState<UserStats | undefined>();
  const [expStats, setExpStats] = useState<WebExpStats | undefined>();
  const { logout, api, loggedInUser } = useAuth();

  const loadUserStats = async () => {
    try {
      const response = await api.getUserStatsUsingGet();
      if (response.data) {
        setUserStats(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadExpStats = async () => {
    try {
      const response = await api.getWebExpStatsUsingGet();
      if (response.data) {
        setExpStats(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadUserStats();
    loadExpStats();
  }, []);

  return (
    <div>
      <h3 className="text-left text-lg font-medium leading-6 text-gray-900">
        User Statistics 📊
      </h3>
      <h5 className="text-left text-base font-medium leading-6 text-gray-400">
        Last 30 days
      </h5>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <InfoCard
          title={"Active Users"}
          value={(userStats?.usersLast30Days ?? 0).toString()}
        />
        <InfoCard
          title={"Inbox Open Rate"}
          value={
            ((userStats?.inboxOpenRate ?? 0) * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) + "%"
          }
        />
        <InfoCard
          title={"Inbox Click Rate"}
          value={
            ((userStats?.inboxClickRate ?? 0) * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) + "%"
          }
        />
        <InfoCard
          title={"Push Open Rate"}
          value={
            ((userStats?.pushOpenRate ?? 0) * 100).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) + "%"
          }
        />
      </dl>

      <h3 className="mt-10  text-left text-lg font-medium leading-6 text-gray-900">
        Race Experience 🏎️
      </h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <InfoCard
          title={"Experience completed 🏁"}
          value={(expStats?.experienceFinished ?? 0).toString()}
        />
        <InfoCard
          title={"New customers acquired 👋"}
          value={(expStats?.newlyAcquiredCustomers ?? 0).toString()}
        />
        <InfoCard
          title={"Marketing consent given ✅"}
          value={(expStats?.marketingConsentGiven ?? 0).toString()}
        />
      </dl>

      {loggedInUser?.alias ||
        (loggedInUser?.username && (
          <p className="mt-6 ml-2 text-left text-sm text-gray-500">
            Logged in as {loggedInUser?.alias ?? loggedInUser?.username}
          </p>
        ))}
      <p
        role="button"
        className="mt-1 ml-2 text-left text-sm text-gray-500 underline"
        onClick={() => logout()}
      >
        Logout
      </p>
    </div>
  );
}
