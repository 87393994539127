import React, { useState } from "react";
import { InboxMessagePayload } from "../../API/data-contracts";
import { classNames, getFormattedDateString, isDateInFuture } from "../../util";
import ToggleButton from "../UI/ToggleButton";

type Props = {
  payload: InboxMessagePayload;
  editPayload(payload: InboxMessagePayload): void;
  toggleHide(payload: InboxMessagePayload): void;
  allowEdit: boolean
};

export default function InboxMessageListEntry({
  payload,
  editPayload,
  toggleHide,
  allowEdit
}: Props) {
  const [hidden, setHidden] = useState(payload.hidden);
  const onToggleClick = () => {
    const newHidden = !hidden;
    setHidden(newHidden);
    payload.hidden = newHidden;
    toggleHide(payload);
  };

  return (
    <tr
      className={classNames(
        hidden ? "opacity-30" : "",
        "text-left bg-white"
      )}
    >
      <td
        className="curor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
        onClick={() => editPayload(payload)}
      >
        {payload.message && payload.message.title.length > 30
          ? payload.message.title.substring(0, 30) + "..."
          : payload.message?.title}
      </td>
      <td
        className="cursor-pointer whitespace-normal break-normal px-6 py-4 text-sm text-gray-500"
        onClick={() => editPayload(payload)}
      >
        {payload.message && payload.message.body.length > 140
          ? payload.message.body.substring(0, 140) + "..."
          : payload.message?.body}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
        {payload.recipients}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
        {payload.views}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
        <p>{payload.message?.url ? payload.ctaClicks : "-"}</p>
      </td>
      {payload.scheduleDate ? (
        <td className="whitespace-nowrap px-3 py-4 text-center text-sm">
          <p className="text-gray-500">{getFormattedDateString(payload.scheduleDate)}</p>
          {isDateInFuture(payload.scheduleDate) ? (
            <p className="text-gray-500">(pending)</p>
          ) : (
            <p className="text-gray-300">(scheduled)</p>
          )}
        </td>
      ) : (
        <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
          {getFormattedDateString(payload.createdDate)}
        </td>
      )}
      {allowEdit && <td className="whitespace-nowrap px-6 py-4 text-sm font-medium">
        <ToggleButton toggled={!hidden} onClick={onToggleClick} />
      </td>}
    </tr>
  );
}
