import React from "react";
import { classNames } from "../../util";

type Props = {
  tabs: { name: string; value: number | undefined }[];
  currentTab: number;
  setCurrentTab: (index: number) => void;
};

export default function SelectionTabBar({
  tabs,
  currentTab,
  setCurrentTab,
}: Props) {
  return (
    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
      {tabs.map((tab, index) => (
        <a
          key={tab.name}
          onClick={() => setCurrentTab(index)}
          className={classNames(
            index == currentTab
              ? "text-bold"
              : "border-transparent text-gray-400 hover:text-gray-700",
            "cursor-pointer whitespace-nowrap py-4 px-1 text-sm font-medium"
          )}
          aria-current={index == currentTab ? "page" : undefined}
        >
          {tab.name}{" "}
          <span className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-600">
            {tab.value}
          </span>
        </a>
      ))}
    </nav>
  );
}
