import React, { useState } from "react";
import { InboxMessageTemplateRes } from "../../API/data-contracts";
import { classNames } from "../../util";
import ToggleButton from "../UI/ToggleButton";

type Props = {
  template: InboxMessageTemplateRes;
  even: boolean;
  editTemplate(template: InboxMessageTemplateRes): void;
  toggleEnabled(template: InboxMessageTemplateRes): void;
};

export default function InboxTemplateListEntry({
  template,
  even,
  editTemplate,
  toggleEnabled
}: Props) {
  const [enabled, setEnabled] = useState(template.enabled);
  const onToggleClick = () => {
    const newEnabled = !enabled;
    setEnabled(newEnabled);
    template.enabled = newEnabled;
    toggleEnabled(template);
  };

  return (
    <tr
      className={classNames(
        even ? "bg-white" : "bg-gray-50",
        template.payload.hidden ? "opacity-30" : "",
        "text-left"
      )}
    >
      <td
        className="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
        onClick={() => editTemplate(template)}
      >
        { template.type }
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
        onClick={() => editTemplate(template)}
      >
        { template.payload.message?.title }
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
        <ToggleButton
          toggled={template.enabled}
          onClick={onToggleClick}
        />
      </td>
    </tr>
  );
}
