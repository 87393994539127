import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { AutomatedMarketingEmailTemplate } from "../API/data-contracts";
import EditEmailContent from "../Components/UI/EditEmailContent";
import { useEmailMessage } from "../context/EmailMessageContext";
import { useErrorHandler } from "../Error/ErrorContext";

type LocationState = {
  template: AutomatedMarketingEmailTemplate;
};

export default function EditAutomatedEmailTemplateSave() {
  const { state } = useLocation();
  const { template } = state as LocationState;
  const emailMessage = useEmailMessage();
  const navigate = useNavigate();
  const { api } = useAuth();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSavingEmail, setSavingEmail] = useState(false);
  const { showError } = useErrorHandler();

  const templateStep = () => {
    navigate("/email-template", {
      state: { template: template },
    });
  };

  const goToEmailMarketing = () => {
    setShowSuccess(false);
    navigate("/emails");
  };

  const save = useMemo(
    () => async () => {
      setSavingEmail(true);

      const automatedEmailTemplate = emailMessage.getAutomatedEmailTemplate();
      if (automatedEmailTemplate) {
        api
          .updateAutomatedMarketingEmail(automatedEmailTemplate)
          .then(() => {
            setShowSuccess(true);
            setShowConfirm(false);
            setSavingEmail(false);
          })
          .catch((error) => {
            setShowConfirm(false);
            setSavingEmail(false);
            showError(error);
          });
      }
    },
    [api, emailMessage]
  );

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          ✉️ Edit {template.type} Template
        </h2>
      </div>

      {/* Navigation */}
      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          <li className="relative md:flex md:flex-1">
            <a
              onClick={templateStep}
              className="group flex cursor-pointer items-center no-underline"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  {"Select a Template"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            {showSuccess && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                  {"Content & Save"}
                </span>
              </span>
            )}

            {!showSuccess && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                  <span className="text-rotaxDark">02</span>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  Send
                </span>
              </span>
            )}
          </li>
        </ol>
      </nav>

      {/* Content */}
      <EditEmailContent />

      {/* Save Button */}
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1" />
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="mt-10 mb-12">
              <div className="flex justify-between">
                <div>
                  <button
                    type="button"
                    onClick={templateStep}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => setShowConfirm(true)}
                    className={
                      "inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Send confirmation dialog */}
      {showConfirm && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Modify email?
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to modify the{" "}
                        <b>{template.type}</b> email?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={save}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {isSavingEmail && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Yes, modify email
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirm(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  No, don't modify it
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success dialog */}
      {showSuccess && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  {/* Heroicon name: outline/check */}
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Success
                  </h3>
                  <div className="mt-2 flex flex-col">
                    <p className="text-sm text-gray-500">
                      The email has been changed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={goToEmailMarketing}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:text-sm"
                >
                  Go back to Email Marketing
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
