export class Weekday {
  value: 1 | 2 | 3 | 4 | 5 | 6 | 7;

  constructor(value: 1 | 2 | 3 | 4 | 5 | 6 | 7) {
    this.value = value;
  }

  get name(): string {
    switch (this.value) {
      case 1:
        return "Sunday";
      case 2:
        return "Monday";
      case 3:
        return "Tuesday";
      case 4:
        return "Wednesday";
      case 5:
        return "Thursday";
      case 6:
        return "Friday";
      case 7:
        return "Saturday";
    }
  }

  static getWeekdays(): Weekday[] {
    return [
      new Weekday(1),
      new Weekday(2),
      new Weekday(3),
      new Weekday(4),
      new Weekday(5),
      new Weekday(6),
      new Weekday(7),
    ];
  }
}
