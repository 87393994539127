import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { MarketingEmail } from "../API/data-contracts";
import Pagination from "../Components/Pagination";
import EmailMarketingListEntry from "../Components/UI/EmailMarketingListEntry";
import SelectionTabBar from "../Components/UI/SelectionTabBar";
import { useEmailMessage } from "../context/EmailMessageContext";
import { useErrorHandler } from "../Error/ErrorContext";

export default function EmailCampaigns() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const emailMessage = useEmailMessage();
  const { showError } = useErrorHandler();
  //   const [emailPages, setEmailPages] = useState<
  //     Map<number, MarketingEmailPage> //TODO: change to page object once packend supports it
  //   >(new Map());
  const [emails, setEmails] = useState<MarketingEmail[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const loadEmails = async () => {
    try {
      const response = await api.getEmails({
        sort: "createdDate,desc",
      });
      const emails = response.data;
      if (emails) {
        // manually sort the emails as backend doesn't support it yet
        const emailsSortedDesc = emails.sort((objA, objB) => {
          const dateA = objA.createdDate;
          const dateB = objB.createdDate;
          if (dateA && dateB) {
            return new Date(dateB).getTime() - new Date(dateA).getTime();
          } else {
            return 0;
          }
        });

        setPageCount(1);
        setTotalElements(emailsSortedDesc.length);
        setPageSize(emailsSortedDesc.length);
        setEmails(emailsSortedDesc);
      }
    } catch (error) {
      showError(error);
    }

    // //TODO: use this once paging is implemented on backedn
    // // when page wasn't loaded before, load the new page
    // if (!emailPages.get(currentPage)) {
    //   try {
    //     const response = await api.getEmails({
    //       page: currentPage,
    //       sort: "createdDate,desc",
    //     });
    //     const emailPage = response.data;
    //     if (emailPage) {
    //       setPageCount(emailPage.totalPages ?? 0);
    //       setTotalElements(emailPage.totalElements ?? 0);
    //       setPageSize(emailPage.size ?? 0);
    //       setEmailPages(new Map(emailPages.set(currentPage, emailPage)));
    //     }
    //   } catch (error) {
    //     showError(error);
    //   }
    // }

    // // set the current page of notifications
    // const newEmails = emailPages.get(currentPage)?.content;
    // setEmails(newEmails);
  };

  const showNewEmailPage = () => {
    emailMessage.initContext();
    navigate("new");
  };

  useEffect(() => {
    loadEmails();
  }, [currentPage]);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1" />
        <div className="flex md:mt-0 md:ml-4 ">
          <button
            type="button"
            className="mt-2 ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={showNewEmailPage}
          >
            New message
          </button>
        </div>
      </div>

      <div className="mt-5 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Template
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Recipients
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Opened
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Clicked
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Delivery
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emails?.map((email, i) => {
                    return <EmailMarketingListEntry key={i} email={email} />;
                  })}
                </tbody>
              </table>
              <Pagination
                pageCount={pageCount}
                totalElements={totalElements}
                pageSize={pageSize}
                maxDisplayPages={8}
                onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
