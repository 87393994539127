import { FilterOperator, OperatorType } from "./filter-operator";
import { FilterProperty, FilterType, InputType } from "./filter-property";

export abstract class RMDConstants {
  static readonly pushNotificationFilterProperties: FilterProperty[] = [
    FilterProperty.create(FilterType.none),
    FilterProperty.create(FilterType.lastVisit),
    FilterProperty.create(FilterType.age),
  ];

  static readonly challengeFilterProperties: FilterProperty[] = [
    FilterProperty.create(FilterType.none),
    FilterProperty.create(FilterType.age),
    FilterProperty.create(FilterType.weekDay),
    FilterProperty.create(FilterType.careerLevel),
    FilterProperty.create(FilterType.joined),
  ];
}
