import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { InboxMessageTemplateRes } from "../API/data-contracts";
import EditMessageContent from "../Components/UI/EditMessageContent";
import { useInboxMessage } from "../context/InboxMessageContext";
import { showError } from "../util";

type LocationState = {
  template: InboxMessageTemplateRes;
};

export default function EditInboxTemplate() {
  const { state } = useLocation();
  const { template } = state as LocationState;
  const { api } = useAuth();
  const inboxMessage = useInboxMessage();

  const save = useMemo(
    () => async () => {
      try {
        const messagePayload = inboxMessage.getMessagePayload();
        const newTemplate = {
          ...template,
        };
        if (messagePayload) {
          newTemplate.payload = messagePayload;
        }
        const response = await api.updateInboxTemplate(newTemplate);

        // upload images
        if (
          response.data.id &&
          (inboxMessage.coverImage || inboxMessage.galleryImages.length > 0)
        ) {
          await updateImages(response.data.id);
        }
      } catch (error) {
        showError(error);
      }
    },
    [api, inboxMessage]
  );

  //TODO: addInboxTemplateImage and deleteInboxTemplateImages endpoints are not implemented yet
  const updateImages = useMemo(
    () => async (messageId: string) => {
      // upload new images
      const images = [];
      if (inboxMessage.coverImage) images.push(inboxMessage.coverImage);
      images.push(...inboxMessage.galleryImages);

      // // Upload new images
      // for (let i = 0; i < images.length; i++) {
      //   if (images[i].file) {
      //     await api
      //       .addInboxTemplateImage(messageId, {
      //         image: images[i].file,
      //       })
      //       .catch((error) => showError(error.toString()));
      //   }
      // }

      // // Delete images
      // if (inboxMessage.imageIdsToDelete.length > 0) {
      //   await api
      //     .deleteInboxTemplateImages(messageId, {
      //       ids: inboxMessage.imageIdsToDelete,
      //     })
      //     .catch((error) => showError(error.toString()));
      // }
    },
    [
      // setShowConfirm,
      // setSendingMessage,
      inboxMessage,
      api,
    ]
  );

  useEffect(() => {
    inboxMessage.initContext(template.payload);
  }, [template]);

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          📱 Edit {template.type} Template
        </h2>
      </div>

      <EditMessageContent />

      <div className="mt-10 mb-12">
        <div className="flex justify-end">
          <div>
            <button
              type="button"
              onClick={save}
              className="inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
