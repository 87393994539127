import React from "react";
import "./ActivityIndicator.css";

export type ActivityIndicatorProps = {
  className?: string;
};

function ActivityIndicator({ className = undefined }: ActivityIndicatorProps) {
  return <div id="loader" className={className}></div>;
}

export default ActivityIndicator;
