import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { useState } from "react";
import Dropdown from "./Dropdown";

type Props = {
  items: string[];
  initiallySelectedItems: string[];
  selectionChanged: (selectedItems: string[]) => void;
};

export default function MultiSelect({ items, initiallySelectedItems, selectionChanged }: Props) {
  // state showing if dropdown is open or closed
  const [dropdown, setDropdown] = useState(false);
  // contains selected items
  const [selectedItems, setSelected] = useState<string[]>(initiallySelectedItems);

  const toogleDropdown = () => {
    setDropdown(!dropdown);
  };
  // adds new item to multiselect
  const addItem = (item: string) => {
    const newSelectedItems = [...selectedItems, item];
    setSelected(newSelectedItems);
    selectionChanged(newSelectedItems);
    setDropdown(false);
  };
  // removes item from multiselect
  const removeItem = (item: string) => {
    const filtered = selectedItems.filter((e) => e !== item);
    setSelected(filtered);
    selectionChanged(filtered);
  };

  return (
    <div className="mx-auto flex w-full flex-col items-center">
      <div className="w-full">
        <div className="relative flex flex-col items-center">
          <div className="w-full">
            <div className="my-1 flex rounded-md border border-gray-300 bg-white p-0.5">
              <div
                className="flex flex-auto cursor-pointer flex-wrap"
                onClick={toogleDropdown}
              >
                {selectedItems.map((tag, index) => {
                  return (
                    <div
                      key={index}
                      className="m-1 flex items-center justify-center rounded-full border border-gray-300 bg-gray-100 py-1 px-2 font-medium text-black"
                    >
                      <div className="max-w-full flex-initial text-xs font-normal leading-none">
                        {tag}
                      </div>
                      <div className="flex flex-auto flex-row-reverse">
                        <div onClick={() => removeItem(tag)}>
                          <XIcon className="ml-2 h-3 w-3" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="flex w-8 items-center border-gray-200 py-1 pl-2 pr-1 text-gray-300"
                onClick={toogleDropdown}
              >
                <button className="h-6 w-6 cursor-pointer text-gray-600 outline-none focus:outline-none">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {dropdown ? (
          <Dropdown
            items={items}
            selectedItems={selectedItems}
            addItem={addItem}
            removeItem={removeItem}
          ></Dropdown>
        ) : null}
      </div>
    </div>
  );
}
