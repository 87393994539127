import React, { useEffect, useState } from "react";
import { useAuth } from "../API/AuthContext";
import { PageUser, User } from "../API/data-contracts";
import { ContentType } from "../API/http-client";
import Pagination from "../Components/Pagination";
import CustomerListEntry from "../Components/UI/CustomerListEntry";
import { useErrorHandler } from "../Error/ErrorContext";

export default function Customers() {
  const { api } = useAuth();
  const [customerPages, setCustomerPages] = useState<Map<number, PageUser>>(
    new Map()
  );
  const { showError } = useErrorHandler();
  const [customers, setCustomers] = useState<User[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isExporting, setExporting] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const loadCustomers = async () => {
    // when page wasn't loaded before, load the new page
    if (!customerPages.get(currentPage)) {
      try {
        const response = await api.listUsingGet({
          searchQuery: searchTerm,
          page: currentPage,
          sort: "createdDate,desc"
        });
        const customerPage = response.data;
        if (customerPage) {
          setPageCount(customerPage.totalPages ?? 0);
          setTotalElements(customerPage.totalElements ?? 0);
          setPageSize(customerPage.size ?? 0);
          setCustomerPages(
            new Map(customerPages.set(currentPage, customerPage))
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of customers
    const newCustomers = customerPages.get(currentPage)?.content;
    setCustomers(newCustomers);
  };

  const updateSearch = (search: string) => {
    // reset cached page data
    customerPages.clear();
    // set new search term
    setSearchTerm(search);
  };

  const exportAsCsv = async () => {
    setExporting(true);
    try {
      const response = await api.userExportUsingGet(
        {
          fileName: "users.csv",
        },
        {
          headers: {
            Accept: "text/csv",
          },
          type: ContentType.UrlEncoded,
          format: "text",
        }
      );

      // Download file, supported by html5 browsers
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent("" + response.data)
      );
      element.setAttribute("download", "users.csv");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (error) {
      showError(error);
    }

    setExporting(false);
  };

  useEffect(() => {
    loadCustomers();
  }, [currentPage, searchTerm]);

  return (
    <div>
      <div className="mb-8 md:flex md:items-center md:justify-between">
        <div className="flex min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            Customer List
          </h2>
        </div>
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
          onClick={() => exportAsCsv()}
        >
          {isExporting && (
            <svg
              className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Export as CSV
        </button>
      </div>

      <div className="fle mb-4">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <input
            id="search"
            name="search"
            onChange={(event) => updateSearch(event.target.value)}
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 shadow-sm focus:border-rotaxDark focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-rotaxDark sm:text-sm"
            placeholder="Search"
            type="search"
          />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Username
                    </th>
                    {/* <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Login Code
                            </th> */}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Joined
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Platform
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Last Visit
                    </th>
                    {/* <th scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Delete
                            </th> */}
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {customers?.map((customer, i) => {
                    return <CustomerListEntry key={i} user={customer} />;
                  })}
                </tbody>
              </table>

              <Pagination
                pageCount={pageCount}
                totalElements={totalElements}
                pageSize={pageSize}
                maxDisplayPages={8}
                onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
