import { XIcon } from "@heroicons/react/outline";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { useEmailMessage } from "../context/EmailMessageContext";
import { useErrorHandler } from "../Error/ErrorContext";
import { useInterval } from "../hooks/useInterval";
import { classNames, dateToYYYYmmdd, isValidEmail } from "../util";

export default function NewEmailMessageSend() {
  const emailMessage = useEmailMessage();
  const navigate = useNavigate();
  const { api } = useAuth();
  const { showError } = useErrorHandler();
  const [showModal, setShowModal] = useState(false);
  const [showContactsCreated, setShowContactsCreated] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmCreateContactList, setShowConfirmCreateContactList] =
    useState(false);
  const [isScheduledMessage, setScheduledMessage] = useState(false);
  const [isSendingMessage, setSendingMessage] = useState(false);
  const [isCreatingContactList, setCreatingContactList] = useState(false);
  const [isCheckingInitiateStatus, setCheckingInitiateStatus] = useState(false);
  const [initiateStatusCheckCount, setInitiateStatusCheckCount] = useState(0);
  const [sendClicked, setSendClicked] = useState(false);
  const [createContactListClicked, setCreateContactListClicked] =
    useState(false);
  const [showTestEmailDialog, setShowTestEmailDialog] = useState(false);
  const [isSendingTestEmail, setSendingTestEmail] = useState(false);
  const [emailInitiateSendSuccess, setEmailInitiateSendSuccess] = useState<
    boolean | undefined
  >(undefined);
  const [emailSendSuccess, setEmailSendSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [newRecipients, setRecipients] = useState<string | undefined>(
    undefined
  );
  const [recipient, setRecipient] = useState<string | undefined>(undefined);
  const [recipientsValid, setRecipientsValid] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState<number | undefined>();
  const [templateName, setTemplateName] = useState<string | undefined>();
  const [marketingEmailId, setMarketingEmailId] = useState<
    string | undefined
  >();

  const goToEmailMarketing = () => {
    setShowModal(false);
    navigate("/emails");
  };

  const templateStep = () => {
    navigate("/emails/new");
  };

  const targetStep = () => {
    navigate("/emails/new/target");
  };

  const handleScheduledChange = (event: React.FormEvent<HTMLInputElement>) => {
    var newValue = event.currentTarget.value === "true";
    setScheduledMessage(newValue);
  };

  const handleScheduleTimeChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    emailMessage.setScheduleTime(event.currentTarget.value);
  };

  const handleScheduleDateChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      emailMessage.setScheduleDate(new Date(dateValue));
    }
  };

  const handleRecipientsChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newRecipients = event.currentTarget.value;
    const emailAddresses = newRecipients.split(",");
    const valid = emailAddresses.every((email) => {
      return isValidEmail(email);
    });
    setRecipient(emailAddresses.length > 0 ? emailAddresses[0] : undefined);
    setRecipientsValid(valid);
    setRecipients(newRecipients);
  };

  const sendTestEmail = useMemo(
    () => async () => {
      if (recipient && emailMessage.template) {
        const testEmail = emailMessage.getTestEmail(recipient);
        if (testEmail) {
          setSendingTestEmail(true);
          api
            .sendTestEmail(testEmail)
            .catch((error) => {
              showError(error);
            })
            .finally(() => {
              setSendingTestEmail(false);
              setShowTestEmailDialog(false);
            });
        }
      }
    },
    [emailMessage, newRecipients, recipientsValid]
  );

  const confirm = useMemo(
    () => async () => {
      const marketingEmail = emailMessage.getMarketingEmail();
      if (marketingEmail) {
        setSendingMessage(true);
        api
          .getMarketingEmailPreview(marketingEmail)
          .then((preview) => {
            setShowConfirm(true);
            setSendingMessage(false);
            setRecipientsCount(preview.data.recipients ?? 0);
            setTemplateName(preview.data.templateName);
          })
          .catch((error) => {
            setShowConfirm(false);
            setSendingMessage(false);
            showError(error);
          });
      }
    },
    [emailMessage]
  );

  const confirmCreateContactList = useMemo(
    () => async () => {
      const marketingEmail = emailMessage.getMarketingEmail();
      if (marketingEmail) {
        setCreatingContactList(true);
        api
          .getMarketingEmailPreview(marketingEmail)
          .then((preview) => {
            setShowConfirmCreateContactList(true);
            setCreatingContactList(false);
            setRecipientsCount(preview.data.recipients ?? 0);
          })
          .catch((error) => {
            setShowConfirmCreateContactList(false);
            setCreatingContactList(false);
            showError(error);
          });
      }
    },
    [emailMessage]
  );

  const initiateSend = useMemo(
    () => async () => {
      const marketingEmail = emailMessage.getMarketingEmail();
      if (marketingEmail && !isSendingMessage) {
        setShowConfirm(false);
        setEmailSendSuccess(undefined);
        setEmailInitiateSendSuccess(undefined);
        setSendClicked(true);
        setSendingMessage(true);

        api
          .initiateSendEmail(marketingEmail)
          .then((response) => {
            setMarketingEmailId(response.data.marketingEmailId);
            // activate polling to check if the initate is finished (reset the count)
            setInitiateStatusCheckCount(0);
            setCheckingInitiateStatus(true);
          })
          .catch((error) => {
            setShowConfirm(false);
            setSendingMessage(false);
            setEmailInitiateSendSuccess(false);
            setEmailSendSuccess(false);
            setSendClicked(false);
            setShowModal(false);
            showError(error);
          });
      }
    },
    [emailMessage, initiateStatusCheckCount, isCheckingInitiateStatus]
  );

  const createContactList = useMemo(
    () => async () => {
      const marketingEmail = emailMessage.getMarketingEmail();
      if (marketingEmail && !isCreatingContactList) {
        setShowConfirmCreateContactList(false);
        setCreateContactListClicked(true);
        setCreatingContactList(true);

        api
          .createContactList(marketingEmail)
          .then((response) => {
            setShowContactsCreated(true);
            setCreateContactListClicked(false);
            setCreatingContactList(false);
          })
          .catch((error) => {
            setShowContactsCreated(false);
            setCreateContactListClicked(false);
            setCreatingContactList(false);
            showError(error);
          });
      }
    },
    [emailMessage, initiateStatusCheckCount, isCheckingInitiateStatus]
  );

  // check every second if initiating email is complete and send email if it is
  useInterval(
    async () => {
      if (marketingEmailId) {
        api.getEmail(marketingEmailId).then((response) => {
          setInitiateStatusCheckCount(initiateStatusCheckCount + 1);

          if (initiateStatusCheckCount > 50) {
            setShowConfirm(false);
            setSendingMessage(false);
            setEmailInitiateSendSuccess(false);
            setEmailSendSuccess(false);
            setSendClicked(false);
            setShowModal(false);
            showError("Timeout while preparing email.");

            // stop polling with timeout error
            setCheckingInitiateStatus(false);
          }

          // check if email is ready to send, if yes send it
          if (response.data.readyForSend) {
            send();

            // stop polling
            setCheckingInitiateStatus(false);
            setEmailInitiateSendSuccess(true);
          }
        });
      }
    },
    isCheckingInitiateStatus ? 3000 : null
  );

  const send = useMemo(
    () => async () => {
      if (marketingEmailId) {
        api
          .sendEmail(marketingEmailId)
          .then(() => {
            setEmailSendSuccess(true);
            setSendingMessage(false);
            setShowModal(true);
            emailMessage.clearContext();
          })
          .catch((error) => {
            setShowConfirm(false);
            setSendingMessage(false);
            setEmailInitiateSendSuccess(false);
            setEmailSendSuccess(false);
            setSendClicked(false);
            setShowModal(false);
            showError(error);
          });
      }
    },
    [emailMessage, marketingEmailId]
  );

  let today = new Date();
  let inThreeYears = new Date();
  inThreeYears.setFullYear(inThreeYears.getFullYear() + 3);

  const minDate = dateToYYYYmmdd(today);
  const maxDate = dateToYYYYmmdd(inThreeYears);

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          {emailMessage.isExistingMessage
            ? "✉️ Edit Email Message"
            : "✉️ New Email Message"}
        </h2>
      </div>

      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          <li className="relative md:flex md:flex-1">
            <a
              onClick={templateStep}
              className="group flex cursor-pointer items-center"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  {"Select a Template"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            <a
              onClick={targetStep}
              className="group flex cursor-pointer items-center"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                  {"Content & Target"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            {showModal && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                  Send
                </span>
              </span>
            )}

            {!showModal && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                  <span className="text-rotaxDark">03</span>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  Send
                </span>
              </span>
            )}
          </li>
        </ol>
      </nav>

      {/* Content */}
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Schedule
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide when you want to send out the email.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="msg_now"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="false"
                        checked={!isScheduledMessage}
                        onChange={handleScheduledChange}
                      />
                      <label
                        htmlFor="msg_now"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Now
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="msg_scheduled"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="true"
                        checked={isScheduledMessage}
                        onChange={handleScheduledChange}
                      />
                      <label
                        htmlFor="msg_scheduled"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Selected date and time
                      </label>
                    </div>
                  </div>
                </fieldset>
                {isScheduledMessage && (
                  <label className="font-small ml-0 block text-sm text-gray-400">
                    Actual email delivery time may differ by up to 15 minutes
                  </label>
                )}
                {isScheduledMessage && (
                  <div className="flex gap-4">
                    <div className="flex-auto">
                      <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        onChange={handleScheduleDateChange}
                        value={
                          emailMessage.scheduleDate
                            ? dateToYYYYmmdd(emailMessage.scheduleDate)
                            : undefined
                        }
                      />
                    </div>
                    <div className="flex-auto">
                      <input
                        type="time"
                        onChange={handleScheduleTimeChange}
                        value={emailMessage.scheduleTime}
                        className="block w-full rounded-md border-gray-300 px-3 py-1.5 pt-2 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10 mb-12">
              <div className="flex justify-between">
                <div>
                  <button
                    type="button"
                    onClick={targetStep}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={confirmCreateContactList}
                    disabled={createContactListClicked}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Create contact list
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowTestEmailDialog(true)}
                    className="ml-8 inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Send test email
                  </button>
                  <button
                    type="button"
                    onClick={confirm}
                    disabled={sendClicked}
                    className="ml-8 inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    {isSendingMessage && (
                      <svg
                        className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Send email message
                  </button>
                </div>
              </div>
            </div>

            {/* Send success state */}
            {sendClicked && (
              <div className="flex flex-col">
                <hr className="mb-4" />
                {/* Initiate email status */}
                <div className="inline-flex items-center py-2 pl-1 text-sm leading-6 text-gray-500 transition duration-150 ease-in-out">
                  {emailInitiateSendSuccess == undefined && (
                    <svg
                      className="ml-0.5 mr-1.5 h-4 w-4 animate-spin text-rotaxDark"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {emailInitiateSendSuccess == true && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1 h-5 w-5 text-green-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  {emailInitiateSendSuccess == false && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1 h-5 w-5 text-rose-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  {emailInitiateSendSuccess == true
                    ? "Email successfully prepared"
                    : emailInitiateSendSuccess == undefined
                    ? "Preparing email & importing contacts (this can take a few minutes - do not leave this page)"
                    : "Could not prepare email"}
                </div>

                {/* Email send status */}
                {emailInitiateSendSuccess == true && (
                  <div className="inline-flex items-center py-2 pl-1 text-sm leading-6 text-gray-500 transition duration-150 ease-in-out">
                    {emailSendSuccess == undefined && (
                      <svg
                        className="ml-0.5 mr-1.5 h-4 w-4 animate-spin text-rotaxDark"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    {emailSendSuccess == true && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 h-5 w-5 text-green-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                    {emailSendSuccess == false && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 h-5 w-5 text-rose-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                    {emailSendSuccess == true
                      ? isScheduledMessage
                        ? "Email scheduled"
                        : "Email sent"
                      : emailSendSuccess == undefined
                      ? isScheduledMessage
                        ? "Scheduling email"
                        : "Sending email"
                      : isScheduledMessage
                      ? "Could not schedule email"
                      : "Could not send email"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Test email dialog */}
      {showTestEmailDialog && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white p-8 pt-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="flex justify-end">
                <XIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setShowTestEmailDialog(false)}
                />
              </div>
              <div className="sm:flex sm:items-start">
                <div className="text-centersm:text-left w-full">
                  <h3
                    className="text-lg font-medium text-gray-900"
                    id="modal-title"
                  >
                    Send a test email
                  </h3>
                  <label
                    htmlFor="title"
                    className="mt-4 block text-xs font-bold text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={newRecipients}
                    autoComplete="off"
                    autoFocus={true}
                    placeholder="Enter the name of the ranking list"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    onChange={handleRecipientsChange}
                  />
                  {/* NOTE: Currently it's only possible to send the test email to 1 recipient */}
                  {/* <p className="mt-2 text-sm text-gray-500">
                    Use comma "," to separate multiple email addresses.
                  </p> */}
                </div>
              </div>
              <div className="mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={sendTestEmail}
                  disabled={!recipientsValid || isSendingTestEmail}
                  className={classNames(
                    recipientsValid
                      ? "bg-red text-white hover:bg-yellow hover:text-rotaxDark"
                      : "bg-gray-200 opacity-60",
                    "ml-3 inline-flex items-center rounded-md border border-transparent px-8 py-2 text-sm font-medium shadow-sm  focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  )}
                >
                  {isSendingTestEmail && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Send test email
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Send confirmation dialog */}
      {showConfirm && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Send email?
                    </h3>
                    <div className="mt-2">
                      {recipientsCount !== undefined && !templateName && (
                        <p className="text-sm text-gray-500">
                          Are you sure you want to send the email? It will go
                          out to <b>{recipientsCount}</b> customers.
                        </p>
                      )}
                      {recipientsCount !== undefined && templateName && (
                        <p className="text-sm text-gray-500">
                          Are you sure you want to send the email based on the
                          template: <b>{templateName}</b>? It will go out to{" "}
                          <b>{recipientsCount}</b> customers.
                        </p>
                      )}
                      {/* On dev environemnt add info for developer/tester */}
                      {(!process.env.NODE_ENV ||
                        process.env.NODE_ENV === "development") && (
                        <p className="text-sm text-gray-500">
                          <b>Dev Info:</b> In development environment the email
                          is only sent to a maximum of 5 random email addresses.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={initiateSend}
                  className={
                    recipientsCount === undefined || recipientsCount === 0
                      ? "invisible"
                      : "inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  }
                >
                  {isSendingMessage && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Yes, send it
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirm(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  No, don't send it
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Contact list creation confirmation dialog */}
      {showConfirmCreateContactList && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Create contact list?
                    </h3>
                    <div className="mt-2">
                      {recipientsCount !== undefined && (
                        <p className="text-sm text-gray-500">
                          Are you sure you want to create a new contact list in
                          Sendinblue with <b>{recipientsCount}</b> customers.
                        </p>
                      )}
                      {/* On dev environemnt add info for developer/tester */}
                      {(!process.env.NODE_ENV ||
                        process.env.NODE_ENV === "development") && (
                        <p className="text-sm text-gray-500">
                          <b>Dev Info:</b> In development environment a maximum
                          of 5 random email addresses are added to the contact
                          list.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={createContactList}
                  className={
                    recipientsCount === undefined || recipientsCount === 0
                      ? "invisible"
                      : "inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  }
                >
                  {isCreatingContactList && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Yes, create list
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirmCreateContactList(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  No, don't create list
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Contact list successfully created dialog */}
      {showContactsCreated && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  {/* Heroicon name: outline/check */}
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Success
                  </h3>
                  <div className="mt-2 flex flex-col">
                    <p className="text-sm text-gray-500">
                      The contact list is created in Sendinblue.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => setShowContactsCreated(false)}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:text-sm"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Success dialog */}
      {showModal && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  {/* Heroicon name: outline/check */}
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Success
                  </h3>
                  <div className="mt-2 flex flex-col">
                    {!isScheduledMessage && (
                      <p className="text-sm text-gray-500">
                        The email has been sent.
                      </p>
                    )}
                    {isScheduledMessage && (
                      <p className="text-sm text-gray-500">
                        The email has been scheduled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={goToEmailMarketing}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:text-sm"
                >
                  Go back to Email Marketing
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
