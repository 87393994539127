import React from "react";

type Props = {
  title: string;
  value: string;
};

export default function InfoCard({
    title,
    value
}: Props) {
  return (
    <div className="overflow-hidden text-left rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">
        {title}
      </dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900">
        {value}
      </dd>
    </div>
  );
}
