import { ChevronRightIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../API/AuthContext";
import { LeaderBoardEntry } from "../../API/data-contracts";
import { getMetricValue } from "../../util";

type Props = {
  rank: number;
  leaderBoardEntry: LeaderBoardEntry;
  metric: string;
};

export default function LeaderBoardListEntry({
  rank,
  leaderBoardEntry,
  metric,
}: Props) {
  const { accessToken, loggedInUser } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState<boolean>();
  const navigate = useNavigate();

  const showUser = () => {
    navigate("/customer/" + leaderBoardEntry.id, {
      state: {
        passedUser: undefined,
        passedUserId: leaderBoardEntry.id,
        profileImageUrl: imageUrl,
      },
    });
  };

  useEffect(() => {
    setImageLoaded(false);
    setImageUrl(
      process.env.REACT_APP_SERVER_URL +
        "/api/sms-timing/service/profile-pic?personId=" +
        leaderBoardEntry.id +
        "&access_token=" +
        accessToken()
    );
  }, [leaderBoardEntry]);

  return (
    <tr className="text-left">
      <td
        className="cursor-pointer whitespace-nowrap px-8 py-4"
        onClick={() => showUser()}
      >
        <div className="text-sm text-gray-500">{rank}</div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-5 py-4"
        onClick={() => showUser()}
      >
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className={imageLoaded ? "h-10 w-10 rounded-full" : "invisible"}
              src={imageUrl}
              onError={() =>
                setImageUrl(
                  require("../../images/placeholder_profile.svg").default
                )
              }
              onLoad={() => setImageLoaded(true)}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {leaderBoardEntry.alias}
            </div>
          </div>

          {loggedInUser?.id == leaderBoardEntry.id && (
            <p className="m-1 text-sm text-gray-500">(You)</p>
          )}
        </div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-6 py-4"
        onClick={() => showUser()}
      >
        <div className="text-sm text-gray-500">
          {getMetricValue(metric, leaderBoardEntry)}
        </div>
      </td>
      <td className="cursor-pointer" onClick={() => showUser()}>
        <ChevronRightIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </td>
    </tr>
  );
}
