import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { RankingListLeaderBoard } from "../API/data-contracts";
import { LoadingIndicator } from "../common/LoadingIndicator";
import LeaderBoardListEntry from "../Components/UI/LeaderBoardListEntry";
import { getMetricName, rankingListLink } from "../util";

export default function RankingListDetails() {
  const { api } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [leaderBoard, setLeaderBoard] = useState<RankingListLeaderBoard>();
  const [loading, setLoading] = useState(true);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    api
      .getRankingListLeaderBoard(id)
      .then((res) => {
        setLeaderBoard(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, id]);

  const copyToClipboard = () => {
    setLinkCopied(true);
    navigator.clipboard.writeText(
      rankingListLink(leaderBoard?.rankingList.code)
    );
    setTimeout(function () {
      setLinkCopied(false);
    }, 3000);
  };

  const backToRankingLists = () => {
    navigate("/challenges", {
      state: { tabIndex: 1 },
    });
  };

  return (
    <div>
      {loading ? (
        <div className="text-text mt-32 flex flex-col items-center justify-center gap-6">
          <LoadingIndicator />
        </div>
      ) : (
        <div>
          <div
            className="text-bold my-4 cursor-pointer text-left text-sm font-medium"
            onClick={backToRankingLists}
          >
            {"< Back"}
          </div>
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                {leaderBoard?.rankingList?.title}
              </h2>
            </div>
          </div>
          <div className="mt-4 mb-4 border-b border-gray-200" />

          <div className="text-left md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <span>
                <a className="text-bold whitespace-nowrap py-4 px-1 text-sm font-medium">
                  Participants{" "}
                  <span className="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-600">
                    {leaderBoard?.rankingList.participants.length}
                  </span>
                </a>
                <span className="inline-flex justify-center">
                  <a className="text-bold whitespace-nowrap py-4 pr-1 pl-16 text-sm font-medium">
                    Link to join:
                  </a>
                  {linkCopied && (
                    <div className="font-small whitespace-nowrap py-4 text-sm">
                      Copied!
                    </div>
                  )}
                  {!linkCopied && (
                    <div className="font-small whitespace-nowrap py-4 text-sm">
                      {leaderBoard?.rankingList?.code}
                    </div>
                  )}
                  <button
                    type="button"
                    className="ml-2 inline-flex cursor-pointer items-center rounded-full text-gray-400 focus:outline-none"
                    onClick={() => copyToClipboard()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </span>
              </span>
            </div>
          </div>

          {/* Participant list */}
          <div className="mt-8 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-5 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Rank
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          User
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          {getMetricName(leaderBoard?.metric)}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          {/* empty <th> to make space for Chevron in list */}
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white">
                      {/* filter out admin-user, which is returned but doesn't have an id */}
                      {leaderBoard?.entries
                        .filter((e) => e.id)
                        .map((leaderBoardEntry, i) => {
                          return (
                            <LeaderBoardListEntry
                              key={i}
                              rank={i + 1}
                              leaderBoardEntry={leaderBoardEntry}
                              metric={leaderBoard?.metric}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
