import React from "react";
import { classNames } from "../../util";

type Props = {
  tabs: { name: string }[];
  currentTab: number;
  setCurrentTab: (index: number) => void;
};

export default function HeaderTabBar({
  tabs,
  currentTab,
  setCurrentTab,
}: Props) {
  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs.map((tab, index) => (
          <a
            key={tab.name}
            onClick={() => setCurrentTab(index)}
            className={classNames(
              index == currentTab
                ? "border-red text-red"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
              "cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            )}
            aria-current={index == currentTab ? "page" : undefined}
          >
            {tab.name}
          </a>
        ))}
      </nav>
    </div>
  );
}
