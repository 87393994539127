import React from "react";
import { classNames } from "../../util";

type Props = {
  toggled?: boolean;
  onClick?: () => void;
};

export default function ToggleButton({
  toggled = false,
  onClick = undefined,
}: Props) {
  return (
    <button
      type="button"
      id="toggle"
      onClick={onClick}
      className={classNames(
        toggled ? "bg-rotaxDark" : "bg-gray-200",
        "relative mr-3 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      )}
      role="switch"
      aria-checked="false"
    >
      <span
        aria-hidden="true"
        className={classNames(
          toggled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </button>
  );
}
