import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { User } from "../API/data-contracts";
import ToggleButton from "../Components/UI/ToggleButton";
import {
  classNames,
  getFormattedDateString,
  isSmsTimingPlatform,
  showError,
} from "../util";

type Props = {};

type LocationState = {
  passedUser: User | undefined;
  passedUserId: string;
  profileImageUrl: string;
};

export default function CustomerDetails({}: Props) {
  const MARKETING_CONSENT = "Marketing";

  const { state } = useLocation();
  const { passedUser, passedUserId, profileImageUrl } = state as LocationState;
  const { accessToken, api } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>(profileImageUrl);
  const [imageLoaded, setImageLoaded] = useState<boolean>();
  const [logins, setLogins] = useState<string>();
  const [tokenCopied, setTokenCopied] = useState<boolean>(false);
  const [copiedUserId, setCopiedUserId] = useState<string | undefined>(
    undefined
  );
  const [avatars, setAvatars] = useState(
    new Map<string, boolean>([
      ["default", false],
      ["afterrace_01", false],
    ])
  );
  const [user, setUser] = useState(passedUser);
  const [userConsents, setUserConsents] = useState<("RMD" | "Marketing")[]>([]);
  const [marketingConsentGiven, setMarketingConsentGiven] = useState(false);

  const onMarketingConsentToggleClick = () => {
    const newMarketingConsentGiven = !marketingConsentGiven;
    updateMarketingConsent(newMarketingConsentGiven);
    setMarketingConsentGiven(newMarketingConsentGiven);
  };

  //TODO: which avatars is correct?
  // const [avatars, setAvatars] = useState({
  //   default: false,
  //   afterrace_01: false,
  // });

  const userBirthday = () => {
    if (!user?.birthDay) {
      return "-";
    }
    const dateParts = user.birthDay.split("-");
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  };

  const loadAvatars = async () => {
    try {
      const response = await api.getUserAvatarDataUsingGet(passedUserId);
      const data = response.data;

      if (data) {
        const loadedAvatars = avatars;
        data.forEach((avatar) => {
          if (avatar.state != 100) {
            return;
          }
          loadedAvatars.set(avatar.template, true);
        });
        setAvatars(loadedAvatars);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadUser = async () => {
    try {
      const response = await api.getUserById(passedUserId);
      const loadedUser = response.data;
      if (loadedUser) {
        setUser(loadedUser);
        loadUserPic(loadedUser);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadUserPic = async (user: User) => {
    setImageLoaded(false);
    setImageUrl(
      process.env.REACT_APP_SERVER_URL +
        "/api/sms-timing/service/profile-pic?personId=" +
        user.smsTimingUserId +
        "&access_token=" +
        accessToken()
    );
  };

  const updateMarketingConsent = async (newMarketingConsentGiven: boolean) => {
    try {
      const newConsents = [...userConsents];

      if (newMarketingConsentGiven) {
        // if marketing consent was not previously given, add it
        if (!newConsents.find((c) => c == MARKETING_CONSENT)) {
          newConsents.push(MARKETING_CONSENT);
        }
      } else {
        // if marketing consent was previously given, remove it
        const index = newConsents.indexOf(MARKETING_CONSENT);
        if (index > -1) {
          newConsents.splice(index, 1);
        }
      }

      const response = await api.setUserConsentById(passedUserId, newConsents);
      const updatedUser = response.data;
      if (updatedUser) {
        setUserConsents(updatedUser.consent as ("RMD" | "Marketing")[] ?? []);
      }
    } catch (error) {
      showError("Could not update marketing consent.");

      // reset toggle button in case of error
      const marketingConsentGiven = userConsents.find((c) => c == MARKETING_CONSENT) != undefined;
      setMarketingConsentGiven(marketingConsentGiven);
    }
  };

  const copyToClipboard = (token: string | undefined, id: string) => {
    setTokenCopied(true);
    setCopiedUserId(id);

    if (token != undefined) {
      navigator.clipboard.writeText(token);
    }

    setTimeout(function () {
      setTokenCopied(false);
      setCopiedUserId(undefined);
    }, 3000);
  };

  const getLogins = () => {
    var logins = [];

    if (user?.appleUserId) {
      logins.push("Apple");
    }

    if (user?.googleUserId) {
      logins.push("Google");
    }

    if (user?.facebookUserId) {
      logins.push("Facebook");
    }

    if (!logins.length) {
      logins.push("Email");
    }
    setLogins(logins.join(", "));
  };

  const checkMarketingConsent = () => {
    const consents = (user?.consent as ("RMD" | "Marketing")[]) ?? [];
    setUserConsents(consents);

    // check if consent array contains "Marketing"
    const marketingConsentGiven = consents.find((c) => c == MARKETING_CONSENT) != undefined;
    setMarketingConsentGiven(marketingConsentGiven);
  };

  useEffect(() => {
    if (!user) {
      // loadUser also loads avatars and logins
      loadUser();
    } else {
      loadAvatars();
      getLogins();
      checkMarketingConsent();
    }
  }, [user]);

  return (
    <div className="overflow-hidden bg-white text-left shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Customer Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Personal details and application.
        </p>
      </div>

      {user && (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Ahoi Kapptn! user id
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
                {user.id}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                SMS-Timing user id
              </dt>
              <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
                {user.smsTimingUserId}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Full name</dt>
              <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0">
                <img
                  className={
                    imageLoaded ? "mr-3 h-10 w-10 rounded-full" : "invisible"
                  }
                  src={imageUrl}
                  onError={() =>
                    setImageUrl(
                      require("../images/placeholder_profile.svg").default
                    )
                  }
                  onLoad={() => setImageLoaded(true)}
                  alt=""
                />
                {user.firstName} {user.lastName}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Alias</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.alias}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Username</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <a href={"mailto:" + user.username}>{user.username}</a>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Birthday</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {userBirthday()}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Gender</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.gender}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Best Time</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.bestTime ?? "-"}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Avatars</dt>
              <dd className="mt-1 flex items-center space-x-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {avatars.get("default") && (
                  <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                    Default
                  </span>
                )}
                {avatars.get("afterrace_01") && (
                  <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                    After Race 01
                  </span>
                )}
                {!avatars.get("default") && !avatars.get("afterrace_01") && (
                  <span>-</span>
                )}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Platform</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span
                  className={classNames(
                    isSmsTimingPlatform(user.platform)
                      ? "bg-blue-100 px-2 text-blue-800"
                      : "bg-green-100 px-3 text-green-600",
                    "inline-flex rounded-full text-xs font-semibold leading-5"
                  )}
                >
                  {user.platform}
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Login method
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {logins}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Login code</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.smsTimingLoginCode}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Markting consent
              </dt>
              <ToggleButton
                toggled={marketingConsentGiven}
                onClick={onMarketingConsentToggleClick}
              />
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Push token</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:mt-0">
                {user.pushToken && !tokenCopied && (
                  <div className="flex-1 text-sm text-gray-500 ">
                    {user.pushToken.substring(0, 20) + "..."}
                  </div>
                )}
                {user.pushToken == undefined && (
                  <div className="flex-1 text-sm text-gray-300">No token</div>
                )}
                {tokenCopied && user.id == copiedUserId && (
                  <div className="flex-1 text-sm text-gray-400">Copied!</div>
                )}
                {user.pushToken && (
                  <button
                    type="button"
                    onClick={() => copyToClipboard(user.pushToken, user.id)}
                    className="ml-6 inline-flex items-center rounded-full p-1 text-gray-400 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                )}
              </dd>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Created</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {getFormattedDateString(user.createdDate)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Last visited
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {getFormattedDateString(user.lastVisit)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Last Modified
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {getFormattedDateString(user.lastModifiedDate)}
                  </dd>
                </div>
              </dl>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Phone number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.phone ? user.phone : "-"}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.postCode ?? "-"}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Locale</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.locale ?? "-"}
              </dd>
            </div>

            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Apple Wallet connected
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.pkPass && (
                  <span className="inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-5 text-green-600">
                    Yes
                  </span>
                )}
                {!user.pkPass && (
                  <span className="inline-flex rounded-full bg-gray-100 px-3 text-xs font-semibold leading-5 text-gray-800">
                    No
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                Google Wallet connected
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.googlePayPass && (
                  <span className="inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-5 text-green-600">
                    Yes
                  </span>
                )}
                {!user.googlePayPass && (
                  <span className="inline-flex rounded-full bg-gray-100 px-3 text-xs font-semibold leading-5 text-gray-800">
                    No
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">Full app</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.fullApp && (
                  <span className="inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-5 text-green-600">
                    Yes
                  </span>
                )}
                {!user.fullApp && (
                  <span className="inline-flex rounded-full bg-red-100 px-3 text-xs font-semibold leading-5 text-gray-800">
                    No
                  </span>
                )}
                {user.fullApp == undefined && (
                  <span className="inline-flex rounded-full bg-gray-100 px-3 text-xs font-semibold leading-5 text-green-600">
                    Unknown
                  </span>
                )}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500">
                AppClip | InstantApp
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.appClipOrInstantApp && (
                  <span className="inline-flex rounded-full bg-green-100 px-3 text-xs font-semibold leading-5 text-green-600">
                    Yes
                  </span>
                )}
                {!user.appClipOrInstantApp && (
                  <span className="inline-flex rounded-full bg-gray-100 px-3 text-xs font-semibold leading-5 text-gray-800">
                    No
                  </span>
                )}
              </dd>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
}
