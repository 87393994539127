import React, { useState } from "react";
import { motion } from "framer-motion";
import { EmailTemplate } from "../../API/data-contracts";
import { classNames, imageUrl, templateThumbnailUrl } from "../../util";
import { CheckIcon } from "@heroicons/react/solid";

type Props = {
  template: EmailTemplate;
  selected: boolean;
  selectTemplate(template: EmailTemplate): void;
};

export const EmailTemplatePage: React.FC<Props> = ({
  template,
  selected,
  selectTemplate,
}) => {
  return (
    <motion.div
      className="relative cursor-pointer"
      whileHover={{
        scale: 1.05,
        opacity: 0.9,
        transition: { duration: 0.1 },
      }}
      onClick={() => selectTemplate(template)}
    >
      <div
        className={classNames(
          "border-2",
          selected ? "border-yellow" : "border-gray-200"
        )}
      >
        <div className="relative h-52 w-full overflow-hidden bg-gray-50">
          {template.htmlContent && (
            <div
              className="absolute origin-top-left scale-27"
              dangerouslySetInnerHTML={{
                __html: template.htmlContent,
              }}
            />
          )}
          {/* put this div over html content to make sure the html links and buttons are not clickable */}
          <div className="absolute h-full w-full" />
        </div>
        {selected && (
          <span className="absolute -right-3 -top-3 flex h-7 w-7 flex-shrink-0 rounded-full bg-yellow">
            <CheckIcon className="m-1" />
          </span>
        )}
      </div>
      <p className="m-1 text-xs font-semibold text-gray-700">
        {template.name} (ID: {template.id})
      </p>
    </motion.div>
  );
};
