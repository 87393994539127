import { toast } from "react-toastify";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

export function getFormattedDateString(dateString, formatter = dateFormatter) {
  if (dateString != undefined) {
    var utcString = dateString.includes("Z") ? dateString : dateString + "Z";
    const date = Date.parse(utcString);
    return formatter.format(date);
  } else {
    return undefined;
  }
}

export function getDateString(date) {
  if (date) {
    return date.toISOString();
  } else {
    return undefined;
  }
}

export function getDateTimeString(date, time) {
  if (date) {
    var dateTime = date;
    if (time) {
      const hoursMinutes = time.split(":");
      dateTime.setHours(parseInt(hoursMinutes[0]));
      dateTime.setMinutes(parseInt(hoursMinutes[1]));
    }
    return dateTime.toISOString();
  } else {
    return undefined;
  }
}

export function dateToYYYYmmdd(date) {
  return date.toISOString().split("T")[0];
}

export function isDateInFuture(dateString) {
  if (dateString) {
    return new Date(dateString) > new Date();
  } else {
    return false;
  }
}

export function isSmsTimingPlatform(platform) {
  return (
    platform != "Android" && platform != "iOS" && platform != "WebExperience"
  );
}

var lastErrorId;

export function showError(text) {
  if (lastErrorId) {
    toast.dismiss(lastErrorId);
  }

  lastErrorId = toast.error(text, {
    position: "bottom-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "bg-white font-semibold text-sm m-1 m-1 rounded-card text-text",
  });
}

export function isValidEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export const throwEmptyContext = () => {
  throw new Error("EMPTY_CONTEXT");
};

export const inboxMessageUrl = (imageId, accessToken) => {
  return `${process.env.REACT_APP_SERVER_URL}/api/cms/inbox/image/${imageId}?access_token=${accessToken}`;
};

export const imageUrl = (imageId, accessToken) => {
  return `${process.env.REACT_APP_SERVER_URL}/api/image/${imageId}?access_token=${accessToken}`;
};

export const templateThumbnailUrl = (templateId, accessToken) => {
  return `${process.env.REACT_APP_SERVER_URL}/api/cms/marketing/templates/${templateId}/thumbnail?access_token=${accessToken}`;
};

export const rankingListLink = (code) => {
  return `${process.env.REACT_APP_BASE_DOMAIN}/ranking-list?rankingListCode=${code}`;
};

function lapTimeFormat(lapTime) {
  let mins = lapTime / 1000 / 60;
  let secs = ((lapTime / 1000) % 60).toFixed(3);
  let secsSplit = secs.toString().split(".");
  let millis = secsSplit[1];
  secs = secsSplit[0];

  if (mins > 1) {
    mins = Math.round(mins);
  } else {
    mins = 0;
  }

  if (secs < 10) {
    secs = "0" + secs;
  }

  if (millis != undefined) {
    return mins + ":" + secs + "." + millis;
  } else {
    return "Enter a number";
  }
}

export function getMetricName(metric) {
  switch (metric) {
    case "VictoryPoints":
      return "Victory Points";
    case "BestLapTime":
      return "Best Lap Time";
    case "MostVisits":
      return "Most Visits";
    case "MostPoints":
      return "Most Points";
    default:
      return undefined;
  }
}

export function getMetricValue(metric, leaderBoardEntry) {
  switch (metric) {
    case "VictoryPoints":
      return leaderBoardEntry.victoryPoints;
    case "BestLapTime":
      return lapTimeFormat(leaderBoardEntry.bestTimeMs);
    case "MostVisits":
      return leaderBoardEntry.daysVisited;
    case "MostPoints":
      return leaderBoardEntry.totalPoints;
    default:
      return undefined;
  }
}
