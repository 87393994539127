import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterCondition } from "../API/data-contracts";
import FilterSelection from "../Components/UI/FilterSelection";
import ToggleButton from "../Components/UI/ToggleButton";
import { useInboxMessage } from "../context/InboxMessageContext";
import { FilterProperty } from "../model/filter-property";
import { RMDConstants } from "../model/rmdconstants";
import { classNames } from "../util";

export default function NewInboxMessageTarget() {
  const inboxMessage = useInboxMessage();
  const navigate = useNavigate();

  const contentStep = () => {
    navigate("/messages/new");
  };

  const sendStep = () => {
    if (
      inboxMessage.sendToSingleUser &&
      (inboxMessage.usernames == undefined ||
        inboxMessage.usernames == null ||
        inboxMessage.usernames == "")
    ) {
      alert("Please enter usernames to send the message to");
    } else {
      navigate("/messages/new/send");
    }
  };

  const addNewCondition = () => {
    let filterCopy = [...inboxMessage.filterConditions];
    filterCopy.push({
      input: [],
      property: new FilterProperty(),
      selectedOperator: null,
    });
    inboxMessage.setFilterConditions(filterCopy);
  };

  const removeCondition = (index: number) => {
    if (index > 0) {
      let filterCopy = [...inboxMessage.filterConditions];
      if (index !== -1) {
        filterCopy.splice(index, 1);
        inboxMessage.setFilterConditions(filterCopy);
      }
    }
  };

  const updateCondition = (
    conditionIndex: number,
    condition: FilterCondition
  ) => {
    let filterCopy = [...inboxMessage.filterConditions];
    filterCopy[conditionIndex] = condition;
    inboxMessage.setFilterConditions(filterCopy);
  };

  const handleUsernameChange = (event: React.FormEvent<HTMLInputElement>) => {
    inboxMessage.setUsernames(event.currentTarget.value);
  };

  const handleTargetChange = (event: React.FormEvent<HTMLInputElement>) => {
    var newValue = event.currentTarget.value === "true";
    inboxMessage.setSendToSingleUser(newValue);
  };

  const handlePushTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    inboxMessage.setPushTitle(event.currentTarget.value);
  };

  const handlePushBodyChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    inboxMessage.setPushBody(event.currentTarget.value);
  };

  const togglePushSend = () => {
    inboxMessage.setPushSendRequired(!inboxMessage.pushSendRequired);
  };

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          {inboxMessage.isExistingMessage
            ? "📱 Edit App Inbox Message"
            : "📱 New App Inbox Message"}
        </h2>
      </div>

      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          <li className="relative md:flex md:flex-1">
            <a onClick={contentStep} className="group flex items-center cursor-pointer">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  {"Content & Media"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            <span className="flex items-center px-6 py-4 text-sm font-medium">
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                <span className="text-rotaxDark group-hover:text-gray-900">
                  02
                </span>
              </span>
              <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                {"Target & Push Notification"}
              </span>
            </span>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            {/* Upcoming Step */}
            <a
              onClick={sendStep}
              className="group flex cursor-pointer items-center"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                  <span className="text-gray-500 group-hover:text-gray-900">
                    03
                  </span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  Send
                </span>
              </span>
            </a>
          </li>
        </ol>
      </nav>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Target
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Select users who should receive the push notification.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="all_users"
                        name="push_notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"false"}
                        checked={!inboxMessage.sendToSingleUser}
                        onChange={handleTargetChange}
                      />
                      <label
                        htmlFor="all_users"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        All users
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="single_user"
                        name="push_notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"true"}
                        checked={inboxMessage.sendToSingleUser}
                        onChange={handleTargetChange}
                      />
                      <label
                        htmlFor="single_user"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Single user
                      </label>
                    </div>
                  </div>
                </fieldset>
                {inboxMessage.sendToSingleUser && (
                  <div>
                    <div className="pt-2">
                      <input
                        id="singleToken"
                        name="singleToken"
                        type="text"
                        onChange={handleUsernameChange}
                        value={inboxMessage.usernames}
                        placeholder="Enter username (email) of customer"
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      If you need to add more usernames, separate them with a
                      comma ",".
                    </p>
                  </div>
                )}

                {/* Filter options */}
                {!inboxMessage.sendToSingleUser && (
                  <div className="mt-4">
                    <div className="relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex items-center justify-between">
                        <span className="bg-white pr-3 text-sm text-gray-500">
                          Filter all users
                        </span>
                        <button
                          type="button"
                          onClick={addNewCondition}
                          className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                        >
                          {/* Heroicon name: solid/plus-sm */}
                          <svg
                            className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>Add filter condition</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* Filter options */}
                {!inboxMessage.sendToSingleUser && (
                  <div className="pt-4">
                    <div className="overflow-hidden rounded-md border border-gray-200 bg-white">
                      <FilterSelection
                        filterProperties={
                          RMDConstants.pushNotificationFilterProperties
                        }
                        filterConditions={inboxMessage.filterConditions}
                        removeCondition={removeCondition}
                        updateCondition={updateCondition}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Push Notification
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide if a push notification should be sent together with this
                app inbox message.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div>
                  <label
                    htmlFor="toggle"
                    className="block pb-2 text-sm font-medium text-gray-700"
                  >
                    Send push notification
                  </label>
                  <ToggleButton
                    toggled={inboxMessage.pushSendRequired}
                    onClick={togglePushSend}
                  />
                </div>

                {inboxMessage.pushSendRequired && (
                  <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                    <div className="col-span-6">
                      <label
                        htmlFor="push_title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="push_title"
                        placeholder="Enter the title of the push notification"
                        id="push_title"
                        autoComplete="off"
                        value={inboxMessage.pushTitle}
                        onChange={handlePushTitleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Body
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="push_content"
                          placeholder="Enter the content / subtitle of the push notification"
                          name="push_content"
                          rows={5}
                          value={inboxMessage.pushBody}
                          onChange={handlePushBodyChange}
                          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        ></textarea>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Max. characters are 178
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10 mb-12">
              <div className="flex justify-between">
                <div>
                  <button
                    type="button"
                    onClick={contentStep}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={sendStep}
                    disabled={
                      inboxMessage.pushSendRequired &&
                      !(inboxMessage.pushTitle && inboxMessage.pushBody)
                    }
                    className={classNames(
                      inboxMessage.pushSendRequired &&
                        !(inboxMessage.pushTitle && inboxMessage.pushBody)
                        ? "bg-gray-200 opacity-60"
                        : "bg-red text-white hover:bg-yellow hover:text-rotaxDark focus:ring-rotaxDark",
                      "inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    )}
                  >
                    Next step
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
