import React, { useEffect, useState } from "react";
import { useAuth } from "../../API/AuthContext";
import { Challenge, PageChallenge } from "../../API/data-contracts";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { classNames, showError } from "../../util";
import Pagination from "../Pagination";
import { ChallengeCard } from "./ChallengeCard";

type Props = {
  className?: string;
  editChallenge(challenge: Challenge): void;
  visibleStatus: string;
};

export const ChallengeCardGrid: React.FC<Props> = ({
  className,
  editChallenge,
  visibleStatus,
}) => {
  const { api } = useAuth();
  const [challengePages, setChallengePages] = useState<
    Map<number, PageChallenge>
  >(new Map());
  const [challenges, setChallenges] = useState<Challenge[]>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const loadChallenges = async () => {
    setLoading(true);
    // when page wasn't loaded before, load the new page
    if (!challengePages.get(currentPage)) {
      try {
        const response = await api.getChallenges({
          page: currentPage,
        });
        const challengePage = response.data;
        if (challengePage) {
          setPageCount(challengePage.totalPages ?? 0);
          setTotalElements(challengePage.totalElements ?? 0);
          setPageSize(challengePage.size ?? 0);
          setChallengePages(
            new Map(challengePages.set(currentPage, challengePage))
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of customers
    const newChallenges = challengePages.get(currentPage)?.content;
    setChallenges(newChallenges);

    setLoading(false);
  };

  useEffect(() => {
    loadChallenges();
  }, [currentPage]);

  return (
    <div className="relative top-4 mb-16 flex flex-col">
      <div
        className={classNames(
          "mb-8 grid w-full gap-8",
          challenges && challenges.length > 0
            ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4"
            : ""
        )}
      >
        {challenges && challenges.length > 0 ? (
          challenges.map((challenge, index) => {
            if (
              visibleStatus.includes("All") ||
              visibleStatus == challenge.status
            ) {
              return (
                <ChallengeCard
                  challenge={challenge}
                  editChallenge={editChallenge}
                  key={index}
                />
              );
            }
          })
        ) : loading ? (
          <div className="text-text mt-20 flex flex-col items-center justify-center gap-6">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="text-text mt-20 flex flex-col items-center justify-center gap-6">
            <p className="text-lg font-bold uppercase">
              Es wurden keine Challenges für diese Auswahl gefunden ...
            </p>
          </div>
        )}
      </div>
      <Pagination
        pageCount={pageCount}
        totalElements={totalElements}
        pageSize={pageSize}
        maxDisplayPages={8}
        onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
      />
    </div>
  );
};
