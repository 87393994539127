import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import {
  InboxMessagePayload,
  InboxStats,
  PageInboxMessagePayload,
} from "../API/data-contracts";
import Pagination from "../Components/Pagination";
import InboxMessageListEntry from "../Components/AppMessages/InboxMessageListEntry";
import SelectionTabBar from "../Components/UI/SelectionTabBar";
import { useInboxMessage } from "../context/InboxMessageContext";
import { useErrorHandler } from "../Error/ErrorContext";

const allIndex = 0;
const visibleIndex = 1;
const hiddenIndex = 2;

export default function InboxMessages() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const inboxMessage = useInboxMessage();
  const { showError } = useErrorHandler();
  const [inboxMessagePages, setInboxMessagePages] = useState<
    Map<number, PageInboxMessagePayload>
  >(new Map());
  const [inboxMessages, setInboxMessages] = useState<InboxMessagePayload[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentTab, setCurrentTab] = useState(allIndex);
  const [inboxStats, setInboxStats] = useState<InboxStats>();

  const loadInboxMessages = async () => {
    // when page wasn't loaded before, load the new page
    if (!inboxMessagePages.get(currentPage)) {
      try {
        const response = await api.getInboxPayloadsUsingGet({
          page: currentPage,
          includeTemplatePayloads: false,
          sort: "createdDate,desc",
        });
        const inboxMessagePage = response.data;
        if (inboxMessagePage) {
          setPageCount(inboxMessagePage.totalPages ?? 0);
          setPageSize(inboxMessagePage.size ?? 0);
          setInboxMessagePages(
            new Map(inboxMessagePages.set(currentPage, inboxMessagePage))
          );
        }
      } catch (error) {
        showError(error);
      }
    }

    // set the current page of inbox messages
    const newInboxMessages = inboxMessagePages.get(currentPage)?.content;
    setInboxMessages(newInboxMessages);
  };

  const loadInboxStats = async () => {
    try {
      const response = await api.getInboxStats();
      const stats = response.data;
      if (stats) {
        setInboxStats(stats);
      }
    } catch (error) {
      showError(error);
    }
  };

  const newPayload = () => {
    showNewInboxMessagePage(undefined);
  };

  const editPayload = (payload: InboxMessagePayload) => {
    showNewInboxMessagePage(payload);
  };

  const showNewInboxMessagePage = (
    payload: InboxMessagePayload | undefined
  ) => {
    inboxMessage.initContext(payload);
    navigate("/messages/new");
  };

  const toggleHide = async (payload: InboxMessagePayload) => {
    try {
      if (payload.id && payload.hidden !== undefined) {
        await api.toggleInboxHideUsingGet({
          id: payload.id,
          hidden: payload.hidden,
        });

        // if visible or hidden elements are displayed, update the list as the numbers ov visible/hidden elements has changed
        if (currentTab === hiddenIndex || currentTab === visibleIndex) {
          inboxMessagePages.clear();
          loadData();
        } else {
          loadInboxStats();
        }
      }
    } catch (error) {
      inboxMessagePages.clear();
      loadData();
      showError(error);
    }
  };

  const loadData = () => {
    loadInboxStats();
    loadInboxMessages();
  };

  useEffect(() => {
    loadData();
  }, [currentPage]);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <SelectionTabBar
            tabs={[
              { name: "All messages", value: inboxStats?.totalCount ?? 0 },
              { name: "Visible", value: inboxStats?.visibleCount ?? 0 },
              { name: "Hidden", value: inboxStats?.hiddenCount ?? 0 },
            ]}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => newPayload()}
          >
            New message
          </button>
        </div>
      </div>

      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Body
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Recipients
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Opened
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Clicks
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Sent
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Visible
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {inboxMessages?.map((inboxMessage) => {
                    if (
                      currentTab == allIndex ||
                      (currentTab == hiddenIndex && inboxMessage.hidden) ||
                      (currentTab == visibleIndex && !inboxMessage.hidden)
                    ) {
                      return (
                        <InboxMessageListEntry
                          key={inboxMessage.id}
                          payload={inboxMessage}
                          editPayload={editPayload}
                          toggleHide={toggleHide}
                          allowEdit={true}
                        />
                      );
                    }
                  })}
                </tbody>
              </table>
              <Pagination
                pageCount={pageCount}
                totalElements={inboxStats?.totalCount ?? 0}
                pageSize={pageSize}
                maxDisplayPages={8}
                onPageSelected={(pageIndex) => setCurrentPage(pageIndex)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
