import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import {
  CMSPreview,
  FilterCondition,
  PushMessageReq,
  PushPayloadReq,
} from "../API/data-contracts";
import FilterSelection from "../Components/UI/FilterSelection";
import { useErrorHandler } from "../Error/ErrorContext";
import { FilterProperty, FilterType } from "../model/filter-property";
import { RMDConstants } from "../model/rmdconstants";
import {
  classNames,
  dateToYYYYmmdd,
  getDateTimeString,
} from "../util";

export default function NewPushNotification() {
  const navigate = useNavigate();
  const { api } = useAuth();
  const { showError } = useErrorHandler();
  const [isSendingPush, setSendingPush] = useState(false);
  const [isScheduledPush, setScheduledPush] = useState(false);
  const [sendToSingleUser, setSendToSingleUser] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pushRecipientsCount, setPushRecipientsCount] = useState<
    number | undefined
  >(undefined);
  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>([
    { input: [], property: new FilterProperty(), selectedOperator: null },
  ]);
  const [pushToken, setPushToken] = useState<string | undefined>(undefined);
  const [pushTitle, setPushTitle] = useState<string>("");
  const [pushContent, setPushContent] = useState<string>("");
  const [scheduleDate, setScheduleDate] = useState<Date>();
  const [scheduleTime, setScheduleTime] = useState<string>();
  const [payload, setPayload] = useState<PushPayloadReq>();

  const confirm = useMemo(
    () => async () => {
      var tokens: string[] | undefined = undefined;
      var conditions: FilterCondition[] | undefined = undefined;
      const scheduleDateTime: string | undefined = getDateTimeString(
        scheduleDate,
        scheduleTime
      );

      if (sendToSingleUser) {
        if (pushToken) {
          tokens = [pushToken];
        }
        conditions = undefined;
      } else {
        tokens = undefined;
        conditions = filterConditions?.filter(
          (condition) =>
            condition.property && condition.property.type != FilterType.none
        );
      }

      const message: PushMessageReq = {
        title: pushTitle,
        body: pushContent,
        localized: false,
      };

      const payload: PushPayloadReq = {
        message: message,
        tokens: tokens,
        conditions: conditions,
        scheduleDate: scheduleDateTime,
      };
      setPayload(payload);
      setSendingPush(true);
      try {
        const response = await api.getPushPreviewUsingPost(payload);
        const preview = response.data as CMSPreview;
        setPushRecipientsCount(preview.recipientsPush ?? 0);
        setShowConfirm(true);
        setSendingPush(false);
      } catch (error) {
        setPayload(undefined);
        setShowConfirm(false);
        setSendingPush(false);
        showError(error);
      }
    },
    [
      pushToken,
      pushTitle,
      pushContent,
      scheduleDate,
      scheduleTime,
      api,
      showError,
      sendToSingleUser,
      filterConditions,
    ]
  );

  const send = useMemo(
    () => async () => {
      if (!isSendingPush) {
        setSendingPush(true);
        setShowConfirm(false);

        try {
          await api.sendPushUsingPost(payload!!);
          setShowModal(true);
          setSendingPush(false);
        } catch (error) {
          setShowModal(false);
          setSendingPush(false);
          showError(error);
        }
      }
    },
    [payload, api, showError, isSendingPush]
  );

  const addNewCondition = () => {
    setFilterConditions((filterConditions) => [
      ...filterConditions,
      { input: [], property: new FilterProperty(), selectedOperator: null },
    ]);
  };

  const removeCondition = (index: number) => {
    if (index > 0) {
      let filterCopy = [...filterConditions];
      if (index !== -1) {
        filterCopy.splice(index, 1);
        setFilterConditions(filterCopy);
      }
    }
  };

  const updateCondition = (
    conditionIndex: number,
    condition: FilterCondition
  ) => {
    let filterCopy = [...filterConditions];
    filterCopy[conditionIndex] = condition;
    setFilterConditions(filterCopy);
  };

  const goToPushes = () => {
    setShowModal(false);
    navigate("/notifications");
  };

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPushTitle(event.currentTarget.value);
  };

  const handleContentChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setPushContent(event.currentTarget.value);
  };

  const handlePushTokenChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPushToken(event.currentTarget.value);
  };

  const handleScheduleTimeChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    setScheduleTime(event.currentTarget.value);
  };

  const handleScheduleDateChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      setScheduleDate(new Date(dateValue));
    }
  };

  const handleTargetChange = (event: React.FormEvent<HTMLInputElement>) => {
    var newValue = event.currentTarget.value === "true";
    setSendToSingleUser(newValue);
  };

  const handleScheduleOptionChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    var newValue = event.currentTarget.value === "true";
    setScheduledPush(newValue);
  };

  let today = new Date();
  let inThreeYears = new Date();
  inThreeYears.setFullYear(inThreeYears.getFullYear() + 3);

  const minDate = dateToYYYYmmdd(today);
  const maxDate = dateToYYYYmmdd(inThreeYears);

  return (
    <div className="text-left">
      <div className="border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          New Push Notification
        </h2>
      </div>

      <form action="#" method="POST">
        <div className="pt-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Compose
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  This information will be displayed in the push notification.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Enter the title of the push notification"
                      onChange={handleTitleChange}
                      autoComplete="off"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Content
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        placeholder="Enter the content / subtitle of the push notification"
                        rows={3}
                        onChange={handleContentChange}
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      ></textarea>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Max. characters are 178
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Target
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Select users who should receive the push notification.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="all_users"
                        name="push_notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"false"}
                        checked={!sendToSingleUser}
                        onChange={handleTargetChange}
                      />
                      <label
                        htmlFor="all_users"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        All users
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="single_user"
                        name="push_notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"true"}
                        checked={sendToSingleUser}
                        onChange={handleTargetChange}
                      />
                      <label
                        htmlFor="single_user"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Single user
                      </label>
                    </div>
                  </div>
                </fieldset>
                {sendToSingleUser && (
                  <div>
                    <div className="pt-2">
                      <input
                        id="singleToken"
                        name="singleToken"
                        type="text"
                        onChange={handlePushTokenChange}
                        placeholder="Copy a user's push token here"
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Use comma "," to separate multiple push tokens.
                    </p>
                  </div>
                )}

                {/* Filter options */}
                {!sendToSingleUser && (
                  <div className="mt-4">
                    <div className="relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex items-center justify-between">
                        <span className="bg-white pr-3 text-sm text-gray-500">
                          Filter all users
                        </span>
                        <button
                          type="button"
                          onClick={addNewCondition}
                          className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                        >
                          {/* Heroicon name: solid/plus-sm */}
                          <svg
                            className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span>Add filter condition</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {/* Filter options */}
                {!sendToSingleUser && (
                  <div className="pt-4">
                    <div className="overflow-hidden rounded-md border border-gray-200 bg-white">
                      <FilterSelection
                        filterProperties={
                          RMDConstants.pushNotificationFilterProperties
                        }
                        filterConditions={filterConditions}
                        removeCondition={removeCondition}
                        updateCondition={updateCondition}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Schedule
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide when you want to send out the push notification.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="push_now"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"false"}
                        checked={!isScheduledPush}
                        onChange={handleScheduleOptionChange}
                      />
                      <label
                        htmlFor="push_everything"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Now
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push_scheduled"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value={"true"}
                        checked={isScheduledPush}
                        onChange={handleScheduleOptionChange}
                      />
                      <label
                        htmlFor="push_email"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Selected date and time
                      </label>
                    </div>
                  </div>
                </fieldset>
                {isScheduledPush && (
                  <div className="flex gap-4">
                    <div className="flex-auto">
                      <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        onChange={handleScheduleDateChange}
                        value={
                          scheduleDate
                            ? dateToYYYYmmdd(scheduleDate)
                            : undefined
                        }
                      />
                    </div>
                    <div className="flex-auto">
                      <input
                        type="time"
                        onChange={handleScheduleTimeChange}
                        value={scheduleTime}
                        className="block w-full rounded-md border-gray-300 px-3 py-1.5 pt-2 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  </div>
                )}
                {isScheduledPush && (
                  <label className="font-small ml-0 block text-sm text-gray-400">
                    Actual push delivery time may differ by up to 15 minutes.
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="flex justify-end">
          <div>
            <button
              type="button"
              onClick={confirm}
              disabled={!(pushTitle && pushContent)}
              className={classNames(
                !(pushTitle && pushContent)
                  ? "bg-gray-200 opacity-60"
                  : "bg-red text-white hover:bg-yellow hover:text-rotaxDark focus:ring-rotaxDark",
                "inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              )}
            >
              {isSendingPush && (
                <svg
                  className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Send push notification(s)
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            {showModal && (
              <div
                //   [@modal]="showModal"
                className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            )}

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            {showModal && (
              <div
                // [@modal]="showModal"
                className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    {/* Heroicon name: outline/check */}
                    <svg
                      className="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Success
                    </h3>
                    <div className="mt-2 flex flex-col">
                      <p className="text-sm text-gray-500">
                        {isScheduledPush
                          ? "The push notification has been scheduled."
                          : "The push notification has been sent out."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={goToPushes}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:text-sm"
                  >
                    Go back to Push Notifications list
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showConfirm && (
        <div
          //    [@modal]="showConfirm"
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            {showConfirm && (
              <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      {/* Heroicon name: outline/exclamation */}
                      <svg
                        className="text-tailwindYellow-400 h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Send message?
                      </h3>
                      <div className="mt-2">
                        Are you sure you want to send the push notification? The
                        push will go out to <b>{pushRecipientsCount}</b>{" "}
                        customers.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={() => send()}
                    disabled={pushRecipientsCount == undefined}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {isSendingPush && (
                      <svg
                        className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Yes, send it
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowConfirm(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    No, don't send it
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
