import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../API/AuthContext";
import { User } from "../../API/data-contracts";
import { classNames, getFormattedDateString, isSmsTimingPlatform } from "../../util";

type Props = {
  user: User;
};

export default function CustomerListEntry({ user }: Props) {
  const { accessToken, loggedInUser } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState<boolean>();
  const navigate = useNavigate();

  const showUser = () => {
    navigate("/customer/" + user.id, {
      state: {
        passedUser: user,
        passedUserId: user.id,
        profileImageUrl: imageUrl,
      },
    });
  };

  useEffect(() => {
    setImageLoaded(false);
    setImageUrl(
      process.env.REACT_APP_SERVER_URL +
        "/api/sms-timing/service/profile-pic?personId=" +
        user.smsTimingUserId +
        "&access_token=" +
        accessToken()
    );
  }, [user]);

  return (
    <tr className="text-left">
      <td
        className="cursor-pointer whitespace-nowrap px-5 py-4"
        onClick={() => showUser()}
      >
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className={imageLoaded ? "h-10 w-10 rounded-full" : "invisible"}
              src={imageUrl}
              onError={() =>
                setImageUrl(
                  require("../../images/placeholder_profile.svg").default
                )
              }
              onLoad={() => setImageLoaded(true)}
              alt=""
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {user.firstName} {user.lastName}
            </div>
          </div>

          {loggedInUser?.id == user.id && (
            <p className="m-1 text-sm text-gray-500">(You)</p>
          )}
        </div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-3 py-4"
        onClick={() => showUser()}
      >
        <div className="text-sm text-gray-500">{user.username}</div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-6 py-4"
        onClick={() => showUser()}
      >
        <div className="text-sm text-gray-500">{getFormattedDateString(user.createdDate)}</div>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-3 pb-1 text-center"
        onClick={() => showUser()}
      >
        <span
          className={classNames(
            isSmsTimingPlatform(user.platform)
              ? "bg-blue-100 px-2 text-blue-800"
              : "bg-green-100 px-3 text-green-600",
            "inline-flex rounded-full text-xs font-semibold leading-5"
          )}
        >
          {user.platform}
        </span>
      </td>
      <td
        className="cursor-pointer whitespace-nowrap px-3 py-4"
        onClick={() => showUser()}
      >
        <div className="text-sm text-gray-500">{getFormattedDateString(user.lastVisit)}</div>
      </td>
      {/* <td className="w-64 px-6 py-4 text-sm text-gray-500">
        <div className="flex items-center">
          <button
            className="ml-6 inline-flex items-center rounded-full p-1 text-gray-400 focus:outline-none"
            // onClick={() => delete(user)}
          >
            🗑️{" "}
            {user.deletionRequested && (
              <p className="text-gray-40 ml-2 text-sm">deletion scheduled</p>
            )}
          </button>
        </div>
      </td> */}
    </tr>
  );
}
