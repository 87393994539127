import React, { useState } from "react";
import { useAuth } from "../../API/AuthContext";
import { UploadedImage } from "../../API/data-contracts";
import { useInboxMessage } from "../../context/InboxMessageContext";
import { classNames, inboxMessageUrl } from "../../util";
import CarouselImage from "./CarouselImage";

export default function EditMessageContent() {
  const inboxMessage = useInboxMessage();
  const { accessToken } = useAuth();
  const [hasCoverImage, setHasCoverImage] = useState(
    inboxMessage.coverImage !== undefined
  );

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    inboxMessage.setMessageTitle(event.currentTarget.value);
  };

  const handleBodyChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    inboxMessage.setMessageBody(event.currentTarget.value);
  };

  const handleActionTitleChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    inboxMessage.setActionTitle(event.currentTarget.value);
  };

  const handleMessageUrlChange = (event: React.FormEvent<HTMLInputElement>) => {
    inboxMessage.setMessageUrl(event.currentTarget.value);
  };

  const handleMessageVideoUrlChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    inboxMessage.setMessageVideoUrl(event.currentTarget.value);
  };

  const handleCoverImageChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      handleImage(file)
        .then((image) => {
          inboxMessage.setCoverImage(image);
          setHasCoverImage(true);

          console.log("Successfully processed cover image.");
        })
        .catch((e) => {
          console.log("Failed to process cover image: " + e);
        });
    }
  };

  const handleGalleryImageChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const files = event.currentTarget.files;
      let fileCount = files.length;

      // do not upload more than 5 images
      if (fileCount + inboxMessage.galleryImages.length > 5) {
        fileCount = 5 - inboxMessage.galleryImages.length;
      }

      var promises: Promise<UploadedImage>[] = [];
      for (let i = 0; i < fileCount; i++) {
        const file = files[i];
        promises.push(handleImage(file));
      }

      Promise.all(promises)
        .then((images) => {
          let galleryImagesCopy = [...inboxMessage.galleryImages];
          galleryImagesCopy.push(...images);
          inboxMessage.setGalleryImages(galleryImagesCopy);

          console.log("Successfully processed images.", galleryImagesCopy);
        })
        .catch((e) => {
          console.log("Failed to process images: " + e);
        });
    }
  };

  function handleImage(file: File): Promise<UploadedImage> {
    var promise = new Promise<UploadedImage>((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var base64 = reader.result; //reader.result;

        var image: UploadedImage = { file: file, src: base64 };
        resolve(image);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
        reject();
      };
      reader.readAsDataURL(file);
    });

    return promise;
  }

  const removeImage = (index: number) => {
    // prep image id array that will be used to delete the images in the backend
    const imageIdToRemove = inboxMessage.galleryImages[index].id;
    if (imageIdToRemove) {
      let filterCopy = [...inboxMessage.imageIdsToDelete];
      filterCopy.push(imageIdToRemove);
      inboxMessage.setImageIdsToDelete(filterCopy);
    }

    // remove actual images
    let galleryImagesCopy = [...inboxMessage.galleryImages];
    if (index !== -1) {
      galleryImagesCopy.splice(index, 1);
      inboxMessage.setGalleryImages(galleryImagesCopy);
    }
  };

  const coverImageSrc =
    inboxMessage.coverImage?.src ??
    inboxMessageUrl(inboxMessage.coverImage?.id, accessToken());

  // update src of cover image if it doesn't have one yet
  if (inboxMessage.coverImage?.src === undefined) {
    inboxMessage.setCoverImage({
      id: inboxMessage.coverImage?.id,
      src: coverImageSrc,
    });
  }

  return (
    <div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Content
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                This is the main content of the inbox message.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                {!hasCoverImage && (
                  <div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="cover_photo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Cover photo
                      </label>
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="flex justify-center text-sm text-gray-600">
                            <label
                              htmlFor="cover-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-rotaxDark hover:text-rotaxDark-light"
                            >
                              <span>Upload a cover photo</span>
                              <input
                                id="cover-upload"
                                name="cover-upload"
                                type="file"
                                className="sr-only"
                                accept=".jpg,.jpeg,.png"
                                onChange={handleCoverImageChange}
                              />
                            </label>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG or JPG up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {hasCoverImage && (
                  <div className="flex justify-center">
                    <img
                      className="max-h-64 object-contain"
                      id="image-overlayed"
                      src={coverImageSrc}
                      onError={() => {
                        setHasCoverImage(false);
                      }}
                    />
                  </div>
                )}

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title*
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="off"
                      value={inboxMessage.messageTitle}
                      onChange={handleTitleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Message*
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={10}
                      value={inboxMessage.messageBody}
                      onChange={handleBodyChange}
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <legend className="text-base font-medium text-gray-900">
                    Call to Action (CTA)
                  </legend>
                  <p className="text-sm text-gray-500">
                    Define the call to action button at the bottom of the
                    message.
                  </p>
                  <div className="mt-2 grid w-full grid-cols-2 gap-8">
                    <div className="col-span-1 lg:col-span-1">
                      <label
                        htmlFor="ctaTitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Button Title
                      </label>
                      <input
                        type="text"
                        name="ctaTitle"
                        id="ctaTitle"
                        value={inboxMessage.actionTitle}
                        onChange={handleActionTitleChange}
                        autoComplete="off"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                      <div className="mt-2" />
                      <label
                        htmlFor="company_website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Button Link
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                          https://
                        </span>
                        <input
                          type="text"
                          name="cta-link"
                          id="cta-link"
                          value={inboxMessage.messageUrl}
                          onChange={handleMessageUrlChange}
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                          placeholder="www.example.com"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-1 flex flex-col">
                    <label className="mb-2 block text-sm font-medium text-gray-700">
                      Preview
                    </label>
                    <div className="relative">
                      <img
                        src={require("../../images/dynamic-button.svg").default}
                        className="h-14 w-full"
                      />
                      <div className="absolute top-0 flex h-full w-full items-center justify-center">
                        <h3 className="text-lg font-semibold">
                          {inboxMessage.actionTitle}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Media
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Enrich the inbox message with media content. You can either post
                a video link or upload up to 5 photos.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div>
                    <legend className="text-base font-medium text-gray-900">
                      Video
                    </legend>
                    <p className="text-sm text-gray-500">
                      You can add a video to the message. Copy the Youtube link
                      into the field below.
                    </p>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                        https://
                      </span>
                      <input
                        type="text"
                        name="company_website"
                        id="company_website"
                        value={inboxMessage.messageVideoUrl}
                        onChange={handleMessageVideoUrlChange}
                        className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        placeholder="Link from Youtube"
                      />
                    </div>
                  </div>

                  <div>
                    <legend className="mt-6 text-base font-medium text-gray-900">
                      Photo Gallery
                    </legend>
                    <p className="text-sm text-gray-500">
                      Add up to 5 photos to the message. Photos will be
                      displayed in a carousel.
                    </p>
                    <div className="flex">
                      {inboxMessage.galleryImages.length < 5 && (
                        <div className="flex w-32 flex-shrink-0">
                          <div
                            className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6
                                pt-5 pb-6"
                          >
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <div className="flex justify-center text-sm text-gray-600">
                                <label
                                  htmlFor="photos-upload"
                                  className="cursor-pointer rounded-md bg-white font-medium text-rotaxDark focus-within:outline-none focus-within:ring-2 focus-within:ring-rotaxDark focus-within:ring-offset-2 hover:text-rotaxDark-light"
                                >
                                  <span>Upload a photo</span>
                                  <input
                                    id="photos-upload"
                                    name="carousel-images"
                                    type="file"
                                    multiple={true}
                                    onChange={handleGalleryImageChange}
                                    accept=".png,.jpg,.jpeg"
                                    className="sr-only"
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">PNG, JPG</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className={classNames(
                          inboxMessage.galleryImages.length < 5 ? "pl-3" : "",
                          "grid grid-cols-5 gap-4 pt-2"
                        )}
                      >
                        {inboxMessage.galleryImages?.map((image, index) => {
                          return (
                            <CarouselImage
                              image={image}
                              index={index}
                              key={index}
                              removeImage={removeImage}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
