import React, { useEffect, useState } from "react";
import { classNames } from "../util";

type Props = {
  pageCount: number;
  totalElements: number;
  pageSize: number;
  maxDisplayPages: number;
  onPageSelected?: (pageIndex: number) => void;
};

export default function Pagination({
  pageCount,
  totalElements,
  pageSize,
  maxDisplayPages,
  onPageSelected,
}: Props) {
  const [pages, setPages] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState(0);

  const maxCount = () => {
    let maxPageResults = pageSize * (pageIndex + 1);
    return maxPageResults < totalElements ? maxPageResults : totalElements;
  };

  const showStartDots = () => {
    if (pages.length > 0) {
      return pages[0] != 2;
    } else {
      return false;
    }
  };

  const showEndDots = () => {
    if (pages.length > 0) {
      return pages[pages.length - 1] != pageCount - 1;
    } else {
      return false;
    }
  };

  const calculatePageNumbers = () => {
    if (pageCount > 1) {
      if (
        pages.length == 0 ||
        Math.abs(pageIndex + 1 - pages[Math.floor(pages.length / 2)]) >=
          Math.floor(maxDisplayPages / 4)
      ) {
        const innerPageCount = Math.min(maxDisplayPages, pageCount - 2); // -2 for always showing first and last
        setPages(
          Array(innerPageCount)
            .fill(0, 0, innerPageCount)
            .map(
              (x, i) =>
                Math.max(
                  Math.min(
                    pageIndex - Math.floor(maxDisplayPages / 2),
                    pageCount - innerPageCount
                  ),
                  2
                ) + i
            )
        );
      }
    }
  };

  const prevClicked = () => {
    selectPage(Math.max(0, pageIndex - 1));
  };

  const nextClicked = () => {
    selectPage(Math.min(pageCount - 1, pageIndex + 1));
  };

  const selectPage = (page: number) => {
    if (pageIndex != page) {
      setPageIndex(page);
      if (onPageSelected) {
        onPageSelected(page);
      }
    }
  };

  useEffect(() => {
    calculatePageNumbers();
  }, [pageIndex, pageCount]);

  return (
    <div>
      {pageCount > 1 && (
        <div className="flex justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{pageSize * pageIndex + 1}</span>{" "}
                to <span className="font-medium">{maxCount()}</span> of{" "}
                <span className="font-medium">{totalElements}</span> results
              </p>
            </div>
            <div className="flex">
              <nav
                className="z-0 inline-flex -space-x-px shadow-sm"
                aria-label="Pagination"
              >
                {/* <p className="inline-flex items-center px-6 text-sm">{{ totalElements }} total users </p> */}
                <button
                  onClick={() => prevClicked()}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40"
                  disabled={pageIndex == 0}
                >
                  <span className="sr-only">Previous</span>
                  {/* Heroicon name: chevron-left */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <a
                  onClick={() => selectPage(0)}
                  className={classNames(
                    pageIndex == 0 ? "bg-accent font-extrabold" : "",
                    "relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  )}
                >
                  1
                </a>
                {showStartDots() && (
                  <a className="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                    ...
                  </a>
                )}
                <div>
                  {pages.map((page, i) => {
                    return (
                      <a
                        key={i}
                        onClick={() => selectPage(page - 1)}
                        className={classNames(
                          pageIndex == page - 1
                            ? "bg-accent font-extrabold"
                            : "",
                          "relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        )}
                      >
                        {page}
                      </a>
                    );
                  })}
                </div>
                {showEndDots() && (
                  <a className="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                    ...
                  </a>
                )}
                <a
                  onClick={() => selectPage(pageCount - 1)}
                  className={classNames(
                    pageIndex == pageCount - 1
                      ? "bg-accent font-extrabold"
                      : "",
                    "relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  )}
                >
                  {pageCount}
                </a>
                <button
                  onClick={() => nextClicked()}
                  disabled={pageIndex >= pageCount - 1}
                  className="relative inline-flex cursor-pointer items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-40"
                >
                  <span className="sr-only">Next</span>
                  {/* Heroicon name: chevron-right */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
