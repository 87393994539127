import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { ReactComponent as MaxDomeLogo } from "../images/logo.svg";
import { ReactComponent as HomeIcon } from "../images/house.svg";
import { ReactComponent as UsersIcon } from "../images/people.svg";
import { ReactComponent as BellIcon } from "../images/bell.svg";
import { ReactComponent as DeviceMobileIcon } from "../images/phone.svg";
import { ReactComponent as EmailIcon } from "../images/email.svg";
import { ReactComponent as CupIcon } from "../images/cup.svg";

import { classNames } from "../util";

const navigation = [
  {
    name: "menu.dashboard",
    href: "/dashboard",
    icon: <HomeIcon />,
    current: false,
  },
  {
    name: "menu.customers",
    href: "/customers",
    icon: <UsersIcon />,
    current: false,
  },
  {
    name: "menu.pushNotifications",
    href: "/notifications",
    icon: <BellIcon />,
    current: false,
  },
  {
    name: "menu.appInboxMessages",
    href: "/messages",
    icon: <DeviceMobileIcon />,
    current: false,
  },
  {
    name: "menu.emailMarketing",
    href: "/emails",
    icon: <EmailIcon />,
    current: false,
  },
  {
    name: "menu.challenges",
    href: "/challenges",
    icon: <CupIcon />,
    current: false,
  },
];

type Props = {};

const SidebarLayout: React.FC<Props> = ({ children }) => {
  const { loggedIn } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  navigation.forEach((n) => (n.current = location.pathname === n.href));

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <MaxDomeLogo />
                </div>
                <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto">
                  <nav className="flex-1 space-y-1 px-2">
                    {navigation.map((item) => (
                      <SidebarElement key={item.name} {...item} />
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="flex h-screen overflow-hidden bg-gray-100">
          {loggedIn && (
            <div className="hidden md:flex md:flex-shrink-0">
              <div className="flex w-64 flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex h-0 flex-1 flex-col">
                  <div className="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4">
                    <div className="h-8 w-auto">
                      <MaxDomeLogo />
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col overflow-y-auto">
                    <nav className="flex-1 space-y-1 bg-gray-800 px-2 py-4">
                      {navigation.map((item) => (
                        <SidebarElement key={item.name} {...item} />
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex w-0 flex-1 flex-col overflow-hidden">
            <main className="relative flex-1 overflow-y-auto focus:outline-none">
              <div className="py-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-6">
                  {children ? children : <Outlet />}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

type ElementProps = {
  name: string;
  href: string;
  icon: JSX.Element;
  current: boolean;
};

function SidebarElement(item: ElementProps) {
  const { t } = useTranslation();

  return (
    <Link
      to={item.href}
      className={classNames(
        item.current ? "bg-gray-900" : "bg-gray-800 hover:bg-gray-700",
        "group flex items-center rounded-md px-2 py-2 text-sm font-medium text-white no-underline"
      )}
    >
      <div
        className={"my-1 flex h-5 w-7 justify-center mr-3"}
        aria-hidden="true"
      >
        {item.icon}
      </div>
      {t(item.name)}
    </Link>
  );
}

export default SidebarLayout;
