export function convertMapToRecord(
  map: Map<string, string>
): Record<string, string> {
  let newObject: Record<string, string> = {};
  map.forEach((value, key) => {
    newObject[key] = value;
  });
  return newObject;
}

export function convertRecordToMap(
  record: Record<string, string>
): Map<string, string> {
  let newObject: Map<string, string> = new Map();
  entries(record).forEach(([key, value]) => {
    newObject.set(key, value);
  })
  return newObject;
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

function entries<Obj extends Record<string, unknown>>(obj: Obj): Entries<Obj> {
  return Object.entries(obj) as Entries<Obj>;
}

export function fixHtmlIssues(map: Map<string, string>): Map<string, string> {
  let newObject: Map<string, string> = new Map();
  // fix issue that text editor uses class="ql-align-XX for alignment whereas standard html uses align="XX"
  map.forEach((value, key) => {
    newObject.set(
      key,
      value
        .replace('class="ql-align-right"', 'align="right"')
        .replace('class="ql-align-center"', 'align="center"')
        .replace('class="ql-align-left"', 'align="left"')
    );
  });
  return newObject;
}
