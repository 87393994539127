export class FilterOperator {
    type: OperatorType

    constructor(type: OperatorType) {
        this.type = type
    }

    get name(): string {
        switch (this.type) {
            case OperatorType.greaterThan:
                return "Greater than"
            case OperatorType.lessThan:
                return "Less than"
            case OperatorType.range:
                return "Is between"
            case OperatorType.multiSelect:
                return "Is one of"
            case OperatorType.picker:
                return "Is"
            default:
                return "Define operator name"
        }
    }
}

export enum OperatorType {
    multiSelect = "multiSelect",
    picker = "picker",
    greaterThan = "greaterThan",
    lessThan = "lessThan",
    range = "range"
}

export class MultiSelectOperator extends FilterOperator {
    options: any[]

    constructor(options: any[]) {
        super(OperatorType.multiSelect)
        this.options = options
    }
}