import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { Challenge, ChallengeStats } from "../API/data-contracts";
import { AnimatedOpacityDiv } from "../common/AnimatedOpacityDiv";
import { ChallengeCardGrid } from "../Components/Challenges/ChallengeCardGrid";
import SelectionTabBar from "../Components/UI/SelectionTabBar";
import { showError } from "../util";

const allIndex = 0;
const runningIndex = 0;
const upcomingIndex = 1;
const completedIndex = 2;

export default function RotaxChallenges() {
  const navigate = useNavigate();
  const { api } = useAuth();
  const [currentTab, setCurrentTab] = useState(allIndex);
  const [challengeStats, setChallengeStats] = useState<ChallengeStats>();

  const loadChallengeStats = async () => {
    try {
      const response = await api.getChallengeStats();
      const stats = response.data;
      if (stats) {
        setChallengeStats(stats);
      }
    } catch (error) {
      showError(error);
    }
  };

  const newChallenge = () => {
    navigate("/challenges/new", {
      state: { editChallenge: undefined },
    });
  };

  const editChallenge = (challenge: Challenge) => {
    navigate("/challenge/" + challenge.id, {
      state: { challenge: challenge },
    });
  };

  useEffect(() => {
    loadChallengeStats();
  }, []);

  const tabs = [
    { name: "All Challenges", value: challengeStats?.total ?? 0 },
    { name: "Running", value: challengeStats?.running ?? 0 },
    { name: "Upcoming", value: challengeStats?.upcoming ?? 0 },
    { name: "Completed", value: challengeStats?.completed ?? 0 },
  ]

  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <SelectionTabBar
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-red px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => newChallenge()}
          >
            Create new challenge
          </button>
        </div>
      </div>
      <AnimatedOpacityDiv key={"page_overview_0"}>
        <ChallengeCardGrid editChallenge={editChallenge} visibleStatus={tabs[currentTab].name} />
      </AnimatedOpacityDiv>
    </div>
  );
}