
export enum ServerErrors {
  ValidationFailed = "ValidationFailed",
  ErrorSendingEmail = "ErrorSendingEmail",
  UploadSizeTooLarge = "UploadSizeTooLarge",
  UserAlreadyExists = "UserAlreadyExists",
  ErrorUpdatingData = "ErrorUpdatingData",
  ErrorStoringData = "ErrorStoringData",
  Expired = "Expired",
  NotFound = "NotFound",
  BadRequest = "BadRequest",
  InvalidData = "InvalidData",
  MissingData = "MissingData",
  ErrorEncodingVideo = "ErrorEncodingVideo",
  BadMediaType = "BadMediaType",
  AccessDenied = "AccessDenied",
  WrongPassword = "WrongPassword",
  WrongUsername = "WrongUsername",
  Unauthorized = "Unauthorized"
}

export function getErrorMessageFromResponse(response: any): string {
  return getErrorMessageKey(response?.error?.code ?? "");
}

export function getErrorMessageKey(error: any): string {
  switch (error) {
    case ServerErrors.ValidationFailed:
      return "error.passwordMinChars";
    case ServerErrors.ErrorSendingEmail:
      return "error.sendEmailError";
    case ServerErrors.UploadSizeTooLarge:
      return "error.uploadSizeTooLarge";
    case ServerErrors.UserAlreadyExists:
      return "error.userAlreadyExists";
    case ServerErrors.ErrorUpdatingData:
      return "error.errorUpdatingData";
    case ServerErrors.ErrorStoringData:
      return "error.errorStoringData";
    case ServerErrors.Expired:
      return "error.expired";
    case ServerErrors.NotFound:
      return "error.notFound";
    case ServerErrors.BadRequest:
      return "error.badRequest";
    case ServerErrors.InvalidData:
      return "error.invalidData";
    case ServerErrors.MissingData:
      return "error.missingData";
    case ServerErrors.ErrorEncodingVideo:
      return "error.errorEncodingVideo";
    case ServerErrors.BadMediaType:
      return "error.badMediaType";
    case ServerErrors.AccessDenied:
      return "error.accessDenied";
    case ServerErrors.WrongPassword:
      return "error.wrongPassword";
    case ServerErrors.WrongUsername:
      return "error.wrongUsername";
    case ServerErrors.Unauthorized:
      return "error.unauthorized";
    default:
      return "error.generic";
  }
}
