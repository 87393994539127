import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";
import { CMSPreview } from "../API/data-contracts";
import { useInboxMessage } from "../context/InboxMessageContext";
import { dateToYYYYmmdd, showError } from "../util";

export default function NewInboxMessageSend() {
  const { api } = useAuth();
  const navigate = useNavigate();
  const inboxMessage = useInboxMessage();

  const [showConfirm, setShowConfirm] = useState(false);
  const [isSendingMessage, setSendingMessage] = useState(false);
  const [sendClicked, setSendClicked] = useState(false);
  const [isScheduledMessage, setScheduledMessage] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isExpiryMessage, setExpiryMessage] = useState(false);
  const [pushSuccess, setPushSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [inboxSuccess, setInboxSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [pushRecipientsCount, setPushRecipientsCount] = useState<
    number | undefined
  >(undefined);
  const [inboxRecipientsCount, setInboxRecipientsCount] = useState<
    number | undefined
  >(undefined);

  const confirm = useMemo(
    () => async () => {
      setSendingMessage(true);

      // Confirm when new message or existing message requests push
      if (!inboxMessage.isExistingMessage || inboxMessage.pushSendRequired) {
        try {
          const messagePayload = inboxMessage.getMessagePayload();
          if (messagePayload) {
            const response = await api.getInboxPreviewUsingPost(messagePayload);
            const preview = response.data as CMSPreview;
            setShowConfirm(true);
            setSendingMessage(false);
            setPushRecipientsCount(preview.recipientsPush ?? 0);
            setInboxRecipientsCount(preview.recipientsInbox ?? 0);
          }
        } catch (error) {
          setShowConfirm(false);
          setSendingMessage(false);
          showError(error);
        }
      } else {
        setSendingMessage(false);
        send();
      }
    },
    [
      setSendingMessage,
      setShowConfirm,
      setPushRecipientsCount,
      setInboxRecipientsCount,
      api,
      inboxMessage,
    ]
  );

  const send = useMemo(
    () => async () => {
      if (!isSendingMessage) {
        setShowConfirm(false);
        setInboxSuccess(undefined);
        setPushSuccess(undefined);
        setSendClicked(true);
        setSendingMessage(true);

        const messagePayload = inboxMessage.getMessagePayload();
        if (messagePayload) {
          try {
            let responsePayload = undefined;
            if (inboxMessage.isExistingMessage) {
              const respone = await api.updateInboxMessage(messagePayload);
              responsePayload = respone.data;
              setSaved(true);
            } else {
              const respone = await api.createInboxMessage(messagePayload);
              responsePayload = respone.data;
              setInboxSuccess(true);
            }

            // upload images
            if (
              responsePayload.id &&
              (inboxMessage.coverImage || inboxMessage.galleryImages.length > 0)
            ) {
              await updateImages(responsePayload.id);
            }

            if (inboxMessage.pushSendRequired) {
              push();
            } else {
              setSendingMessage(false);
              setShowModal(true);
              inboxMessage.clearContext();
            }
          } catch (error) {
            setShowConfirm(false);
            setSendingMessage(false);
            setInboxSuccess(false);
            setPushSuccess(false);
            setSendClicked(false);
            setShowModal(false);
            showError(error);
          }
        }
      }
    },
    [
      setShowConfirm,
      setInboxSuccess,
      setPushSuccess,
      setSendingMessage,
      inboxMessage,
      api,
    ]
  );

  const updateImages = useMemo(
    () => async (messageId: string) => {
      // upload new images
      const images = [];
      if (inboxMessage.coverImage) images.push(inboxMessage.coverImage);
      images.push(...inboxMessage.galleryImages);

      // Upload new images
      for (let i = 0; i < images.length; i++) {
        if (images[i].file) {
          await api
            .addInboxMessageImage(messageId, {
              image: images[i].file,
            })
            .catch((error) => showError(error.toString()));
        }
      }

      // Delete images
      if (inboxMessage.imageIdsToDelete.length > 0) {
        await api
          .deleteInboxMessageImages(messageId, {
            ids: inboxMessage.imageIdsToDelete,
          })
          .catch((error) => showError(error.toString()));
      }
    },
    [
      setShowConfirm,
      setInboxSuccess,
      setPushSuccess,
      setSendingMessage,
      inboxMessage,
      api,
    ]
  );

  const push = useMemo(
    () => async () => {
      try {
        const pushPayload = inboxMessage.getPushPayload();
        if (pushPayload) {
          const response = await api.sendPushUsingPost(pushPayload);
          setSendingMessage(false);
          setPushSuccess(true);
          setShowModal(true);
          inboxMessage.clearContext();
        }
      } catch (error) {
        setSendingMessage(false);
        setPushSuccess(false);
        setSendClicked(false);
      }
    },
    [setSendingMessage, setPushSuccess, setShowModal, inboxMessage, api]
  );

  const goToMessages = () => {
    setShowModal(false);
    navigate("/messages");
  };

  const contentStep = () => {
    navigate("/messages/new");
  };

  const targetStep = () => {
    navigate("/messages/new/target");
  };

  const handleScheduledChange = (event: React.FormEvent<HTMLInputElement>) => {
    var newValue = event.currentTarget.value === "true";
    setScheduledMessage(newValue);
  };

  const handleExpireyChange = (event: React.FormEvent<HTMLInputElement>) => {
    var newValue = event.currentTarget.value === "true";
    setExpiryMessage(newValue);
  };

  const handleScheduleTimeChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    inboxMessage.setScheduleTime(event.currentTarget.value);
  };

  const handleScheduleDateChange = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      inboxMessage.setScheduleDate(new Date(dateValue));
    }
  };

  const handleExpiryTimeChange = (event: React.FormEvent<HTMLInputElement>) => {
    inboxMessage.setExpiryTime(event.currentTarget.value);
  };

  const handleExpiryDateChange = (event: React.FormEvent<HTMLInputElement>) => {
    let dateValue = event.currentTarget.value;
    if (dateValue) {
      inboxMessage.setExpiryDate(new Date(dateValue));
    }
  };

  let today = new Date();
  let inThreeYears = new Date();
  inThreeYears.setFullYear(inThreeYears.getFullYear() + 3);

  const minDate = dateToYYYYmmdd(today);
  const maxDate = dateToYYYYmmdd(inThreeYears);

  return (
    <div className="text-left">
      <div className="mb-8 border-b border-gray-200 pb-5">
        <h2 className="text-xl font-bold leading-6 text-gray-900">
          {inboxMessage.isExistingMessage
            ? "📱 Edit App Inbox Message"
            : "📱 New App Inbox Message"}
        </h2>
      </div>

      <nav aria-label="Progress" className="mb-12">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          <li className="relative md:flex md:flex-1">
            <a onClick={contentStep} className="group flex items-center cursor-pointer">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  {"Content & Media"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            <a
              onClick={targetStep}
              className="group flex cursor-pointer items-center"
            >
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                  {"Target & Push Notification"}
                </span>
              </span>
            </a>

            {/* Arrow separator for lg screens and up */}
            <div
              className="absolute top-0 right-0 hidden h-full w-5 md:block"
              aria-hidden="true"
            >
              <svg
                className="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>

          <li className="relative md:flex md:flex-1">
            {showModal && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-yellow group-hover:bg-rotaxDark">
                  {/* Heroicon name: solid/check */}
                  <svg
                    className="h-6 w-6 text-rotaxDark group-hover:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark group-hover:text-gray-900">
                  Send
                </span>
              </span>
            )}

            {!showModal && (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow">
                  <span className="text-rotaxDark">03</span>
                </span>
                <span className="ml-4 text-sm font-medium text-rotaxDark">
                  Send
                </span>
              </span>
            )}
          </li>
        </ol>
      </nav>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Schedule
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide when you want to send out the app inbox message.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="msg_now"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="false"
                        checked={!isScheduledMessage}
                        onChange={handleScheduledChange}
                      />
                      <label
                        htmlFor="msg_now"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Now
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="msg_scheduled"
                        name="push_schedule"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="true"
                        checked={isScheduledMessage}
                        onChange={handleScheduledChange}
                      />
                      <label
                        htmlFor="msg_scheduled"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Selected date and time
                      </label>
                    </div>
                  </div>
                </fieldset>
                {isScheduledMessage && inboxMessage.pushSendRequired && (
                  <label className="font-small ml-0 block text-sm text-gray-400">
                    Actual push delivery time may differ by up to 15 minutes
                  </label>
                )}
                {isScheduledMessage && (
                  <div className="flex gap-4">
                    <div className="flex-auto">
                      <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        onChange={handleScheduleDateChange}
                        value={
                          inboxMessage.scheduleDate
                            ? dateToYYYYmmdd(inboxMessage.scheduleDate)
                            : undefined
                        }
                      />
                    </div>
                    <div className="flex-auto">
                      <input
                        type="time"
                        onChange={handleScheduleTimeChange}
                        value={inboxMessage.scheduleTime}
                        className="block w-full rounded-md border-gray-300 px-3 py-1.5 pt-2 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Expire
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Decide when you want to the app inbox message to expire and be
                no longer visible.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="expiry_now"
                        name="payload_expiry"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="false"
                        checked={!isExpiryMessage}
                        onChange={handleExpireyChange}
                      />
                      <label
                        htmlFor="expiry_now"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Never
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="expiry_scheduled"
                        name="payload_expiry"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-rotaxDark focus:ring-rotaxDark"
                        value="true"
                        checked={isExpiryMessage}
                        onChange={handleExpireyChange}
                      />
                      <label
                        htmlFor="expiry_scheduled"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Selected date and time
                      </label>
                    </div>
                  </div>
                </fieldset>
                {isExpiryMessage && (
                  <div className="flex gap-4">
                    <div className="flex-auto">
                      <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                        onChange={handleExpiryDateChange}
                        value={
                          inboxMessage.expiryDate
                            ? dateToYYYYmmdd(inboxMessage.expiryDate)
                            : undefined
                        }
                      />
                    </div>
                    <div className="flex-auto">
                      <input
                        type="time"
                        onChange={handleExpiryTimeChange}
                        value={inboxMessage.expiryTime}
                        className="block w-full rounded-md border-gray-300 px-3 py-1.5 pt-2 shadow-sm focus:border-rotaxDark focus:ring-rotaxDark sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10 mb-12">
              <div className="flex justify-between">
                <div>
                  <button
                    type="button"
                    onClick={targetStep}
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={confirm}
                    disabled={sendClicked}
                    className="inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
                  >
                    {isSendingMessage && (
                      <svg
                        className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    {inboxMessage.isExistingMessage ? "Save" : "Send"} inbox
                    message
                  </button>
                </div>
              </div>
            </div>

            {/* Send success state */}
            {sendClicked && (
              <div className="flex flex-col">
                <hr className="mb-4" />
                {/* Inbox message status */}
                <div className="inline-flex items-center py-2 pl-1 text-sm leading-6 text-gray-500 transition duration-150 ease-in-out">
                  {inboxSuccess == undefined && (
                    <svg
                      className="ml-0.5 mr-1.5 h-4 w-4 animate-spin text-rotaxDark"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {inboxSuccess == true && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1 h-5 w-5 text-green-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  {inboxSuccess == false && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1 h-5 w-5 text-rose-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  {inboxSuccess == true
                    ? "App inbox message sent"
                    : inboxSuccess == undefined
                    ? "Sending app inbox message"
                    : "Could not send app inbox message"}
                </div>

                {/* Push message status */}
                {inboxSuccess == true && inboxMessage.pushSendRequired && (
                  <div className="inline-flex items-center py-2 pl-1 text-sm leading-6 text-gray-500 transition duration-150 ease-in-out">
                    {pushSuccess == undefined && (
                      <svg
                        className="ml-0.5 mr-1.5 h-4 w-4 animate-spin text-rotaxDark"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    {pushSuccess == true && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 h-5 w-5 text-green-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                    {pushSuccess == false && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1 h-5 w-5 text-rose-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    )}
                    {pushSuccess == true
                      ? "Push notification message sent"
                      : pushSuccess == undefined
                      ? "Sending push notificaton"
                      : "Could not send push notification"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* This example requires Tailwind CSS v2.0+ */}
      {showModal && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  {/* Heroicon name: outline/check */}
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Success
                  </h3>
                  <div className="mt-2 flex flex-col">
                    {isSaved && (
                      <p className="text-sm text-gray-500">
                        The app inbox message has been saved.
                      </p>
                    )}
                    {!isSaved && isScheduledMessage && (
                      <p className="text-sm text-gray-500">
                        The app inbox message has been scheduled.
                      </p>
                    )}
                    {!isSaved && !isScheduledMessage && (
                      <p className="text-sm text-gray-500">
                        The app inbox message has been sent out to app users and
                        they can now see it in the app.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={goToMessages}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:text-sm"
                >
                  Go back to App Inbox Messages
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* This example requires Tailwind CSS v2.0+ */}
      {showConfirm && (
        <div
          className="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="bg-tailwindYellow-100 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    {/* Heroicon name: outline/exclamation */}
                    <svg
                      className="text-tailwindYellow-400 h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Send message?
                    </h3>
                    <div className="mt-2">
                      {inboxMessage.isExistingMessage &&
                        pushRecipientsCount &&
                        inboxMessage.pushSendRequired && (
                          <p className="text-sm text-gray-500">
                            Are you sure you want to save the inbox app message?
                            The push will go out to <b>{pushRecipientsCount}</b>{" "}
                            customers.
                          </p>
                        )}
                      {!inboxMessage.isExistingMessage &&
                        inboxRecipientsCount &&
                        pushRecipientsCount &&
                        inboxMessage.pushSendRequired && (
                          <p className="text-sm text-gray-500">
                            Are you sure you want to send the inbox app message?
                            The inbox message will go out to{" "}
                            <b>{inboxRecipientsCount}</b> customers. The push
                            will go out to <b>{pushRecipientsCount}</b>{" "}
                            customers.
                          </p>
                        )}
                      {!inboxMessage.isExistingMessage &&
                        inboxRecipientsCount &&
                        !inboxMessage.pushSendRequired && (
                          <p className="text-sm text-gray-500">
                            Are you sure you want to send the inbox app message?
                            The inbox message will go out to{" "}
                            <b>{inboxRecipientsCount}</b> customers.
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={send}
                  disabled={inboxRecipientsCount == undefined}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-rotaxDark px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-rotaxDark-light focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {isSendingMessage && (
                    <svg
                      className="-ml-1 mr-3 h-4 w-4 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Yes, send it
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirm(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  No, don't send it
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
