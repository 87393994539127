import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderTabBar from "../Components/UI/HeaderTabBar";
import BusinessRankingLists from "./BusinessRankingLists";
import RotaxChallenges from "./RotaxChallenges";

const rotaxChallengesIndex = 0;
const businessRankingListsIndex = 1;

type LocationState = {
  tabIndex: number;
};

export default function Challenges() {
  const { state } = useLocation();
  var tab = rotaxChallengesIndex;
  if (state) {
    const { tabIndex } = state as LocationState;
    tab = tabIndex;
  }
  const [currentTab, setCurrentTab] = useState(tab);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-left text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            MAX Dome Challenges
          </h2>
        </div>
      </div>

      <div className="hidden sm:block">
        <HeaderTabBar
          tabs={[
            { name: "Rotax Challenges" },
            { name: "Business Ranking Lists" },
          ]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />

        {currentTab == rotaxChallengesIndex && <RotaxChallenges />}
        {currentTab == businessRankingListsIndex && <BusinessRankingLists />}
      </div>
    </div>
  );
}
