import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../API/AuthContext";

export default function Login() {
  const { loggedIn, authorize } = useAuth();
  const navigate = useNavigate();
  const [isLoggingIn, setLoggingIn] = useState(true);

  // prompt the user to login (this will perform the redirect to Salesforce SAML SSO)
  const login = () => authorize();

  const init = () => {
    if (loggedIn) {
      console.log("Logged in");
      navigate("/dashboard");
    } else {
      setLoggingIn(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="mt-12">
      {isLoggingIn ? (
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Logging in...
        </h3>
      ) : (
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Login</h3>
          <p className="mt-3 whitespace-nowrap text-sm text-gray-500">
            Click the button below to proceed to Salesforce Single Sign On.
          </p>
          <p className="mt-1 whitespace-nowrap text-sm text-gray-500">
            You may still be signed in to your accout at Salesforce. If you want
            to switch accounts, you have to logout of your current Salesforce
            account first or clear the browser cache for Salesforce.
          </p>

          <button
            type="button"
            className="mt-6 inline-flex items-center rounded-md border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow hover:text-rotaxDark focus:outline-none focus:ring-2 focus:ring-rotaxDark focus:ring-offset-2"
            onClick={() => login()}
          >
            Login
          </button>
        </div>
      )}
    </div>
  );
}
